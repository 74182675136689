import { useState } from "react"
import Toolbar from "@mui/material/Toolbar"
import Box from "@mui/material/Box"
import { useTheme } from "../../../contexts/theme"
import Left from "./left/Left"
import Right from "./right/Right"
import Container from "@mui/material/Container"

const Secondary = () => {
  const { theme } = useTheme()
  const [activeButton, setActiveButton] = useState(null)

  const handleSetActiveButton = (buttonName) => {
    setActiveButton(buttonName)
  }

  return (
    <>
      <Box
        sx={{
          backgroundColor:
            theme.palette.mode === "light" ? "#E9EDFC" : "#030C27",
        }}
      >
        <Container maxWidth="xl">
          <Toolbar
            sx={{
              justifyContent: "space-between",
              flexWrap: "wrap",
              minHeight: "unset !important",
            }}
          >
            <Box display="flex" columnGap="1rem" flexWrap="wrap">
              <Left
                activeButton={activeButton}
                setActiveButton={handleSetActiveButton}
              />
            </Box>
            <Box display="flex" columnGap="1rem" flexWrap="wrap">
              <Right
                activeButton={activeButton}
                setActiveButton={handleSetActiveButton}
              />
            </Box>
          </Toolbar>
        </Container>
      </Box>
    </>
  )
}

export default Secondary
