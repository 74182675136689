import { useState, useEffect } from "react"
import { getFormattedValue } from "../../../utils/utils"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Box from "@mui/material/Box"
import Paper from "@mui/material/Paper"
import ArrowRise from "../../../assets/svgs/ArrowRise"
import ArrowFall from "../../../assets/svgs/ArrowFall"
import { useTheme } from "../../../contexts/theme"

const CustomLegendTable = ({
  data,
  chartConfig,
  colorScheme,
  title,
  showPrevious = true,
}) => {
  const [reportData, setReportData] = useState([])
  const { theme } = useTheme()
  const dataFormat =
    chartConfig?.meta_info?.format == "Currency"
      ? "Currency"
      : chartConfig?.meta_info?.format == "Percentage"
      ? "Percentage"
      : ""
  useEffect(() => {
    if (data) {
      const combinedData = Array.isArray(data)
        ? data.map((item) => ({
            label: item.id || "",
            currentValue:
              title === "Sales by Payment Method"
                ? item.Transactions || 0
                : item.value || 0,
          }))
        : data.reportDataCurrent?.map((current) => {
            const previous = data.reportDataPrevious?.find(
              (prev) => prev.id === current.id
            )
            const label = current.id || ""
            const currentTransactions =
              title === "Sales by Payment Method"
                ? current.Transactions || 0
                : current.value || 0
            const previousTransactions =
              title === "Sales by Payment Method"
                ? previous?.Transactions || 0
                : previous?.value || 0
            const percentChange =
              previousTransactions !== 0
                ? (
                    ((currentTransactions - previousTransactions) /
                      previousTransactions) *
                    100
                  ).toFixed(2)
                : "0.00"

            return {
              label: label,
              currentValue: currentTransactions,
              previousValue: previousTransactions,
              percentChange: percentChange,
            }
          }) || []

      setReportData(combinedData)
    }
  }, [data, title])

  const textPrimary = theme.palette.text.primary

  const commonStyles = {
    padding: "8px 28px 8px 0px",
    color: textPrimary,
    borderBottom: `1px solid ${
      theme.palette.mode === "dark" ? "#0D2266" : "#E6E6E6"
    }`,
    textAlign: "center",
  }

  return (
    <TableContainer
      component={Paper}
      sx={{
        backgroundColor: theme.palette.mode === "dark" ? "#030C27" : "#FAFAFA",
        borderRadius: "5px",
        width: "fit-content",
        minWidth: "250px",
        maxHeight: "350px",
        overflowX: "hidden",
        overflowY: "auto",
        color: textPrimary,
        border: theme.palette.mode === "dark" ? "none" : "1px solid #E6E6E6",
        borderBottom: "0.4px !important",
        "&::-webkit-scrollbar": {
          width: "5px",
          backgroundColor: "transparent",
        },
        "&::-webkit-scrollbar-thumb:hover": {
          backgroundColor:
            theme.palette.mode === "dark" ? "#1C307F" : "#555555AF",
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor:
            theme.palette.mode === "dark" ? "#1C307FEF" : "#5555559F",
          borderRadius: "10px",
          padding: "10px",
          margin: "10px",
        },
      }}
    >
      <Table
        sx={{ minWidth: 150, borderCollapse: "collapse" }}
        aria-label="simple table"
      >
        <TableHead>
          <TableRow>
          {showPrevious && data?.reportDataPrevious &&
            <TableCell
              style={{
                ...commonStyles,
                color: "inherit",
                fontSize: "0.75rem",
                width: "40%",
              }}
            ></TableCell>
}
             {showPrevious && data?.reportDataPrevious &&
            <TableCell
              align="left"
              sx={{
                ...commonStyles,
                color: "inherit",
                width: "92px",
                opacity: 0.7,
                fontSize: 12,
                fontWeight: 400,
              }}
            >
              {data?.reportDataPrevious ? "Current" : "Value"}
            </TableCell>
}
            {showPrevious && data?.reportDataPrevious && (
              <TableCell
                align="left"
                style={{
                  ...commonStyles,
                  color: "inherit",
                  width: "92px",
                  opacity: 0.7,
                  fontSize: 12,
                  fontWeight: 400,
                }}
              >
                Previous
              </TableCell>
            )}
            {showPrevious && data?.reportDataPrevious && (
              <TableCell
                align="left"
                style={{
                  ...commonStyles,
                  padding: "8px 12px 8px 0px",
                  color: "inherit",
                  width: "20%",
                  opacity: 0.7,
                  fontSize: 12,
                  fontWeight: 400,
                  minWidth: "90px",
                }}
              >
                % Change
              </TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {reportData.map((row, index) => (
            <TableRow
              key={index}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                color: "inherit",
                ...commonStyles,
              }}
            >
             
                  {showPrevious && data?.reportDataPrevious ? (
                     <TableCell
                     sx={{
                       display: "flex",
                       justifyContent: "center",
                       alignItems: "center",
                       gap: "20px",
                       borderBottom: "none",
                     }}
                   >
                <div
                  style={{
                    width: "5px",
                    height: "30px",
                    borderRadius: 8,
                    backgroundColor: colorScheme[index % colorScheme.length],
                  }}
                />
                </TableCell>
                  ) : ( 
                    <TableCell
                    sx={{
                      display: "flex",
                      justifyContent: "left",
                      alignItems: "center",
                      gap: "20px",
                      borderBottom: "none",
                    }}
                  >
                  <div
                  style={{
                    width: "5px",
                    height: "30px",
                    borderRadius: 8,
                    backgroundColor: colorScheme[index % colorScheme.length],
                  }}
                />{row.label}
                </TableCell>
)                }
              
              <TableCell
                className="hoverEffect"
                align="left"
                style={{
                  ...commonStyles,
                  color: "inherit",
                  fontSize: 14,
                  fontWeight: 400,
                }}
              >
                {getFormattedValue(row.currentValue, dataFormat)}
                {/* {dataFormat =='$' ? '$'+row.currentValue : dataFormat =='%' ? row.currentValue +'%' : row.currentValue } */}
              </TableCell>
              {showPrevious && data?.reportDataPrevious && (
                <TableCell
                  className="hoverEffect"
                  align="left"
                  style={{
                    ...commonStyles,
                    color: "inherit",
                  }}
                >
                  {getFormattedValue(row.previousValue, dataFormat)}
                  {/* {dataFormat =='$' ? '$'+row.previousValue : dataFormat =='%' ? row.previousValue +'%' : row.previousValue } */}
                </TableCell>
              )}
              {showPrevious && data?.reportDataPrevious && (
                <TableCell
                  className="hoverEffect"
                  align="left"
                  style={{
                    ...commonStyles,
                    padding: "8px 12px 8px 0px",
                    color:
                      row.percentChange > 0
                        ? "#23BF6F"
                        : row.percentChange < 0
                        ? "#EC2D30"
                        : textPrimary,
                    whiteSpace: "nowrap",
                  }}
                >
                  <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="flex-start"
                  >
                    {row.percentChange}%{" "}
                    {row.percentChange > 0 ? (
                      <ArrowRise />
                    ) : row.percentChange < 0 ? (
                      <ArrowFall />
                    ) : null}
                  </Box>
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default CustomLegendTable
