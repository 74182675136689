import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import CircularProgress from "@mui/material/CircularProgress"
import InfoLogoSvg from "../../../assets/svgs/InfoLogoSvg"
import { useTheme } from "../../../contexts/theme"
import NivoHeatMapChart from "./NivoHeatMapChart"
import { useSelector, useDispatch } from "react-redux"
import { postData } from "../../../api/api"
import { useEffect, useState } from "react"
import {
  HeatmapChartData,
  HeatmapChartParameters,
} from "../../../constants/chartsData.js"
import InfoToolTip from "../../InfoToolTip.jsx"
import extractKeyValuePairs from "../../../utils/extractKeyValuePairs.js"
import ViewBy from "../../ViewBy.jsx"
// TODO: Remove these imports and functions when not needed anymore
// import {
//   mergeHeatmapComparisonData,
//   formatPreviousPeriodHeatmapData,
//   formatDataComparedBy,
// } from "../../../utils/utils.js"
import Analytica from "../../Analytica.jsx"
import HeatMapChartDetails from "./HeatMapChartDetails.jsx"

const HeatMapChart = ({
  route,
  setChartData,
  title,
  comparisonPeriod,
  description,
  postUrl,
  reportUid,
  parameters,
  dateRangeData = {
    fromDate: "",
    toDate: "",
  },
  focus_mode = true,
  showAiDropdown = true,
  defaultColor = { type: "diverging", scheme: "blues", divergeAt: 0.75 },
}) => {
  const extractedParameters = extractKeyValuePairs(parameters)
  const [viewBy, setViewBy] = useState("Day")
  const [loading, setLoading] = useState(true)
  const [open, setOpen] = useState(false)
  const [error, setError] = useState(null)
  const dispatch = useDispatch()
  const [apiResponse, setApiResponse] = useState([])
  const { theme } = useTheme()
  const svgColor = theme.palette.accentSecondary
  const extractedPara = extractKeyValuePairs(parameters)
  const [combinedState, setCombinedState] = useState({
    comparisonPeriod: comparisonPeriod,
    dateRangeData: dateRangeData,
  })
  useEffect(() => {
    setCombinedState({
      comparisonPeriod,
      dateRangeData,
    })
  }, [comparisonPeriod, dateRangeData])
  extractedPara.chartContainerDimensions ||
    (extractedPara.chartContainerDimensions = {
      height: "350px",
      minHeight: "350px",
      maxHeight: "unset",
    })
  // Function to convert values to appropriate types
  function convertValues(obj) {
    for (let key in obj) {
      if (typeof obj[key] === "object" && obj[key] !== null) {
        convertValues(obj[key])
      } else if (typeof obj[key] === "string") {
        if (!isNaN(obj[key])) {
          obj[key] = Number(obj[key])
        } else if (obj[key].toLowerCase() === "true") {
          obj[key] = true
        } else if (obj[key].toLowerCase() === "false") {
          obj[key] = false
        }
      }
    }
    return obj
  }
  const params = convertValues(extractedParameters)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const tooltip_data = extractedParameters.tooltip_data || [
    {
      name: "Gross Sales",
      format: "Currency",
      color: "",
    },
    {
      name: "Customers",
      format: "Number",
      color: "",
    },
    {
      name: "Transactions",
      format: "Number",
      color: "",
    },
    {
      name: "Average Ticket",
      format: "Currency",
      color: "",
    },
    {
      name: "Return Amount",
      format: "Currency",
      color: "",
    },
    {
      name: "Discount Amount",
      format: "Currency",
      color: "",
    },
  ]

  const showTooltipPrevious =
    extractedParameters.showTooltipPrevious === undefined
      ? true
      : extractedParameters.showTooltipPrevious

  const transformData = (response) => {
    let responseWithTooltip = []
    let chartInput = []
    const hours = [
      "9AM",
      "10AM",
      "11AM",
      "12PM",
      "1PM",
      "2PM",
      "3PM",
      "4PM",
      "5PM",
      "6PM",
      "7PM",
      "8PM",
      "9PM",
    ]

    if (Array.isArray(response)) {
      responseWithTooltip = response.map((currentItem) => {
        const eachElement = {
          Weekday: currentItem.Weekday || 0,
          id: currentItem.id || "",
          x: currentItem.x || 0,
          y: currentItem.y || 0,
          tooltipData: {
            current: {},
          },
        }

        if (tooltip_data && Array.isArray(tooltip_data)) {
          tooltip_data?.forEach((tooltipDataItem) => {
            eachElement.tooltipData.current[tooltipDataItem.name] = {
              value: currentItem[tooltipDataItem.name] || 0,
              format: tooltipDataItem.format,
            }
          })
        }

        return eachElement
      })

      chartInput = responseWithTooltip.reduce((acc, current) => {
        const id = current.id
        if (!acc[id]) {
          acc[id] = {
            id,
            data: [],
            processedHours: new Set(),
          }
        }

        if (hours.includes(current.x)) {
          acc[id].data.push({
            x: current.x,
            y: current.y,
            reportType: "Heatmap",
            tooltipData: current.tooltipData,
          })
          acc[id].processedHours.add(current.x)
        }

        return acc
      }, {})

      // Add missing hours with default values
      Object.values(chartInput).forEach((entry) => {
        hours.forEach((hour) => {
          if (!entry.processedHours.has(hour)) {
            entry.data.push({
              x: hour,
              y: 0,
              reportType: "Heatmap",
              tooltipData: {
                current: {},
              },
            })
          }
        })
        delete entry.processedHours // Clean up the temporary Set

        // Sort from 9AM to 9PM (hours variable)
        entry.data.sort((a, b) => hours.indexOf(a.x) - hours.indexOf(b.x))
      })
    } else if (response?.reportDataCurrent) {
      responseWithTooltip = response.reportDataCurrent.map((currentItem) => {
        const matchingPreviousItem =
          response?.reportDataPrevious?.find(
            (prevItem) =>
              prevItem.Weekday === currentItem.Weekday &&
              prevItem.id === currentItem.id &&
              prevItem.x === currentItem.x
          ) || {}

        const eachElement = {
          Weekday: currentItem.Weekday || 0,
          id: currentItem.id || "",
          reportType: "Heatmap",
          x: currentItem.x || 0,
          y: currentItem.y || 0,
          previousY: matchingPreviousItem?.y || 0,
          tooltipData: {
            current: {},
            previous: {},
          },
        }

        if (tooltip_data && Array.isArray(tooltip_data)) {
          tooltip_data?.forEach((tooltipDataItem) => {
            eachElement.tooltipData.current[tooltipDataItem.name] = {
              value: currentItem[tooltipDataItem.name] || 0,
              format: tooltipDataItem.format,
            }

            eachElement.tooltipData.previous[tooltipDataItem.name] = {
              value: matchingPreviousItem[tooltipDataItem.name] || 0,
              format: tooltipDataItem.format,
            }
          })
        }

        return eachElement
      })

      chartInput = responseWithTooltip.reduce((acc, current) => {
        const id = current.id
        if (!acc[id]) {
          acc[id] = {
            id,
            data: [],
            processedHours: new Set(),
          }
        }

        if (hours.includes(current.x)) {
          acc[id].data.push({
            x: current.x,
            y: current.y,
            reportType: "Heatmap",
            previousY: current.previousY,
            tooltipData: current.tooltipData,
          })
          acc[id].processedHours.add(current.x)
        }

        return acc
      }, {})

      // Add missing hours with default values
      Object.values(chartInput).forEach((entry) => {
        hours.forEach((hour) => {
          if (!entry.processedHours.has(hour)) {
            entry.data.push({
              x: hour,
              y: 0,
              reportType: "Heatmap",
              previousY: 0,
              tooltipData: {
                current: {},
                previous: {},
              },
            })
          }
        })
        delete entry.processedHours // Clean up the temporary Set

        // Sort from 9AM to 9PM (hours variable)
        entry.data.sort((a, b) => hours.indexOf(a.x) - hours.indexOf(b.x))
      })
    }

    return Object.values(chartInput)
  }

  const fetchData = async () => {
    try {
      setLoading(true)
      const response = await postData(postUrl, {
        entityDb: localStorage.getItem("entityDb"),
        fromDate: combinedState.dateRangeData.fromDate,
        toDate: combinedState.dateRangeData.toDate,
        reportInstanceId: reportUid,
        comparedBy: {
          previousFromDate: combinedState.comparisonPeriod.previousFromDate,
          previousToDate: combinedState.comparisonPeriod.previousToDate,
        },
        // viewBy: viewBy || "Day",
      })
      setApiResponse(response)
      if (!!response === false || Object.keys(response).length === 0) {
        setError("Inadequate data points to generate a report")
        dispatch(
          setChartData({
            reportUid,
            response: HeatmapChartData,
          })
        )
      } else {
        dispatch(
          setChartData({
            reportUid,
            response: transformData(response),
          })
        )
      }
    } catch (error) {
      setError("Inadequate data points to generate a report")
      dispatch(
        setChartData({
          reportUid,
          response: HeatmapChartData,
        })
      )
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    setLoading(true)
    dispatch(
      setChartData({
        reportUid,
        viewBy: viewBy || "Day",
      })
    )

    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    viewBy,
    combinedState.comparisonPeriod.previousFromDate,
    combinedState.comparisonPeriod.previousToDate,
    combinedState.dateRangeData.fromDate,
    combinedState.dateRangeData.toDate,
  ])

  const response = useSelector((state) => {
    const selectedReport = state[route]?.reports?.find(
      (report) => report.report_uid === reportUid
    )
    if (selectedReport) return selectedReport?.reportData
  })

  const config = parameters.length === 1 ? HeatmapChartParameters : params

  return (
    <>
      <Box
        display="flex"
        alignItems="flex-start"
        justifyContent="space-between"
      >
        <Box display="flex" alignItems="center" mb={"14px"}>
          <div className="sales">{title}</div>
          <InfoToolTip title={description}>
            <span style={{ height: "16px", cursor: "pointer" }}>
              <InfoLogoSvg svgColor={svgColor} />
            </span>
          </InfoToolTip>
        </Box>
        <Box
          display="flex"
          alignItems="flex-start"
          justifyContent="space-between"
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            gap={"20px"}
          >
            {/* <Button
              variant="text"
              sx={{
                fontSize: "0.8rem",
                fontWeight: 700,
                color: theme.palette.accent,
                cursor: "pointer",
                textTransform: "none",
                "&:hover": {
                  textDecoration: "underline",
                },
              }}
              onClick={handleClickOpen}
            >
              + View More
            </Button> */}
            {extractedParameters.view_by_period && (
              <ViewBy
                route={route}
                parameters={
                  extractedParameters && extractedParameters.view_by_period
                }
                viewBy={viewBy}
                setViewBy={setViewBy}
                reportUid={reportUid}
                dateRangeData={dateRangeData}
              />
            )}
          </Box>

          <HeatMapChartDetails
            open={open}
            handleClose={handleClose}
            dateRangeData={dateRangeData}
            comparisonPeriod={comparisonPeriod}
            error={error}
            loading={loading}
            staticParameters={config}
            chartInput={response}
          />
          {extractedParameters.focus_mode && focus_mode && (
            <Analytica
              description={description}
              component="modal"
              reportType="Heatmap"
              chartDetails={{
                route,
                setChartData,
                title,
                description,
                comparisonPeriod,
                postUrl,
                reportUid,
                parameters,
                dateRangeData,
                apiResponse,
              }}
            />
          )}
        </Box>
      </Box>

      {loading ? (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          alignContent="center"
          gap="10px"
          minHeight="350px"
          width="100%"
        >
          <CircularProgress size={45} color="info" />
          <Typography variant="body2" color="inherit">
            Fetching data, please wait...
          </Typography>
        </Box>
      ) : error ? (
        <NivoHeatMapChart
          chartConfig={config}
          chartInput={HeatmapChartData}
          reportTitle={title}
          reportData={apiResponse}
          showAiDropdown={showAiDropdown}
          defaultColor={defaultColor}
          showTooltipPrevious={showTooltipPrevious}
        />
      ) : (
        <NivoHeatMapChart
          chartConfig={config}
          chartInput={response}
          reportTitle={title}
          reportData={apiResponse}
          showAiDropdown={showAiDropdown}
          defaultColor={defaultColor}
          showTooltipPrevious={showTooltipPrevious}
        />
      )}
    </>
  )
}

export default HeatMapChart
