import Box from "@mui/material/Box"
import Stack from "@mui/material/Stack"
import Typography from "@mui/material/Typography"
import Chip from "@mui/material/Chip"
import DeleteForeverIcon from "@mui/icons-material/DeleteForever"
import "./styles.scss"
import { useNavigate } from "react-router-dom"
import EditIcon from "@mui/icons-material/Edit"
import CustomPaper from "../../../components/CustomPaper"
import ArrowRise from "../../../assets/svgs/ArrowRise"
import Star from "../../../assets/svgs/Star"
import JobInformation from "./components/JobInformation"
import PayrollSummary from "./components/PayrollSummary"
import TotalLeaves from "./components/TotalLeaves"
import IconButton from "@mui/material/IconButton"
import CustomBreadcrumbs from "../../../components/CustomBreadcrumbs"
import DeleteModal from "../../../components/DeleteModal"
import { useState } from "react"
import BarChart from "../../../components/charts/bar/BarChart"
import { setChartData } from "../../../redux/slices/employeeSlice"
import { useSelector } from "react-redux"

const EmployeeDetails = () => {
  const navigate = useNavigate()
  const comparisonPeriod = useSelector((state) => state.period.selectedValue)

  const [open, setOpen] = useState(false)

  const deleteRecord = () => {
    setOpen(false)
  }

  const handleDelete = (event) => {
    event.stopPropagation()
    setOpen(true)
  }

  const handleEdit = () => {
    navigate(``)
  }

  const EmployeeDetailsCard = ({ title, children }) => (
    <Stack
      display="flex"
      flexDirection="column"
      // justifyContent="space-between"
      // height="100%"
    >
      <Typography color="inherit" variant="subtitle2">
        {title}
      </Typography>
      {children}
    </Stack>
  )

  return (
    <>
      <DeleteModal
        open={open}
        onCancel={() => setOpen(false)}
        onDelete={deleteRecord}
      />
      <Box p="30px 30px 15px 30px">
        <CustomBreadcrumbs
          breadcrumbItems={[
            {
              text: "All Employees",
              // isActive: false,
              clickHandler: () => {
                navigate("/employees/allemployees")
              },
            },
            {
              text: "Employees Details",
              isActive: true,
            },
          ]}
        />
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        p="15px 30px 15px 30px"
      >
        <Typography component="div" variant="h6">
          Employee Details
        </Typography>
        <Box>
          <IconButton size="small">
            <EditIcon sx={{ color: "inherit" }} onClick={handleEdit} />
          </IconButton>
          <IconButton size="small" onClick={handleDelete}>
            <DeleteForeverIcon />
          </IconButton>
        </Box>
      </Box>
      <Stack gap="20px" px="30px" pb="20px">
        {/* personal Information */}
        <div className="primary-grid-container">
          <CustomPaper className="user-grid-content1 grid-col-span-3">
            <Box>
              {/* Personal Information */}

              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                flexWrap="wrap"
                borderBottom="2px solid #091743"
              >
                <Typography mb="10px" color="inherit">
                  Personal Information
                </Typography>
              </Box>

              {/* Personal Information Details */}

              <Box
                display="flex"
                flexDirection="row"
                flexWrap="wrap"
                gap="30px"
                mt={1.5}
              >
                {Object.entries({
                  name: {
                    label: "Name",
                    value: "Dianne Russle",
                  },
                  phoneNumber: {
                    label: "Phone Number",
                    value: "97399321",
                  },
                  emailAddress: {
                    label: "Email Address",
                    value: "abc.qqqq@livelytics.ai",
                  },
                  streetAddress: {
                    label: "Street Address",
                    value: "123 Main Street",
                  },
                  city: {
                    label: "City",
                    value: "AnyTown",
                  },
                  apartmentSuiteNumber: {
                    label: "Appartment / Suite Number",
                    value: "5th Nov 2023",
                  },
                  state: {
                    label: "State",
                    value: "New York",
                  },
                  country: {
                    label: "Country",
                    value: "USA",
                  },
                  zipCode: {
                    label: "Zip Code",
                    value: "440012",
                  },
                  rank: {
                    label: "Rank",
                    value: "6",
                  },
                }).map(([key, value]) => (
                  <Stack
                    key={key}
                    rowGap="5px"
                    minWidth={{
                      sx: "180px",
                      sm: "180px",
                      md: "180px",
                      lg: "250px",
                    }}
                  >
                    <Typography
                      variant="body2"
                      color="inherit"
                      sx={{
                        opacity: "70%",
                        fontSize: "0.85rem",
                      }}
                    >
                      {value.label}
                    </Typography>
                    <Typography
                      variant="body1"
                      color={
                        value.label === "Total Purchased Amount"
                          ? "#50CD89"
                          : "inherit"
                      }
                      sx={{ fontSize: "1.1rem" }}
                    >
                      {value.value}
                    </Typography>
                  </Stack>
                ))}
              </Box>
            </Box>
          </CustomPaper>

          <div className=" secondary-grid-container">
            <div className="item-4">
              {[
                {
                  title: "Total Leaves",
                  value: "36",
                  message: "check leave details below",
                },
                {
                  title: "Performance",
                  value: (
                    <>
                      4.3 <Star />
                    </>
                  ),
                  message: (
                    <>
                      10% Higher than last year <ArrowRise />
                    </>
                  ),
                },
                {
                  title: "Total Leaves",
                  value: "36",
                },
                {
                  title: "Anual Income",
                  value: "$12,360",
                },
                {
                  title: "Total Leaves",
                  value: "36",
                },
                {
                  title: "Status",
                  value: (
                    <Chip
                      size="small"
                      label="Active"
                      sx={{
                        backgroundColor: "rgba(80, 205, 137, 0.3)",
                        color: "rgb(80, 205, 137)",
                        maxWidth: "fit-content",
                      }}
                    />
                  ),
                },
              ].map((item, idx) => (
                <CustomPaper
                  key={idx}
                  className={`small-grid-${idx}`}
                  watermarkBg={true}
                  customStyles={{ minHeight: "100%" }}
                >
                  <EmployeeDetailsCard title={item.title}>
                    <Typography color="inherit" variant="h6">
                      {item.value}
                    </Typography>
                    <Typography color="inherit" style={{ fontSize: "0.75rem" }}>
                      {item.message}
                    </Typography>
                  </EmployeeDetailsCard>
                </CustomPaper>
              ))}
            </div>
          </div>
        </div>

        {/* job Information  */}
        <JobInformation />

        {/* payroll summray */}
        <PayrollSummary />

        {/* Total Leaves */}
        <TotalLeaves />

        {/* Monthly Leaves  */}
        <CustomPaper customStyles={{ mt: "1rem" }}>
          {/* <BarChart
            showLegend={false}
            comparisonPeriod={comparisonPeriod}
            setChartData={setChartData}
            title="Monthly Leaves"
          /> */}
        </CustomPaper>
      </Stack>
    </>
  )
}

export default EmployeeDetails
