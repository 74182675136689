import RouteTitle from "../../../RouteTitle"
import CustomPaper from "../../../CustomPaper"
import Typography from "@mui/material/Typography"
import Stack from "@mui/material/Stack"
import { useTheme } from "../../../../contexts/theme"
import MenuItem from "@mui/material/MenuItem"
import { postData } from "../../../../api/api"
import Select from "@mui/material/Select"
import { useState, useEffect } from "react"
import ThemeToggleButton from "../themeToggleButton/ThemeToggleButton"
import Button from "@mui/material/Button"
import Alert from "@mui/material/Alert"
import axios from "axios"

const Setting = () => {
  const { theme } = useTheme()

  const userData = JSON.parse(localStorage.getItem("user"))
  const userId = userData?.userId || null

  const [dateFormat, setDateFormat] = useState(
    localStorage.getItem("dateFormat") || "MM-DD-YYYY"
  )
  const [timeFormat, setTimeFormat] = useState(
    localStorage.getItem("timeFormat") || "HH:mm"
  )
  const [defaultPage, setDefaultPage] = useState(
    localStorage.getItem("defaultPage") || "Business Synopsis"
  )
  const [displayMode, setDisplayMode] = useState(
    JSON.parse(localStorage.getItem("displayMode")) || false
  )
  const [saveStatus, setSaveStatus] = useState("")

  const handleDateChange = (event) => {
    setDateFormat(event.target.value)
  }

  const handleTimeFormatChange = (event) => {
    setTimeFormat(event.target.value)
  }

  const handleDefaultPageChange = (event) => {
    setDefaultPage(event.target.value)
  }

  useEffect(() => {
    setDateFormat(localStorage.getItem("dateFormat") || "MM-DD-YYYY")
    setTimeFormat(localStorage.getItem("timeFormat") || "HH:mm")
    setDefaultPage(localStorage.getItem("defaultPage") || "Business Synopsis")
    setDisplayMode(localStorage.getItem("themeMode") == "light" ? true : false)
  }, [])

  const handleSaveSettings = async () => {
    try {
      const settingsData = {
        userId,
        dateFormat,
        timeFormat,
        defaultPage,
        displayMode:
          localStorage.getItem("themeMode") == "light" ? true : false,
      }

      const response = await postData(
        "/user/updateuserpreference",
        settingsData
      )

      if (response?.status === 200 || response?.success === true) {
        localStorage.setItem("dateFormat", dateFormat)
        localStorage.setItem("timeFormat", timeFormat)
        localStorage.setItem("defaultPage", defaultPage)

        setSaveStatus("success")
      } else {
        setSaveStatus("failure")
      }
    } catch (error) {
      console.error("Error saving settings:", error)
      setSaveStatus("failure")
    }
  }

  return (
    <>
      <RouteTitle title="User Preferences" />
      <Stack padding="15px 30px 15px 30px" gap="20px">
        <CustomPaper
          customStyles={{
            display: "flex",
            flexDirection: "column",
            gap: "20px",
            padding: "20px",
          }}
        >
          {/* <Typography variant="h6" color="inherit">User Preference</Typography> */}

          <Stack direction="row" alignItems="center" gap={2}>
            <Typography variant="subtitle1" color="inherit">
              Default Mode :
            </Typography>
            <ThemeToggleButton />
          </Stack>

          <Stack direction="row" alignItems="center" gap={1}>
            <Typography variant="subtitle1" color="inherit">
              Date Format :
            </Typography>
            <Select
              value={dateFormat}
              onChange={handleDateChange}
              displayEmpty
              sx={{
                width: "12%",
                height: "35px",
                backgroundColor:
                  theme.palette.mode === "dark"
                    ? "rgba(13, 34, 102, 1)"
                    : "white",
                border: "1px solid #6382E9",
                borderRadius: "12px",
                color: theme.palette.mode === "dark" ? "white" : "black",
              }}
            >
              <MenuItem value="MM-DD-YYYY">MM-DD-YYYY</MenuItem>
              <MenuItem value="DD-MM-YY">DD-MM-YY</MenuItem>
              <MenuItem value="DD-MMM-YYYY">DD-MMM-YYYY</MenuItem>
              <MenuItem value="DD-MM-YYYY">DD-MM-YYYY</MenuItem>
            </Select>
          </Stack>

          <Stack direction="row" alignItems="center" gap={1}>
            <Typography variant="subtitle1" color="inherit">
              Time Format :
            </Typography>
            <Select
              value={timeFormat}
              onChange={handleTimeFormatChange}
              displayEmpty
              sx={{
                width: "12%",
                height: "35px",
                backgroundColor:
                  theme.palette.mode === "dark"
                    ? "rgba(13, 34, 102, 1)"
                    : "white",
                border: "1px solid #6382E9",
                borderRadius: "12px",
                color: theme.palette.mode === "dark" ? "white" : "black",
              }}
            >
              <MenuItem value="12-Hour AM/PM: hh(02:30PM)">
                hh(02:30 PM)
              </MenuItem>
              <MenuItem value="12-Hour AM/PM: hh:mm(02:30:45PM)">
                hh:mm(02:30:45 PM)
              </MenuItem>
              <MenuItem value="12-Hour AM/PM: h(2:30 PM)">h(2:30PM)</MenuItem>
              <MenuItem value="24-Hour Military Time: HH(14:30)">
                HH(14:30)
              </MenuItem>
              <MenuItem value="24-Hour Military Time: HH:mm(14:30:45)">
                HH:mm(14:30:45)
              </MenuItem>
            </Select>
          </Stack>

          <Stack direction="row" alignItems="center" gap={1}>
            <Typography variant="subtitle1" color="inherit">
              Default Page :
            </Typography>
            <Select
              value={defaultPage}
              onChange={handleDefaultPageChange}
              displayEmpty
              sx={{
                width: "16%",
                height: "35px",
                backgroundColor:
                  theme.palette.mode === "dark"
                    ? "rgba(13, 34, 102, 1)"
                    : "white",
                border: "1px solid #6382E9",
                borderRadius: "12px",
                color: theme.palette.mode === "dark" ? "white" : "black",
              }}
            >
              <MenuItem value="Business Synopsis">Business Synopsis</MenuItem>
              <MenuItem value="Sales Dashboard">Sales Dashboard</MenuItem>
              <MenuItem value="Customers Dashboard">
                Customers Dashboard
              </MenuItem>
              <MenuItem value="Customers List">Customers List</MenuItem>
              <MenuItem value="Products Dashboard">Products Dashboard</MenuItem>
              <MenuItem value="Products List">Products List</MenuItem>
              <MenuItem value="Employees Dashboard">
                Employees Dashboard
              </MenuItem>
              <MenuItem value="Employees List">Employees List</MenuItem>
            </Select>
          </Stack>
          <Stack direction="row" alignItems="center" gap={1}>
            <Button
              onClick={handleSaveSettings}
              sx={{
                color: "white",
                background: theme.palette.accent,
                textTransform: "none",
                borderRadius: "20px",
                "&:hover": {
                  backgroundColor:
                    theme.palette.mode === "dark"
                      ? "rgba(255, 100, 0, 1)"
                      : "rgba(29, 71, 226, 1)",
                },
              }}
            >
              Save
            </Button>
          </Stack>

          {saveStatus === "success" && (
            <Alert severity="success">Settings saved successfully!</Alert>
          )}
          {saveStatus === "failure" && (
            <Alert severity="error">
              Failed to save settings. Please try again.
            </Alert>
          )}
        </CustomPaper>
      </Stack>
    </>
  )
}

export default Setting
