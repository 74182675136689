import { useState, useEffect } from "react"
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import { useSelector, useDispatch } from "react-redux"
import extractKeyValuePairs from "../../../utils/extractKeyValuePairs"
import NivoDashLineChart from "./NivoDashLineChart"
import { useTheme } from "../../../contexts/theme"
import ViewBy from "../../ViewBy"
import InfoLogoSvg from "../../../assets/svgs/InfoLogoSvg"
import CircularProgress from "@mui/material/CircularProgress"
import {
  DashLineChartData,
  DashLineChartParameters,
} from "../../../constants/chartsData"
import InfoToolTip from "../../InfoToolTip"
import Analytica from "../../Analytica"
import { postData } from "../../../api/api"
import { getDateFormatFromLocalStorage } from "../../../utils/dateUtils"

const DashLineChart = ({
  route,
  setChartData,
  comparisonPeriod,
  title,
  description,
  postUrl,
  reportUid,
  parameters,
  legendsData,
  dateRangeData = {
    fromDate: "",
    toDate: "",
  },
  focus_mode = true,
  showAiDropdown = true,
}) => {
  const { theme } = useTheme()
  const extractedParameters = extractKeyValuePairs(parameters)
  const [viewBy, setViewBy] = useState("Day")
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [apiResponse, setApiResponse] = useState([])
  const svgColor = theme.palette.accentSecondary
  const dispatch = useDispatch()
  const [combinedState, setCombinedState] = useState({
    comparisonPeriod: comparisonPeriod,
    dateRangeData: dateRangeData,
  })
  useEffect(() => {
    setCombinedState({
      comparisonPeriod,
      dateRangeData,
    })
  }, [comparisonPeriod, dateRangeData])

  const extractedPara = extractKeyValuePairs(parameters)

  const showTooltipPrevious =
    extractedParameters.showTooltipPrevious === undefined
      ? true
      : extractedParameters.showTooltipPrevious

  const tooltip_data = extractedParameters.tooltip_data || [
    {
      name: "Sales",
      format: "Currency",
      color: "",
    },
  ]
  const dayjs = require("dayjs")

  function formatDate(date) {
    const dateFormat = getDateFormatFromLocalStorage() // Get the date format from localStorage
    return dayjs(date).format(dateFormat) // Use the retrieved format
  }
  extractedPara.chartContainerDimensions ||
    (extractedPara.chartContainerDimensions = {
      height: "350px",
      minHeight: "350px",
      maxHeight: "unset",
    })

  function transformData(response) {
    let transformedResponse = response.map((item) => {
      return {
        ...item,
        data: JSON.parse(item.data),
      }
    })

    let actualData =
      transformedResponse.filter((item) => item.id === "Actual")[0] || {}
    let forecastData =
      transformedResponse.filter((item) => item.id === "Forecast")[0] || {}

    let actualDataWithTooltip = []

    actualData.data.forEach((actualItem, idx) => {
      const mappedItem = forecastData.data[idx]
      let eachElement = {
        ...actualItem,
        reportType: "DashLine",
        actualDate:
          viewBy === "Day" ? formatDate(actualItem.daymonth) : actualItem.x,
        forcastDate:
          viewBy === "Day" ? formatDate(actualItem.daymonth) : actualItem.x,
        y: actualItem.Sales,
        tooltipData: {
          current: {},
          previous: {},
        },
      }

      tooltip_data?.forEach((tooltipDataItem) => {
        eachElement.tooltipData.current[tooltipDataItem.name] = {
          value: actualItem[tooltipDataItem.name],
          format: tooltipDataItem.format,
        }

        eachElement.tooltipData.previous[tooltipDataItem.name] = {
          value: mappedItem[tooltipDataItem.name],
          format: tooltipDataItem.format,
        }
      })

      actualDataWithTooltip.push(eachElement)
    })

    actualData.data = actualDataWithTooltip

    let forecastDataWithTooltip = []

    forecastData.data.forEach((forecastItem, idx) => {
      const mappedItem = actualData.data[idx]

      let eachElement = {
        ...forecastItem,
        y: forecastItem.Sales,
        reportType: "DashLine",
        actualDate: formatDate(forecastItem.daymonth),
        forcastDate: formatDate(forecastItem.daymonth),
        tooltipData: {
          current: {},
          previous: {},
        },
      }

      tooltip_data?.forEach((tooltipDataItem) => {
        eachElement.tooltipData.current[tooltipDataItem.name] = {
          value: forecastItem[tooltipDataItem.name],
          format: tooltipDataItem.format,
        }

        eachElement.tooltipData.previous[tooltipDataItem.name] = {
          value: mappedItem[tooltipDataItem.name],
          format: tooltipDataItem.format,
        }
      })

      forecastDataWithTooltip.push(eachElement)
    })

    forecastData.data = forecastDataWithTooltip

    return transformedResponse
  }

  const fetchData = async () => {
    try {
      const response = await postData(postUrl, {
        entityDb: localStorage.getItem("entityDb"),
        fromDate: combinedState.dateRangeData.fromDate,
        toDate: combinedState.dateRangeData.toDate,
        reportInstanceId: reportUid,
        // comparedBy: {
        //   previousFromDate: comparisonPeriod?.previousFromDate,
        //   previousToDate: comparisonPeriod?.previousToDate,
        // },
        viewBy: viewBy || "Day",
      })
      setApiResponse(response)
      if (!!response === false || Object.keys(response).length === 0) {
        setError("Inadequate data points to generate a report")
        dispatch(
          setChartData({
            reportUid,
            response: DashLineChartData,
          })
        )
      } else {
        dispatch(
          setChartData({
            reportUid,
            response: transformData(response),
          })
        )
      }
    } catch (error) {
      console.log(error)
      setError("Inadequate data points to generate a report")
      dispatch(
        setChartData({
          reportUid,
          response: DashLineChartData,
        })
      )
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    setLoading(true)
    if (setChartData)
      dispatch(
        setChartData({
          reportUid,
          viewBy: viewBy || "Day",
        })
      )
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    viewBy,
    combinedState.comparisonPeriod.previousFromDate,
    combinedState.comparisonPeriod.previousToDate,
    combinedState.dateRangeData.fromDate,
    combinedState.dateRangeData.toDate,
  ])

  const response = useSelector((state) => {
    const selectedReport = state[route]?.reports?.find(
      (report) => report.report_uid === reportUid
    )
    if (selectedReport) return selectedReport?.reportData
  })

  const chartInput = loading ? [] : response ?? DashLineChartData

  let maxSales = 0

  if (Array.isArray(chartInput)) {
    chartInput.forEach((dataset) => {
      dataset.data.forEach((entry) => {
        const adjustedSales = Math.round(entry.y + entry.y * 0.2)
        if (adjustedSales > maxSales) {
          maxSales = adjustedSales
        }
      })
    })
  } else {
    console.error("chartInput is not an array:", chartInput)
  }

  const legendData = [
    { label: "Actual", color: "#F19004" },
    {
      label: "Forecasted",
      color: theme.palette.mode === "light" ? "#1D54C8" : "#A4D0E9",
    },
  ]

  return (
    <>
      <Box
        display="flex"
        alignItems="flex-start"
        justifyContent="space-between"
        flexWrap={"wrap"}
        gap={"10px"}
      >
        <Box display="flex" alignItems="center">
          <Typography color="inherit">{title}</Typography>
          <InfoToolTip title={description}>
            <span style={{ height: "16px", cursor: "pointer" }}>
              <InfoLogoSvg svgColor={svgColor} />
            </span>
          </InfoToolTip>
        </Box>
        <Box
          display="flex"
          alignItems="flex-start"
          justifyContent="space-between"
          gap={"2px"}
        >
          {extractedParameters.view_by_period && (
            <ViewBy
              route={route}
              parameters={
                extractedParameters && extractedParameters.view_by_period
              }
              viewBy={viewBy}
              setViewBy={setViewBy}
              reportUid={reportUid}
              dateRangeData={dateRangeData}
            />
          )}

          <Box display="flex">
            {extractedParameters.focus_mode && focus_mode && (
              <Analytica
                description={description}
                component="modal"
                reportType="DashLine"
                chartDetails={{
                  route,
                  setChartData,
                  title,
                  description,
                  comparisonPeriod,
                  postUrl,
                  reportUid,
                  parameters,
                  dateRangeData,
                  apiResponse,
                }}
              />
            )}
          </Box>
        </Box>
      </Box>

      {loading ? (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          alignContent="center"
          gap="10px"
          minHeight="350px"
          width="100%"
        >
          <CircularProgress size={45} color="info" />
          <Typography variant="body2" color="inherit">
            Fetching data, please wait...
          </Typography>
        </Box>
      ) : error ? (
        <NivoDashLineChart
          chartInput={chartInput}
          parameters={DashLineChartParameters}
          legendsData={legendData}
          maxSales={maxSales}
          reportTitle={title}
          reportData={apiResponse}
          showAiDropdown={showAiDropdown}
          showTooltipPrevious={showTooltipPrevious}
        />
      ) : (
        <NivoDashLineChart
          chartInput={chartInput}
          parameters={DashLineChartParameters}
          legendsData={legendData}
          maxSales={maxSales}
          reportTitle={title}
          reportData={apiResponse}
          showAiDropdown={showAiDropdown}
          showTooltipPrevious={showTooltipPrevious}
        />
      )}
    </>
  )
}

export default DashLineChart
