import { useEffect, useState } from "react"
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import Stack from "@mui/material/Stack"
import CircularProgress from "@mui/material/CircularProgress"
import ArrowRise from "../assets/svgs/ArrowRise"
import ArrowFall from "../assets/svgs/ArrowFall"
import Analytica from "./Analytica"
import { getFormattedValue } from "../utils/utils"
import "./Style.scss"
import { useTheme } from "../contexts/theme"
import extractKeyValuePairs from "../utils/extractKeyValuePairs"
import { postData } from "../api/api"
import Star from "../assets/svgs/Star"

const parseCurrency = (value) => {
  return parseFloat(value?.replace(/[^0-9.-]+/g, ""))
}

const KPICard = ({
  title,
  parameters,
  postUrl,
  comparisonPeriod,
  reportUid,
  description,
  dateRangeData = {
    fromDate: "",
    toDate: "",
  },
  star,
  format,
  showPrevious = true,
}) => {
  const extractColumns = (parameters) => {
    if (
      !parameters ||
      !parameters[0]?.reportParameters ||
      !parameters[0].reportParameters[0]
    ) {
      return null
    }

    let columnString = parameters[0].reportParameters[0].parameterValue
    try {
      return JSON.parse(columnString)?.format
    } catch (error) {
      console.warn("Failed to parse column string", error)
      return null
    }
  }

  const extractedParameters = extractKeyValuePairs(parameters)

  const [responseData, setResponseData] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const { theme } = useTheme()
  const ThemeMode = theme.palette.mode
  const [combinedState, setCombinedState] = useState({
    comparisonPeriod: comparisonPeriod,
    dateRangeData: dateRangeData,
  })
  useEffect(() => {
    setCombinedState({
      comparisonPeriod,
      dateRangeData,
    })
  }, [comparisonPeriod, dateRangeData])

  const staticFallbackData = {
    reportDataCurrent: [
      { value: parseCurrency(String(parameters?.[0]?.currentValue || 100)) },
    ],
    reportDataPrevious: [
      { value: parseCurrency(String(parameters?.[0]?.previousValue || 50)) },
    ],
    star: parameters?.[0]?.star || false,
  }

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true)
      try {
        let data

        if (reportUid === "static-kpi") {
          data = staticFallbackData
        } else {
          data = await postData(postUrl, {
            entityDb: localStorage.getItem("entityDb"),
            comparedBy:
              {
                previousFromDate:
                  combinedState.comparisonPeriod.previousFromDate,
                previousToDate: combinedState.comparisonPeriod.previousToDate,
              } || "",
            fromDate: combinedState.dateRangeData.fromDate,
            toDate: combinedState.dateRangeData.toDate,
            reportInstanceId: reportUid,
          })
        }

        if (
          !data ||
          !data.reportDataCurrent ||
          !data.reportDataCurrent.length
        ) {
          data = staticFallbackData
        }

        setResponseData(data)
      } catch (error) {
        console.warn(error.message)
        setResponseData(staticFallbackData)
      } finally {
        setIsLoading(false)
      }
    }

    if (postUrl && comparisonPeriod.previousToDate !== 1) {
      fetchData()
    } else {
      setIsLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    postUrl,
    reportUid,
    combinedState.comparisonPeriod.previousFromDate,
    combinedState.comparisonPeriod.previousToDate,
    combinedState.dateRangeData.fromDate,
    combinedState.dateRangeData.toDate,
    parameters,
  ])

  const formatParams = extractColumns(parameters) || format

  // Calculate percentage change
  let percentageChange = null
  let currentValue = null
  let previousValue = null

  if (
    responseData?.reportDataCurrent?.length &&
    responseData?.reportDataPrevious?.length
  ) {
    currentValue = responseData.reportDataCurrent[0]?.value
    previousValue = responseData.reportDataPrevious[0]?.value
  } else {
    // Use static data
    currentValue = staticFallbackData.reportDataCurrent[0]?.value
    previousValue = staticFallbackData.reportDataPrevious[0]?.value
  }

  if (currentValue !== null && previousValue !== null && previousValue !== 0) {
    percentageChange = ((currentValue - previousValue) / previousValue) * 100
  }

  // Format percentage change with + sign for positive values
  const formattedPercentageChange =
    percentageChange !== null
      ? `${percentageChange > 0 ? "+" : ""}${percentageChange.toFixed(2)}%`
      : "N/A"

  const titlesWithDollarPrefix = [
    "Gross Sales",
    "Average Ticket",
    "Repeat Customers",
    "Avg Sales per employee",
  ]

  // Function to conditionally prepend $ based on title
  const formatValue = (value, formatParams) => {
    const formattedValue = getFormattedValue(value, formatParams)
    return titlesWithDollarPrefix.includes(title)
      ? `${formattedValue}`
      : formattedValue
  }

  return (
    <>
      <Stack
        display="flex"
        justifyContent="space-between"
        height="100%"
        gap="5px"
      >
        <Box
          display="flex"
          alignItems="flex-start"
          justifyContent="space-between"
          gap="2px"
        >
          <Typography
            variant="subtitle2"
            component="div"
            style={{
              marginBottom: "8px",
              overflow: "visible",
              overflowWrap: "break-word",
              textOverflow: "none",
              color: ThemeMode === "dark" ? "#ffffff" : "#737373",
              fontWeight: "700",
            }}
            textAlign="left"
          >
            {title}
          </Typography>

          <Box display="flex">
            {extractedParameters.focus_mode && (
              <Analytica description={description} />
            )}
          </Box>
        </Box>
        <Stack display="flex" gap="5px">
          {isLoading ? (
            <CircularProgress color="info" size={30} />
          ) : (
            <>
              <Typography variant="h5" color="inherit">
                {currentValue !== null ? (
                  <span
                    className="KPICardhoverEffect"
                    style={{ color: "inherit", fontWeight: "bold" }}
                  >
                    {formatValue(currentValue, formatParams)}
                    {star && <Star />}
                  </span>
                ) : (
                  "No Data"
                )}
              </Typography>
              {previousValue !== null && showPrevious && (
                <Box
                  display="flex"
                  flexWrap="wrap"
                  justifyContent="space-between"
                  alignItems="flex-end"
                >
                  <Typography
                    color="textSecondary"
                    fontWeight="400"
                    fontSize="0.9rem"
                    sx={{
                      color: ThemeMode === "dark" ? "#ffffff" : "#404040",
                    }}
                    className="KPICardhoverEffect"
                  >
                    {formatValue(previousValue, formatParams)}
                  </Typography>
                  <Box
                    className="KPICardhoverEffect"
                    display="flex"
                    flexWrap="wrap"
                  >
                    <Typography
                      fontSize="1.2rem"
                      fontWeight="400"
                      display="flex"
                      flexDirection="row"
                      alignItems="flex-end"
                      color={ThemeMode === "dark" ? "#ffffff" : "#404040"}
                    >
                      {formattedPercentageChange}
                      {percentageChange !== null &&
                        percentageChange !== 0 &&
                        (percentageChange > 0 ? (
                          <ArrowRise width="45" height="35" />
                        ) : (
                          <ArrowFall width="45" height="35" />
                        ))}
                    </Typography>
                  </Box>
                </Box>
              )}
            </>
          )}
        </Stack>
      </Stack>
    </>
  )
}

export default KPICard
