import { useFormik } from "formik"
import React, { useState } from "react"
import * as Yup from "yup"
import Modal from "@mui/material/Modal"
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import TextField from "@mui/material/TextField"
import Button from "@mui/material/Button"
import IconButton from "@mui/material/IconButton"
import InputAdornment from "@mui/material/InputAdornment"
import Visibility from "@mui/icons-material/Visibility"
import VisibilityOff from "@mui/icons-material/VisibilityOff"
import { useNavigate } from "react-router-dom"
import CloseIcon from "@mui/icons-material/Close"
import { useTheme } from "../../../contexts/theme"
import Ovvi from "../../../assets/svgs/Ovvi"
import axios from "axios"
import { ovviloginSchema } from "../Validation"

const OvviPOSModal = ({ open, handleClose, setIsLoggedIn }) => {
  const { theme } = useTheme()
  const [showPassword, setShowPassword] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const navigate = useNavigate()

  const handleClickShowPassword = () => setShowPassword(!showPassword)
  const handleMouseDownPassword = (event) => event.preventDefault()

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: ovviloginSchema,
    onSubmit: async (values) => {
      try {
        const response = await axios.post(
          "https://cloudapi.ovvihq.com/api/Account/Login",
          {
            nvarEmail: values.email,
            nvarPassword: values.password,
            intOwnerID: 1,
          }
        )

        const { isSuccess, response_Body } = response.data

        if (isSuccess) {
          localStorage.setItem("ClientID", response_Body.ClientID)
          localStorage.setItem("AccessToken", response_Body.accessToken)
          localStorage.setItem("APIKey", response_Body.APIKey)

          setIsLoggedIn(true)
          handleClose()

          // navigate("/sales")
        } else {
          setErrorMessage("Login failed. Please check your credentials.")
        }
      } catch (error) {
        console.error("Login error:", error)
        setErrorMessage("An error occurred. Please try again.")
      }
    },
  })

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "488px",
          height: "557px",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          textAlign: "center",
          borderRadius: "16px",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <IconButton onClick={handleClose}>
            <CloseIcon sx={{ color: theme.palette.accent }} />
          </IconButton>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            mb: 3,
          }}
        >
          <Ovvi width="180px" height="50px" />
        </Box>
        <Typography variant="body1" fontSize="20px" lineHeight="22.3px">
          Welcome To OVVI Cloud POS
        </Typography>
        <form onSubmit={formik.handleSubmit}>
          <TextField
            required
            fullWidth
            id="email"
            name="email"
            label="User Name"
            type="email"
            margin="normal"
            placeholder="Enter Your User Name"
            multiline
            variant="standard"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.email}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
            sx={{ mt: 5, width: "388px" }}
          />
          <TextField
            required
            name="password"
            label="Password"
            fullWidth
            type={showPassword ? "text" : "password"}
            variant="standard"
            margin="normal"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.password}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
            sx={{ width: "388px" }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    sx={{ color: "black" }}
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          {errorMessage && (
            <Typography variant="body2" color="error" sx={{ mt: 2 }}>
              {errorMessage}
            </Typography>
          )}
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
            }}
          >
            <Button
              variant="text"
              sx={{
                fontWeight: 500,
                fontSize: "16px",
                textTransform: "none",
                color: "#1D47CE",
                borderRadius: 10,
                "&:hover": {
                  textDecoration: "underline",
                },
              }}
              onClick={() => navigate("/forgotpassword")}
            >
              Forgot password?
            </Button>
          </Box>

          <Button
            type="submit"
            variant="contained"
            sx={{
              mt: 5,
              borderRadius: "24px",
              textTransform: "none",
              backgroundColor: "#1D47CE",
              padding: "3% 18%",
              width: "auto",
            }}
          >
            Login to OVVI
          </Button>
        </form>
      </Box>
    </Modal>
  )
}

export default OvviPOSModal
