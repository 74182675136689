import { useState, useEffect } from "react"
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import { useSelector, useDispatch } from "react-redux"
import CircularProgress from "@mui/material/CircularProgress"
import NivoHorizontalBarChart from "./NivoHorizontalBarChart"
import extractKeyValuePairs from "../../../../utils/extractKeyValuePairs"
import { useTheme } from "../../../../contexts/theme"
import {
  HorizontalBarChartData,
  HorizontalBarChartParameters,
  HorizontalBarChartsecondData,
} from "../../../../constants/chartsData"
import InfoToolTip from "../../../InfoToolTip"
import InfoLogoSvg from "../../../../assets/svgs/InfoLogoSvg"
import ViewBy from "../../../ViewBy"
import Analytica from "../../../Analytica"
import { postData } from "../../../../api/api"
import dayjs from "dayjs"

const HorizontalBarChart = ({
  route,
  setChartData,
  title,
  description,
  comparisonPeriod,
  postUrl,
  reportUid,
  parameters,
  dateRangeData = {
    fromDate: "",
    toDate: "",
  },
  focus_mode = true,
  showAiDropdown = true,
}) => {
  const extractedParameters = extractKeyValuePairs(parameters)
  const [viewBy, setViewBy] = useState("Day")
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const dispatch = useDispatch()
  const [apiResponse, setApiResponse] = useState([])
  const { theme } = useTheme()
  const svgColor = theme.palette.accentSecondary
  const [combinedState, setCombinedState] = useState({
    comparisonPeriod: comparisonPeriod,
    dateRangeData: dateRangeData,
  })
  useEffect(() => {
    setCombinedState({
      comparisonPeriod,
      dateRangeData,
    })
  }, [comparisonPeriod, dateRangeData])
  const tooltip_data = extractedParameters.tooltip_data || [
    {
      name: "Order Count",
      format: "Number",
      color: "",
    },
    {
      name: "Gross Sales",
      format: "Currency",
      color: "",
    },
    {
      name: "Customers",
      format: "Number",
      color: "",
    },
    {
      name: "Transactions",
      format: "Number",
      color: "",
    },
    {
      name: "Average Ticket",
      format: "Currency",
      color: "",
    },
    {
      name: "Return Amount",
      format: "Currency",
      color: "",
    },
    {
      name: "Discount Amount",
      format: "Currency",
      color: "",
    },
  ]

  const showTooltipPrevious =
    extractedParameters.showTooltipPrevious === undefined
      ? true
      : extractedParameters.showTooltipPrevious

  function transformData(input, chart = "left") {
    const data = []

    if (comparisonPeriod.selectedValue === "Compared By") {
      if (viewBy === "Day") {
        if (!input || !Array.isArray(input)) {
          console.error("Expected input to be an array but received:", input)
          return []
        }
        input?.forEach((currentItem) => {
          let eachElement = {
            idMonth:
              chart === "left"
                ? dayjs(currentItem.idMonth).format("MM-DD-YYYY") || 0
                : currentItem.idMonth || 0,
            [chart === "left" ? "orderCount" : "grossSale"]:
              chart === "left"
                ? parseFloat(currentItem["Order Count"])
                : parseFloat(currentItem["Gross Sales"]),
            tooltipData: {
              current: {},
            },
          }

          tooltip_data?.forEach((tooltipDataItem) => {
            eachElement.tooltipData.current[tooltipDataItem.name] = {
              value: Math.abs(currentItem[tooltipDataItem.name]),
              format: tooltipDataItem.format,
            }
          })

          data.push(eachElement)
        })
      } else if (
        viewBy === "Week" ||
        viewBy === "Month" ||
        viewBy === "Quarter" ||
        viewBy === "Year"
      ) {
        if (!input || !Array.isArray(input)) {
          console.error("Expected input to be an array but received:", input)
          return []
        }
        input.forEach((currentItem) => {
          let eachElement = {
            idMonth: currentItem.id || 0,
            [chart === "left" ? "orderCount" : "grossSale"]:
              chart === "left"
                ? parseFloat(currentItem["Order Count"])
                : parseFloat(currentItem["Gross Sales"]),
            tooltipData: {
              current: {},
            },
          }

          tooltip_data?.forEach((tooltipDataItem) => {
            eachElement.tooltipData.current[tooltipDataItem.name] = {
              value: Math.abs(currentItem[tooltipDataItem.name]),
              format: tooltipDataItem.format,
            }
          })

          data.push(eachElement)
        })
      }
    } else {
      if (!input || typeof input !== "object") {
        console.error("Expected input to be an object but received:", input)
        return []
      }
      if (viewBy === "Day") {
        input?.reportDataCurrent?.forEach((currentItem, idx) => {
          let previousItem = input?.reportDataPrevious[idx] || {}

          let eachElement = {
            idMonth:
              chart === "left"
                ? dayjs(currentItem.idMonth).format("MM-DD-YYYY") || 0
                : currentItem.idMonth || 0,
            [chart === "left" ? "orderCount" : "grossSale"]:
              chart === "left"
                ? parseFloat(currentItem["Order Count"])
                : parseFloat(currentItem["Gross Sales"]),
            tooltipData: {
              current: {},
              previous: {},
            },
          }

          tooltip_data?.forEach((tooltipDataItem) => {
            eachElement.tooltipData.current[tooltipDataItem.name] = {
              value: Math.abs(currentItem[tooltipDataItem.name]),
              format: tooltipDataItem.format,
            }

            eachElement.tooltipData.previous[tooltipDataItem.name] = {
              value: Math.abs(previousItem[tooltipDataItem.name]),
              format: tooltipDataItem.format,
            }
          })

          data.push(eachElement)
        })
      } else if (
        viewBy === "Week" ||
        viewBy === "Month" ||
        viewBy === "Quarter" ||
        viewBy === "Year"
      ) {
        if (!input || typeof input !== "object") {
          console.error("Expected input to be an object but received:", input)
          return []
        }
        input?.reportDataCurrent?.forEach((currentItem, idx) => {
          let previousItem = input?.reportDataPrevious[idx] || {}

          let eachElement = {
            idMonth: currentItem.WeekNumber || 0,
            [chart === "left" ? "orderCount" : "grossSale"]:
              chart === "left"
                ? parseFloat(currentItem["Order Count"])
                : parseFloat(currentItem["Gross Sales"]),
            tooltipData: {
              current: {},
              previous: {},
            },
          }

          tooltip_data?.forEach((tooltipDataItem) => {
            eachElement.tooltipData.current[tooltipDataItem.name] = {
              value: Math.abs(currentItem[tooltipDataItem.name]),
              format: tooltipDataItem.format,
            }

            eachElement.tooltipData.previous[tooltipDataItem.name] = {
              value: Math.abs(previousItem[tooltipDataItem.name]),
              format: tooltipDataItem.format,
            }
          })

          data.push(eachElement)
        })
      }
    }

    return data
  }

  const fetchData = async () => {
    try {
      const response = await postData(postUrl, {
        entityDb: localStorage.getItem("entityDb"),
        fromDate: combinedState.dateRangeData.fromDate,
        toDate: combinedState.dateRangeData.toDate,
        reportInstanceId: reportUid,
        comparedBy: {
          previousFromDate: combinedState.comparisonPeriod.previousFromDate,
          previousToDate: combinedState.comparisonPeriod.previousToDate,
        },
        viewBy: viewBy || "Day",
      })
      setApiResponse(response)
      if (!response || Object.keys(response).length === 0) {
        setError("Inadequate data points to generate a report")
        dispatch(
          setChartData({
            reportUid,
            response: HorizontalBarChartData,
          })
        )
      } else {
        dispatch(
          setChartData({
            reportUid,
            response: response,
          })
        )
      }
    } catch (error) {
      setError("Inadequate data points to generate a report")
      dispatch(
        setChartData({
          reportUid,
          response: HorizontalBarChartData,
        })
      )
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    setLoading(true)
    dispatch(
      setChartData({
        reportUid,
        viewBy: viewBy || "Day",
      })
    )
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    viewBy,
    combinedState.comparisonPeriod.previousFromDate,
    combinedState.comparisonPeriod.previousToDate,
    combinedState.dateRangeData.fromDate,
    combinedState.dateRangeData.toDate,
  ])

  const response = useSelector((state) => {
    const selectedReport = state[route]?.reports?.find(
      (report) => report.report_uid === reportUid
    )

    if (selectedReport) return selectedReport?.reportData || {}
    return {}
  })

  const transformedDataOne = transformData(response, "left")
  const transformedDataTwo = transformData(response, "right")

  const legendData = [
    { label: "Orders", color: "rgb(252, 151,72)" },
    { label: "Gross Sale", color: "rgba(0, 158, 247, 1)" },
  ]

  return (
    <>
      <Box
        display="flex"
        alignItems="flex-start"
        justifyContent="space-between"
        flexWrap={"wrap"}
        gap={"10px"}
      >
        <Box display="flex" alignItems="center">
          <Typography color="inherit">{title}</Typography>
          <InfoToolTip title={description}>
            <span style={{ height: "16px", cursor: "pointer" }}>
              <InfoLogoSvg svgColor={svgColor} />
            </span>
          </InfoToolTip>
        </Box>
        <Box
          display="flex"
          alignItems="flex-start"
          justifyContent="space-between"
          gap={"2px"}
        >
          {extractedParameters.view_by_period && (
            <ViewBy
              route={route}
              parameters={extractedParameters.view_by_period}
              viewBy={viewBy}
              setViewBy={setViewBy}
              reportUid={reportUid}
              dateRangeData={dateRangeData}
            />
          )}

          <Box display="flex">
            {extractedParameters.focus_mode && focus_mode && (
              <Analytica
                description={description}
                component="modal"
                reportType="DualHorizontalBar"
                chartDetails={{
                  route,
                  setChartData,
                  title,
                  description,
                  comparisonPeriod,
                  postUrl,
                  reportUid,
                  parameters,
                  dateRangeData,
                  apiResponse,
                }}
              />
            )}
          </Box>
        </Box>
      </Box>

      <Box minHeight="520px">
        {loading ? (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            alignContent="center"
            gap="10px"
            minHeight="350px"
            width="100%"
          >
            <CircularProgress size={45} color="info" />
            <Typography variant="body2" color="inherit">
              Fetching data, please wait...
            </Typography>
          </Box>
        ) : error ? (
          <NivoHorizontalBarChart
            chartConfig={HorizontalBarChartParameters}
            chartInput1={HorizontalBarChartData}
            chartInput2={HorizontalBarChartsecondData}
            legendsData={legendData}
            reportTitle={title}
            reportData={apiResponse}
            showAiDropdown={showAiDropdown}
            showTooltipPrevious={showTooltipPrevious}
          />
        ) : (
          <NivoHorizontalBarChart
            chartConfig={HorizontalBarChartParameters}
            chartInput1={transformedDataOne}
            chartInput2={transformedDataTwo}
            legendsData={legendData}
            reportTitle={title}
            reportData={apiResponse}
            showAiDropdown={showAiDropdown}
            showTooltipPrevious={showTooltipPrevious}
          />
        )}
      </Box>
    </>
  )
}

export default HorizontalBarChart
