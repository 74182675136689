import { useNavigate } from "react-router-dom"
import { useState, useEffect } from "react"
import dayjs from "dayjs"

import RouteTitle from "../../../../RouteTitle"
import Invoices from "../../../../../assets/svgs/Invoices"
import CustomPaper from "../../../../CustomPaper"
import { useTheme } from "../../../../../contexts/theme"
import FixedBottomComponent from "../../../../FixedBottomComponent"
import { postData } from "../../../../../api/api"
import { getFormattedValue } from "../../../../../utils/utils"

import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Stack from "@mui/material/Stack"
import Typography from "@mui/material/Typography"
import CircularProgress from "@mui/material/CircularProgress"
import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import Accordion from "@mui/material/Accordion"
import AccordionSummary from "@mui/material/AccordionSummary"
import AccordionDetails from "@mui/material/AccordionDetails"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import Masonry from "@mui/lab/Masonry"

const Subscription = () => {
  const { theme } = useTheme()
  const navigate = useNavigate()

  const [firstLoading, setfirstLoading] = useState(true)
  const [secondLoading, setSecondLoading] = useState(true)
  const [entitySubscription, setEntitySubscription] = useState(null)
  const [entitySubscriptionDetails, setEntitySubscriptionDetails] =
    useState(null)

  const fetchData = async () => {
    try {
      setfirstLoading(true)
      setSecondLoading(true)
      const response = await postData("subscription/entitysubscription", {
        entityId: localStorage.getItem("entityId"),
      })

      setEntitySubscription(response?.result?.[0])
    } catch (error) {
      console.warn(error.message)
      setEntitySubscription({
        subscriptionID: "12345678-1234-1234-1234-123456789101112",
        EntitySubscriptionName: "DEMO - Transactions Package",
        businessEntityId: "12345678-1234-1234-1234-123456789101112",
        subscriptionStatus: "Pending Activation",
        subscriptionPackageType: "Base",
        billingCycle: "Monthly",
        purchasedDate: "2024-07-02T20:43:59.530",
        lastBilledDate: "2024-07-02T20:43:59.530",
        nextBillDate: "2024-08-02T20:43:59.530",
        subscriptionExpiryDate: "2024-08-02T20:43:59.530",
        totalAmount: 0,
        billingAddressLine1: "some address",
        City: "Houston",
        State: "Texas",
        Country: "United States",
        postalCode: "12345",
        entityPointofContactID: 1,
        PoCFirstName: "John",
        POCLastName: "Doe",
        POCJobTitle: "TA",
        POCEmail: "john@livelytics.ai",
        PackageDetails: [
          {
            PackageName: "Transactional Reporting & Insights Package",
            PackageType: "Core",
            PackageTierName: "Free",
            PackageTierPrice: 0,
          },
        ],
      })
    } finally {
      setfirstLoading(false)
    }
  }

  useEffect(() => {
    fetchData().then(() => {
      postData("subscription/entitysubscriptiondetail", {
        subscriptionID: entitySubscription?.subscriptionID,
      })
        .then((entitySubscriptionDetail) => {
          setSecondLoading(false)
          setEntitySubscriptionDetails(entitySubscriptionDetail?.result?.[0])
        })
        .catch((error) => {
          setSecondLoading(false)
          setEntitySubscriptionDetails({
            SubscriptionId: "12345678-1234-1234-1234-123456789101112",
            SubscriptionName: "DEMO - Transactions Package",
            SubscriptionDetails: [
              {
                entityPackageTierSubscriptionID: 1,
                componentPrice: 0,
                ParentComponent: [
                  {
                    ParentComponentType: "Dashboards",
                    ParentComponentUID:
                      "12345678-1234-1234-1234-123456789101112",
                    ParentComponent: "Customer Dashboard",
                    ChildComponent: [
                      {
                        ParentId: 2,
                        ChildComponentType: "Reports",
                        ChildComponentId: 19,
                        ChildComponent: "Total Customers",
                        isOpenComponent: false,
                        isBillableComponent: false,
                      },
                      {
                        ParentId: 2,
                        ChildComponentType: "Reports",
                        ChildComponentId: 20,
                        ChildComponent: "New Customers",
                        isOpenComponent: false,
                        isBillableComponent: false,
                      },
                      {
                        ParentId: 2,
                        ChildComponentType: "Reports",
                        ChildComponentId: 21,
                        ChildComponent: "Anonymous Customers",
                        isOpenComponent: false,
                        isBillableComponent: false,
                      },
                      {
                        ParentId: 2,
                        ChildComponentType: "Reports",
                        ChildComponentId: 22,
                        ChildComponent: "Total Customers by Segmentation",
                        isOpenComponent: false,
                        isBillableComponent: false,
                      },
                      {
                        ParentId: 2,
                        ChildComponentType: "Reports",
                        ChildComponentId: 23,
                        ChildComponent: "Customers List",
                        isOpenComponent: false,
                        isBillableComponent: false,
                      },
                      {
                        ParentId: 2,
                        ChildComponentType: "Reports",
                        ChildComponentId: 24,
                        ChildComponent: "Repeat Customers",
                        isOpenComponent: false,
                        isBillableComponent: false,
                      },
                      {
                        ParentId: 2,
                        ChildComponentType: "Reports",
                        ChildComponentId: 25,
                        ChildComponent: "Unique Customers by Period",
                        isOpenComponent: false,
                        isBillableComponent: false,
                      },
                      {
                        ParentId: 2,
                        ChildComponentType: "Reports",
                        ChildComponentId: 26,
                        ChildComponent: "Average Customer Visits",
                        isOpenComponent: false,
                        isBillableComponent: false,
                      },
                      {
                        ParentId: 2,
                        ChildComponentType: "Reports",
                        ChildComponentId: 27,
                        ChildComponent: "Total Sales by Customer Type",
                        isOpenComponent: false,
                        isBillableComponent: false,
                      },
                    ],
                  },
                  {
                    ParentComponentType: "Dashboards",
                    ParentComponentUID:
                      "12345678-1234-1234-1234-123456789101112",
                    ParentComponent: "Inventory Dashboard",
                    ChildComponent: [
                      {
                        ParentId: 4,
                        ChildComponentType: "Reports",
                        ChildComponentId: 13,
                        ChildComponent: "Most Popular Products",
                        isOpenComponent: false,
                        isBillableComponent: false,
                      },
                      {
                        ParentId: 4,
                        ChildComponentType: "Reports",
                        ChildComponentId: 14,
                        ChildComponent: "Product Mix",
                        isOpenComponent: false,
                        isBillableComponent: false,
                      },
                      {
                        ParentId: 4,
                        ChildComponentType: "Reports",
                        ChildComponentId: 15,
                        ChildComponent: "Popular Items with Repeat Customers",
                        isOpenComponent: false,
                        isBillableComponent: false,
                      },
                      {
                        ParentId: 4,
                        ChildComponentType: "Reports",
                        ChildComponentId: 28,
                        ChildComponent: "Products List",
                        isOpenComponent: false,
                        isBillableComponent: false,
                      },
                    ],
                  },
                  {
                    ParentComponentType: "Dashboards",
                    ParentComponentUID:
                      "12345678-1234-1234-1234-123456789101112",
                    ParentComponent: "People Dashboard",
                    ChildComponent: [
                      {
                        ParentId: 6,
                        ChildComponentType: "Reports",
                        ChildComponentId: 16,
                        ChildComponent: "Average Sales by Employee",
                        isOpenComponent: false,
                        isBillableComponent: false,
                      },
                      {
                        ParentId: 6,
                        ChildComponentType: "Reports",
                        ChildComponentId: 17,
                        ChildComponent: "Total Sales by Employee",
                        isOpenComponent: false,
                        isBillableComponent: false,
                      },
                      {
                        ParentId: 6,
                        ChildComponentType: "Reports",
                        ChildComponentId: 18,
                        ChildComponent: "Discounts by Employee",
                        isOpenComponent: false,
                        isBillableComponent: false,
                      },
                      {
                        ParentId: 6,
                        ChildComponentType: "Reports",
                        ChildComponentId: 29,
                        ChildComponent: "Employees List",
                        isOpenComponent: false,
                        isBillableComponent: false,
                      },
                    ],
                  },
                  {
                    ParentComponentType: "Dashboards",
                    ParentComponentUID:
                      "12345678-1234-1234-1234-123456789101112",
                    ParentComponent: "Sales Dashboard",
                    ChildComponent: [
                      {
                        ParentId: 3,
                        ChildComponentType: "Reports",
                        ChildComponentId: 7,
                        ChildComponent: "Gross Sales",
                        isOpenComponent: false,
                        isBillableComponent: false,
                      },
                      {
                        ParentId: 3,
                        ChildComponentType: "Reports",
                        ChildComponentId: 8,
                        ChildComponent: "Average Ticket Size",
                        isOpenComponent: false,
                        isBillableComponent: false,
                      },
                      {
                        ParentId: 3,
                        ChildComponentType: "Reports",
                        ChildComponentId: 9,
                        ChildComponent: "Total # of Transactions",
                        isOpenComponent: false,
                        isBillableComponent: false,
                      },
                      {
                        ParentId: 3,
                        ChildComponentType: "Reports",
                        ChildComponentId: 10,
                        ChildComponent: "Sales Summary",
                        isOpenComponent: false,
                        isBillableComponent: false,
                      },
                      {
                        ParentId: 3,
                        ChildComponentType: "Reports",
                        ChildComponentId: 11,
                        ChildComponent: "Sales by New vs Repeat Customers",
                        isOpenComponent: false,
                        isBillableComponent: false,
                      },
                      {
                        ParentId: 3,
                        ChildComponentType: "Reports",
                        ChildComponentId: 12,
                        ChildComponent: "Total Transactions by Hour",
                        isOpenComponent: false,
                        isBillableComponent: false,
                      },
                      {
                        ParentId: 3,
                        ChildComponentType: "Reports",
                        ChildComponentId: 31,
                        ChildComponent: "Sales by Payment Method",
                        isOpenComponent: false,
                        isBillableComponent: false,
                      },
                      {
                        ParentId: 3,
                        ChildComponentType: "Reports",
                        ChildComponentId: 32,
                        ChildComponent: "Sales by Order Channel",
                        isOpenComponent: false,
                        isBillableComponent: false,
                      },
                    ],
                  },
                  {
                    ParentComponentType: "Dashboards",
                    ParentComponentUID:
                      "12345678-1234-1234-1234-123456789101112",
                    ParentComponent: "Vendor Dashboard",
                    ChildComponent: [
                      {
                        ParentId: 5,
                        ChildComponentType: "Reports",
                        ChildComponentId: 30,
                        ChildComponent: "Vendors List",
                        isOpenComponent: false,
                        isBillableComponent: false,
                      },
                    ],
                  },
                ],
              },
            ],
          })
          console.warn(error.message)
        })
    })
  }, [entitySubscription?.subscriptionID])

  const selectPakages = () => {
    navigate("/subscription/packages", {
      state: {
        entitySubscription,
      },
    })
  }

  const invoiceTable = () => {
    navigate("/subscription/invoices")
  }

  return (
    <>
      <RouteTitle title="My Subscription Plan">
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            color: "accent",
            "&:hover": {
              textDecoration: "underline",
              color: "accent",
            },
          }}
        >
          <Invoices svgColor={theme.palette.accent} />
          <Typography
            variant="body1"
            sx={{
              fontSize: "13px",
              color: "accent",
              cursor: "pointer",
            }}
            onClick={invoiceTable}
          >
            View All Invoices
          </Typography>
        </Box>
      </RouteTitle>
      <Stack padding="15px 30px 15px 30px" gap={2}>
        <CustomPaper watermarkBg={true}>
          <Stack display="flex" flexDirection="column" gap={2}>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              gap={1}
            >
              {firstLoading ? (
                <CircularProgress
                  size="20px"
                  sx={{
                    color:
                      theme.palette.mode === "dark" ? "#FFFFFF" : "#707070",
                  }}
                />
              ) : (
                <Typography
                  variant="h6"
                  color="inherit"
                  mb={1}
                  fontWeight="fontWeightSemiBold"
                >
                  {entitySubscription?.EntitySubscriptionName}
                </Typography>
              )}
              <Box display="flex" flexDirection="row" gap={2}>
                <Button
                  onClick={selectPakages}
                  sx={{
                    padding: "10px 16px",
                    gap: "8px",
                    justifyContent: "center",
                    alignItems: "center",
                    textTransform: "none",
                    borderRadius: "24px",
                    border: `1px solid ${theme.palette.accent}`,
                    color: "accent",
                    "&:hover": {
                      backgroundColor: "accent",
                      color: "rgba(255, 255, 255, 1)",
                    },
                    "&:disabled": {
                      border: `1px solid ${theme.palette.accent}80`,
                      color: `accent80`,
                    },
                  }}
                >
                  View All Packages
                </Button>
                {entitySubscription?.totalAmount &&
                entitySubscription?.totalAmount !== 0 ? (
                  <Button
                    sx={{
                      width: "130px",
                      padding: "10px 16px",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "8px",
                      textTransform: "none",
                      borderRadius: "24px",
                      backgroundColor: "accent",
                      color: "rgba(255, 255, 255, 1)",
                      "&:hover": {
                        backgroundColor:
                          theme.palette.mode === "dark"
                            ? "rgba(255, 100, 0, 1)"
                            : "rgba(29, 71, 226, 1)",
                      },
                    }}
                  >
                    Pay Now
                  </Button>
                ) : null}
              </Box>
            </Box>

            <CustomPaper
              customStyles={{
                backgroundColor:
                  theme.palette.mode === "dark"
                    ? "#001D4D"
                    : "rgba(233, 237, 252, 1)",
                border:
                  theme.palette.mode === "dark" ? "1px solid #0044B3" : "none",
                borderRadius: "14px",
              }}
              innerCustomStyles={{
                border: "none",
              }}
            >
              {firstLoading ? (
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="center"
                  alignItems="center"
                  width="100%"
                  height="100px"
                >
                  <CircularProgress
                    size="40px"
                    sx={{
                      color:
                        theme.palette.mode === "dark" ? "#FFFFFF" : "#707070",
                    }}
                  />
                </Box>
              ) : (
                <>
                  <Box
                    sx={{
                      position: "relative",
                      right: "1.2rem",
                      width: "fit-content",
                      borderRadius: "0px 20px 20px 0px",
                      background:
                        "linear-gradient(97.13deg, #5468A5 20.11%, #1D47CE 94.09%)",
                      mb: 1.5,
                    }}
                  >
                    <Typography
                      variant="body1"
                      color="#fff"
                      sx={{
                        fontSize: "12px",
                        p: "0.5rem 1rem",
                      }}
                    >
                      {`Active Date: ${dayjs(
                        entitySubscription?.purchasedDate
                      ).format("Do MMMM YYYY")}`}
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                    flexWrap="wrap"
                  >
                    {[
                      {
                        label: "Subscription Status",
                        key: "subscriptionStatus",
                        format: false,
                      },
                      {
                        label: "Billing Cycle",
                        key: "billingCycle",
                        format: false,
                      },
                      {
                        label: "Next Billing Date",
                        key: "nextBillDate",
                        format: "MMMM D, YYYY",
                        formatter: (value, format) =>
                          dayjs(value).format(format),
                      },
                      {
                        label: "Invoice Amount",
                        key: "totalAmount",
                        format: "Currency",
                        formatter: (value, format) =>
                          getFormattedValue(value, format),
                      },
                    ].map((item) => (
                      <Stack display="flex" flexDirection="column">
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          fontSize={12}
                        >
                          {item.label}
                        </Typography>
                        <Typography variant="body2" color="inherit">
                          {item.format
                            ? item.formatter(
                                entitySubscription?.[item.key],
                                item.format
                              )
                            : entitySubscription?.[item.key]}
                        </Typography>
                      </Stack>
                    ))}
                  </Box>
                </>
              )}
            </CustomPaper>

            <CustomPaper
              customStyles={{
                backgroundColor:
                  theme.palette.mode === "dark"
                    ? "#001D4D"
                    : "rgba(233, 237, 252, 1)",
                border:
                  theme.palette.mode === "dark" ? "1px solid #0044B3" : "none",
                borderRadius: "14px",
              }} 
              innerCustomStyles={{
                border: "none",
              }}
            >
              <Box display="flex" flexDirection="row" mb={1}>
                {!secondLoading && (
                  <Stack display="flex" flexDirection="column">
                    <Typography color="inherit">
                      {entitySubscription?.PackageDetails?.[0]?.PackageName}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      fontSize={12}
                    >
                      {entitySubscription?.PackageDetails?.[0]?.PackageTierName}
                    </Typography>
                  </Stack>
                )}
              </Box>

              {secondLoading ? (
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="center"
                  alignItems="center"
                  width="100%"
                  height="100px"
                >
                  <CircularProgress
                    size="40px"
                    sx={{
                      color:
                        theme.palette.mode === "dark" ? "#FFFFFF" : "#707070",
                    }}
                  />
                </Box>
              ) : (
                <Masonry
                  columns={{ xs: 1, sm: 1, md: 2 }}
                  spacing={2}
                  sx={{ margin: "-10px -10px -20px -10px" }}
                >
                  {entitySubscriptionDetails?.SubscriptionDetails?.[0]?.ParentComponent?.map(
                    (item, idx) => (
                      <Box key={`${item?.ParentComponent}-${idx}`}>
                        <Accordion
                          square="false"
                          elevation={0}
                          sx={{
                            "&:before": { backgroundColor: "transparent" },
                            borderRadius: "8px",
                            boxShadow: "unset",
                            bgcolor:
                              theme.palette.mode === "dark"
                                ? "#071339"
                                : "#FFFFFF",
                          }}
                        >
                          <AccordionSummary
                            expandIcon={
                              <ExpandMoreIcon style={{ color: "#A4B5E9" }} />
                            }
                            aria-controls="panel1-content"
                            id="panel1-header"
                          >
                            <Typography color="inherit" variant="body1">
                              {item?.ParentComponent}
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <List
                              sx={{
                                listStyleType: "disc",
                                textWrap: "balance",
                                display: "flex",
                                flexDirection: "row",
                                flexWrap: "wrap",
                                rowGap: 1,
                                columnGap: 2,
                              }}
                              dense={true}
                            >
                              {item?.ChildComponent?.map((innerItem) => (
                                <ListItem
                                  key={innerItem}
                                  sx={{
                                    display: "flex",
                                    gap: "24px",
                                    maxWidth: "280px",
                                  }}
                                >
                                  <ListItemIcon
                                    sx={{
                                      minWidth: "unset",
                                    }}
                                  >
                                    <svg
                                      width="18"
                                      height="12"
                                      viewBox="0 0 18 12"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M1 6.9L4.14286 10.5L12 1.5M17 1.5625L8.4284 10.5625L8 10"
                                        stroke="#50CD89"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                    </svg>
                                  </ListItemIcon>
                                  <ListItemText
                                    primary={innerItem.ChildComponent}
                                  />
                                </ListItem>
                              ))}
                            </List>
                          </AccordionDetails>
                        </Accordion>
                      </Box>
                    )
                  )}
                </Masonry>
              )}
            </CustomPaper>
          </Stack>
        </CustomPaper>
      </Stack>
      <FixedBottomComponent price={entitySubscription?.totalAmount} />
    </>
  )
}

export default Subscription
