import { useState, useEffect } from "react"
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import CircularProgress from "@mui/material/CircularProgress"
import { useDispatch, useSelector } from "react-redux"
import NivoFunnelChart from "./NivoFunnelChart"
import { funnelData, funnelParameters } from "../../../constants/chartsData"
import extractKeyValuePairs from "../../../utils/extractKeyValuePairs"
import InfoToolTip from "../../InfoToolTip"
import ViewBy from "../../ViewBy"
import Analytica from "../../Analytica"
import InfoLogoSvg from "../../../assets/svgs/InfoLogoSvg"
import { useTheme } from "../../../contexts/theme"
import { postData } from "../../../api/api"

const FunnelChart = ({
  route,
  setChartData,
  title,
  description,
  postUrl,
  comparisonPeriod,
  reportUid,
  parameters,
  dateRangeData = { fromDate: "", toDate: "" },
  focus_mode = true,
  showLegend = true,
  showAiDropdown = true,
}) => {
  const { theme } = useTheme()
  const extractedParameters = extractKeyValuePairs(parameters)
  const [viewBy, setViewBy] = useState("Day")
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const dispatch = useDispatch()
  const [apiResponse, setApiResponse] = useState([])
  const [combinedState, setCombinedState] = useState({
    comparisonPeriod: comparisonPeriod,
    dateRangeData: dateRangeData,
  })
  useEffect(() => {
    setCombinedState({
      comparisonPeriod,
      dateRangeData,
    })
  }, [comparisonPeriod, dateRangeData])
  const svgColor = theme.palette.accentSecondary

  function convertValues(obj) {
    for (let key in obj) {
      if (typeof obj[key] === "object" && obj[key] !== null) {
        convertValues(obj[key])
      } else if (typeof obj[key] === "string") {
        if (!isNaN(obj[key])) {
          obj[key] = Number(obj[key])
        } else if (obj[key].toLowerCase() === "true") {
          obj[key] = true
        } else if (obj[key].toLowerCase() === "false") {
          obj[key] = false
        }
      }
    }
    return obj
  }

  extractedParameters.chartContainerDimensions ||
    (extractedParameters.chartContainerDimensions = {
      height: "600px",
      minHeight: "600px",
      maxHeight: "unset",
    })

  const tooltip_data = extractedParameters.tooltip_data || [
    {
      name: "Gross Sales",
      format: "Currency",
      color: "",
    },
    {
      name: "Profit Percent",
      format: "Percentage",
      color: "",
    },
    {
      name: "Customers",
      format: "Number",
      color: "",
    },
  ]

  const parseNumber = (value) => {
    if (typeof value === "number") return value
    if (typeof value === "string")
      return parseFloat(value.replace(/[^0-9.]/g, "")) || 0
    return 0
  }
  const showTooltipPrevious =
    extractedParameters.showTooltipPrevious === undefined
      ? true
      : extractedParameters.showTooltipPrevious

  function transformData(input) {
    if (
      !input ||
      !input.reportDataCurrent ||
      !Array.isArray(input.reportDataCurrent)
    ) {
      console.error("Invalid input data structure:", input)
      return []
    }

    let data = []
    input.reportDataCurrent.forEach((currentItem) => {
      const previousItem =
        input.reportDataPrevious?.find((p) => p.id === currentItem.id) || {}

      let eachElement = {
        id: currentItem.id,
        value: parseNumber(currentItem.value),

        tooltipData: {
          current: {},
          previous: {},
        },
      }

      tooltip_data?.forEach((tooltipDataItem) => {
        eachElement.tooltipData.current[tooltipDataItem.name] = {
          value: parseNumber(currentItem[tooltipDataItem.name]) || 0,
          format: tooltipDataItem.format,
        }

        eachElement.tooltipData.previous[tooltipDataItem.name] = {
          value: parseNumber(previousItem[tooltipDataItem.name]) || 0,
          format: tooltipDataItem.format,
        }
      })

      data.push(eachElement)
    })

    return data
  }

  function transformDataComparedBy(input) {
    if (!input || !Array.isArray(input)) {
      console.error("Invalid input data structure:", input)
      return []
    }

    let data = []

    input?.forEach((currentItem) => {
      let eachElement = {
        id: currentItem.id,
        ...currentItem,
        tooltipData: {
          current: {},
        },
      }

      tooltip_data?.forEach((tooltipDataItem) => {
        eachElement.tooltipData.current[tooltipDataItem.name] = {
          value: parseNumber(currentItem[tooltipDataItem.name]) || 0,
          format: tooltipDataItem.format,
        }
      })

      data.push(eachElement)
    })

    return data
  }

  const params = convertValues(extractedParameters)

  const fetchData = async () => {
    try {
      const response = await postData(postUrl, {
        entityDb: localStorage.getItem("entityDb"),
        fromDate: combinedState.dateRangeData.fromDate,
        toDate: combinedState.dateRangeData.toDate,
        reportInstanceId: reportUid,
        comparedBy: {
          previousFromDate: combinedState.comparisonPeriod.previousFromDate,
          previousToDate: combinedState.comparisonPeriod.previousToDate,
        },
        // viewBy: viewBy || "Day",f
      })

      setApiResponse(response)

      if (!response || Object.keys(response)?.length === 0) {
        setError("Inadequate data points to generate a report")
        dispatch(
          setChartData({
            reportUid,
            response:
              comparisonPeriod?.selectedValue === "Compared By"
                ? transformDataComparedBy(funnelData)
                : transformData(funnelData),
          })
        )
      } else {
        dispatch(
          setChartData({
            reportUid,
            response:
              comparisonPeriod?.selectedValue === "Compared By"
                ? transformDataComparedBy(response)
                : transformData(response),
          })
        )
      }
    } catch (error) {
      setError("Inadequate data points to generate a report")
      dispatch(
        setChartData({
          reportUid,
          response: funnelData,
        })
      )
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    setLoading(true)
    dispatch(
      setChartData({
        reportUid,
        viewBy: viewBy || "Day",
      })
    )
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    viewBy,
    combinedState.comparisonPeriod.previousFromDate,
    combinedState.comparisonPeriod.previousToDate,
    combinedState.dateRangeData.fromDate,
    combinedState.dateRangeData.toDate,
  ])

  const response = useSelector((state) => {
    const selectedReport = state[route]?.reports?.find(
      (report) => report.report_uid === reportUid
    )
    if (selectedReport) return selectedReport?.reportData
  })

  return (
    <>
      <Box
        display="flex"
        alignItems="flex-start"
        justifyContent="space-between"
        flexWrap={"wrap"}
        gap={"10px"}
      >
        <Box display="flex" alignItems="center" mb={"14px"}>
          <Typography color="inherit">{title}</Typography>
          <InfoToolTip title={description}>
            <span style={{ height: "16px", cursor: "pointer" }}>
              <InfoLogoSvg svgColor={svgColor} />
            </span>
          </InfoToolTip>
        </Box>

        <Box
          display="flex"
          alignItems="flex-start"
          justifyContent="space-between"
          gap={"2px"}
        >
          {extractedParameters.view_by_period && (
            <ViewBy
              route={route}
              parameters={
                extractedParameters && extractedParameters.view_by_period
              }
              viewBy={viewBy}
              setViewBy={setViewBy}
              reportUid={reportUid}
              dateRangeData={dateRangeData}
            />
          )}
          <Box display="flex">
            {extractedParameters.focus_mode && focus_mode && (
              <Analytica
                description={description}
                component="modal"
                chartDetails={{
                  route,
                  setChartData,
                  title,
                  description,
                  comparisonPeriod,
                  postUrl,
                  reportUid,
                  parameters,
                  dateRangeData,
                  showLegend,
                  apiResponse,
                }}
              />
            )}
          </Box>
        </Box>
      </Box>

      <Box minHeight="350px">
        {loading ? (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            alignContent="center"
            gap="10px"
            minHeight="350px"
            width="100%"
          >
            <CircularProgress size={45} color="info" />
            <Typography variant="body2">
              {" "}
              Fetching data, please wait...
            </Typography>
          </Box>
        ) : error ? (
          <NivoFunnelChart
            chartConfig={funnelParameters}
            chartInput={funnelData}
            reportTitle={title}
            reportData={apiResponse}
            showAiDropdown={showAiDropdown}
            showTooltipPrevious={showTooltipPrevious}
          />
        ) : (
          <NivoFunnelChart
            chartConfig={parameters || funnelParameters}
            chartInput={response}
            reportTitle={title}
            reportData={apiResponse}
            showAiDropdown={showAiDropdown}
            showTooltipPrevious={showTooltipPrevious}
          />
        )}
      </Box>
    </>
  )
}

export default FunnelChart
