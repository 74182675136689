import Box from "@mui/material/Box"
import DatePicker from "../../components/DatePicker"
import RouteTitle from "../../components/RouteTitle"
import Masonry from "@mui/lab/Masonry"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import Stack from "@mui/material/Stack"
import { getData } from "../../api/api"
import CustomPaper from "../../components/CustomPaper"
import KPICard from "../../components/KPICard"
import "./styles.scss"
import { loadData, setChartData } from "../../redux/slices/employeeSlice"
import BarChart from "../../components/charts/bar/BarChart"
import RouteLoader from "../../components/loader/RouteLoader"
import LineChart from "../../components/charts/line/LineChart"
import HeatMapChart from "../../components/charts/heatmap/HeatMapChart"
import PieChart from "../../components/charts/pie/PieChart"
import ScatterPlotChart from "../../components/charts/scatterplot/ScatterPlotChart"
import LineBarChart from "../../components/charts/lineBar/LineBarChart"
import StackedBarChart from "../../components/charts/stackedBar/StackedBarChart"
import ProgressBar from "../../components/charts/progressBar/ProgressBarChart"
import CompareBy from "../../components/CompareBy"
import DashLineChart from "../../components/charts/dashLine/DashLineChart"
import HorizontalBarChart from "../../components/charts/bar/horizontalBarGraph/HorizontalBarChart"
import BumpChart from "../../components/charts/bump/BumpChart"
import StreamChart from "../../components/charts/stream/StreamChart"
import {
  TopEmployeeList,
  TopEmployeeListParameters,
} from "../../constants/chartsData"
import CalendarChart from "../../components/charts/calendar/CalendarChart"
import Grid from "@mui/material/Grid"
import { useTheme } from "../../contexts/theme"
import Typography from "@mui/material/Typography"
import RadialBarChart from "../../components/charts/radialBar/RadialBarChart"
import SwarmPlot from "../../components/charts/swarmPlot/SwarmPlot"

const Employees = () => {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const [drawerOpen, setDrawerOpen] = useState(false)
  const employeeData = useSelector((state) => state.employee)
  const dateRangeData = useSelector((state) => state.calendar)
  const { theme } = useTheme()
  const ThemeMode = theme.palette.mode
  const fetchData = async () => {
    try {
      setIsLoading(true)
      const response = await getData("employee", {})
      await setTimeout(() => {}, 3000)
      dispatch(loadData(response))
    } catch (error) {
      console.error("Error fetching data:", error)
    } finally {
      setIsLoading(false)
    }
  }
  useEffect(() => {
    if (employeeData?.reports?.length === 0) {
      fetchData()
    } else {
      setIsLoading(false)
    }
  }, [employeeData?.reports?.length])

  const countReports = employeeData?.reports?.filter(
    (report) =>
      report.reportTypes.some((type) => type.ReportType === "Count") &&
      report.reportName !== "Total Employees"
  )

  const TotalEmployeesReport = employeeData?.reports?.find(
    (report) =>
      report.reportName === "Total Employees" &&
      report.reportTypes.some((type) => type.ReportType === "Count")
  )

  const otherReports = employeeData?.reports
    ?.filter(
      (report) =>
        report.reportName != "Employee Metrics" &&
        !report.reportTypes.some(
          (type) =>
            type.ReportType === "Count" || type.ReportType === "Calendar"
        ) &&
        report.reportName !== "Employee Performance"
    )
    .sort((a, b) => a.reportOrder - b.reportOrder)

  const fullscreenReport = employeeData?.reports?.filter((report) =>
    ["Employee Metrics"].includes(report.reportName)
  )

  const calendarReports = employeeData?.reports?.filter((report) => {
    return report.reportTypes.some((type) => type.ReportType === "Calendar")
  })

  const employeePerformanceReport = employeeData?.reports?.filter(
    (report) => report.reportName === "Employee Performance"
  )?.[0]

  // const handleDrawerOpen = () => {
  //   setDrawerOpen(true)
  // }

  // const handleDrawerClose = () => {
  //   setDrawerOpen(false)
  // }
  // const alerts = [
  //   {
  //     message:
  //       "Two employee' schedules overlap. Please review and adjust the shifts to avoid conflicts.",
  //     btnName: "Check Now →",
  //     isOpen: handleDrawerOpen,
  //   },
  // ]
  const comparisonPeriod = useSelector((state) => state.period.selectedValue)

  const BumpReports = employeeData?.reports?.filter((report) => {
    return report.reportTypes.some((type) => type.ReportType === "Bump")
  })
  return (
    <>
      {isLoading ? (
        <RouteLoader />
      ) : (
        <>
          <RouteTitle>
            <Stack sx={{ maxWidth: { md: "1250px" } }} gap="3px">
              <Typography
                variant="subtitle1"
                color="inherit"
                fontWeight="fontWeightMedium"
              >
                {`Good to see you again, ${localStorage.getItem("fname")}!`}
              </Typography>
              <Typography
                variant="body2"
                color="inherit"
                fontWeight="fontWeightLight"
                sx={{ fontSize: "0.8rem", opacity: 0.9 }}
              >
                Name: Demo Restaurants &nbsp;
                <span style={{ opacity: 0.6 }}>|</span> Address: 1000 Main st,
                Houston, TX&nbsp;
                <span style={{ opacity: 0.6 }}>|</span> Phone: +1 111 111
                1111&nbsp;
                <span style={{ opacity: 0.6 }}>|</span> Location: Houston&nbsp;
                <span style={{ opacity: 0.6 }}>|</span> Site: Houston
              </Typography>
            </Stack>
            <Box
              display="flex"
              alignItems="flex-start"
              justifyContent="space-between"
              maxWidth="auto"
              gap={2}
            >
              <CompareBy dateRangeData={dateRangeData} />
              <DatePicker />
            </Box>
          </RouteTitle>
          {/* <Stack paddingInline="30px" flexWrap="wrap">
            {alerts.map((alert, index) => (
              <TransitionAlerts
                key={index}
                message={alert.message}
                btnName={alert.btnName}
                isOpen={alert.isOpen}
                additionalStyle={{
                  marginBottom: index !== alerts.length - 1 ? "10px" : "0",
                }}
              />
            ))}
            <DrawerComponent open={drawerOpen} onClose={handleDrawerClose} />
          </Stack> */}
          <Box padding="5px 20px 15px 20px">
            <CustomPaper
              customStyles={{
                backgroundColor: ThemeMode === "dark" ? "#030816" : "#E9EDFC",
                margin: "10px",
              }}
              innerCustomStyles={{ margin: "0px" }}
              watermarkBg={true}
              CustomDrawerComponent={true}
            >
              {TotalEmployeesReport && (
                <Grid
                  key={TotalEmployeesReport.report_uid}
                  sx={{ width: "12rem", pb: 1 }}
                >
                  <KPICard
                    title={TotalEmployeesReport.reportName}
                    parameters={
                      TotalEmployeesReport.reportTypes[0].reportParameterTypes
                    }
                    reportUid={TotalEmployeesReport.report_uid}
                    dateRangeData={dateRangeData}
                    description={TotalEmployeesReport.reportDescription}
                    postUrl="sales/data"
                    comparisonPeriod={comparisonPeriod}
                    showPrevious={false}
                  />
                </Grid>
              )}
              {countReports && (
                <section className="employee_grid_container">
                  {countReports?.map((report, index) => (
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      lg={2.25}
                      key={report.report_uid}
                    >
                      <CustomPaper
                        key={report.report_uid}
                        className={
                          index > 8
                            ? "employee_grid_item_other"
                            : `employee_grid_item_${index + 1}`
                        }
                        watermarkBg={true}
                        customStyles={{
                          position: "relative",
                        }}
                      >
                        {/* Coming soon overlay */}
                        {report.report_uid ===
                          "B0F3215C-1C94-4BC1-B642-88177B37FBD3" && (
                          <Box
                            width="100%"
                            height="100%"
                            display="flex"
                            flexDirection="row"
                            justifyContent="center"
                            alignItems="center"
                            sx={{
                              borderRadius: "inherit",
                              background:
                                theme.palette.mode === "dark"
                                  ? "#00000085"
                                  : "#FFFFFFA0",
                              position: "absolute",
                              zIndex: 1,
                              top: "0px",
                              left: "0px",
                            }}
                          >
                            <Typography
                              variant="body1"
                              color="inherit"
                              sx={{
                                p: 1,
                                borderRadius: 1,
                                // textShadow: `0 0 0.5px ${theme.palette.text.primary}`,
                              }}
                              fontWeight="bold"
                            >
                              Coming soon
                            </Typography>
                          </Box>
                        )}

                        <KPICard
                          title={report.reportName}
                          parameters={
                            report.reportTypes[0].reportParameterTypes
                          }
                          dateRangeData={dateRangeData}
                          postUrl="employee/data"
                          reportUid={report.report_uid}
                          width="100%"
                          height="6rem"
                          comparisonPeriod={comparisonPeriod}
                        />
                      </CustomPaper>
                    </Grid>
                  ))}
                </section>
              )}
            </CustomPaper>

            {fullscreenReport?.map(
              (report) =>
                ({
                  Bar: (
                    <CustomPaper
                      key={report.report_uid}
                      customStyles={{ overflow: "visible", margin: 1 }}
                      innerCustomStyles={{ margin: 0 }}
                    >
                      <BarChart
                        key={report.report_uid}
                        route="employee"
                        title={report.reportName}
                        comparisonPeriod={comparisonPeriod}
                        description={report.reportDescription}
                        postUrl="employee/data"
                        setChartData={setChartData}
                        reportUid={report.report_uid}
                        parameters={report.reportTypes[0].reportParameterTypes}
                        dateRangeData={dateRangeData}
                      />
                    </CustomPaper>
                  ),
                }[report.reportTypes[0].ReportType])
            )}

            <Masonry
              columns={{ xs: 1, sm: 1, md: 2 }}
              spacing={2}
              id="masonry"
              sx={{ margin: "0px 0px -20px 0px" }}
            >
              {otherReports?.map(
                (report) =>
                  ({
                    Line: (
                      <CustomPaper
                        key={report.report_uid}
                        customStyles={{ overflow: "visible" }}
                      >
                        <LineChart
                          route="employee"
                          setChartData={setChartData}
                          title={report.reportName}
                          description={report.reportDescription}
                          postUrl="employee/data"
                          reportUid={report.report_uid}
                          parameters={
                            report.reportTypes[0].reportParameterTypes
                          }
                          dateRangeData={dateRangeData}
                          comparisonPeriod={comparisonPeriod}
                        />
                      </CustomPaper>
                    ),
                    Pie: (
                      <CustomPaper
                        key={report.report_uid}
                        customStyles={{ overflow: "visible" }}
                      >
                        <PieChart
                          route="employee"
                          setChartData={setChartData}
                          comparisonPeriod={comparisonPeriod}
                          title={report.reportName}
                          description={report.reportDescription}
                          postUrl="employee/data"
                          reportUid={report.report_uid}
                          parameters={
                            report.reportTypes[0].reportParameterTypes
                          }
                          dateRangeData={dateRangeData}
                        />
                      </CustomPaper>
                    ),
                    // List: (
                    //   <Table
                    //     key={report.report_uid}
                    //     route="employee"
                    //     postUrl="employee/list"
                    //     reportUid={report.report_uid}
                    //     parameters={report.reportTypes[0].reportParameterTypes}
                    //     setChartData={setChartData}
                    //     title={report.reportName}
                    //     showExportButton={false}
                    //     // hidePageNumbers={true}
                    //     dateRangeData={dateRangeData}
                    //     StaticData={TopEmployeeList}
                    //     StaticParameters={TopEmployeeListParameters}
                    //   />
                    // ),
                    Bar: (
                      <CustomPaper
                        key={report.report_uid}
                        customStyles={{ overflow: "visible" }}
                      >
                        <BarChart
                          route="employee"
                          setChartData={setChartData}
                          title={report.reportName}
                          comparisonPeriod={comparisonPeriod}
                          description={report.reportDescription}
                          postUrl="employee/data"
                          reportUid={report.report_uid}
                          parameters={
                            report.reportTypes[0].reportParameterTypes
                          }
                          dateRangeData={dateRangeData}
                        />
                      </CustomPaper>
                    ),
                    LineBar: (
                      <CustomPaper
                        key={report.report_uid}
                        customStyles={{ overflow: "visible" }}
                      >
                        <LineBarChart
                          route="employee"
                          setChartData={setChartData}
                          title={report.reportName}
                          description={report.reportDescription}
                          postUrl="employee/data"
                          reportUid={report.report_uid}
                          parameters={
                            report.reportTypes[0].reportParameterTypes
                          }
                          dateRangeData={dateRangeData}
                          comparisonPeriod={comparisonPeriod}
                        />
                      </CustomPaper>
                    ),
                    Heatmap: (
                      <CustomPaper
                        key={report.report_uid}
                        customStyles={{ overflow: "visible" }}
                      >
                        <HeatMapChart
                          route="employee"
                          setChartData={setChartData}
                          title={report.reportName}
                          description={report.reportDescription}
                          postUrl="employee/data"
                          reportUid={report.report_uid}
                          parameters={
                            report.reportTypes[0].reportParameterTypes
                          }
                          comparisonPeriod={comparisonPeriod}
                          dateRangeData={dateRangeData}
                        />
                      </CustomPaper>
                    ),
                    ProgressBar: (
                      <CustomPaper
                        key={report.report_uid}
                        customStyles={{ overflow: "visible" }}
                      >
                        <ProgressBar
                          route="employee"
                          setChartData={setChartData}
                          title={report.reportName}
                          description={report.reportDescription}
                          postUrl="employee/data"
                          reportUid={report.report_uid}
                          parameters={
                            report.reportTypes[0].reportParameterTypes
                          }
                          dateRangeData={dateRangeData}
                          comparisonPeriod={comparisonPeriod}
                        />
                      </CustomPaper>
                    ),
                    ScatterPlot: (
                      <CustomPaper
                        key={report.report_uid}
                        customStyles={{ overflow: "visible" }}
                      >
                        <ScatterPlotChart
                          route="employee"
                          setChartData={setChartData}
                          title={report.reportName}
                          description={report.reportDescription}
                          postUrl="employee/data"
                          reportUid={report.report_uid}
                          parameters={
                            report.reportTypes[0].reportParameterTypes
                          }
                          dateRangeData={dateRangeData}
                          comparisonPeriod={comparisonPeriod}
                        />
                      </CustomPaper>
                    ),
                    StackedBar: (
                      <CustomPaper
                        key={report.report_uid}
                        customStyles={{ overflow: "visible" }}
                      >
                        <StackedBarChart
                          route="employee"
                          setChartData={setChartData}
                          title={report.reportName}
                          description={report.reportDescription}
                          postUrl="employee/data"
                          reportUid={report.report_uid}
                          parameters={
                            report.reportTypes[0].reportParameterTypes
                          }
                          dateRangeData={dateRangeData}
                          comparisonPeriod={comparisonPeriod}
                        />
                      </CustomPaper>
                    ),
                    DualHorizontalBar: (
                      <CustomPaper
                        key={report.report_uid}
                        customStyles={{ overflow: "visible" }}
                      >
                        <HorizontalBarChart
                          route="employee"
                          setChartData={setChartData}
                          title={report.reportName}
                          description={report.reportDescription}
                          postUrl="employee/data"
                          reportUid={report.report_uid}
                          parameters={
                            report.reportTypes[0].reportParameterTypes
                          }
                          dateRangeData={dateRangeData}
                          comparisonPeriod={comparisonPeriod}
                        />
                      </CustomPaper>
                    ),
                    DashLine: (
                      <CustomPaper
                        key={report.report_uid}
                        customStyles={{ overflow: "visible" }}
                      >
                        <DashLineChart
                          route="employee"
                          setChartData={setChartData}
                          title={report.reportName}
                          description={report.reportDescription}
                          postUrl="employee/data"
                          reportUid={report.report_uid}
                          parameters={
                            report.reportTypes[0].reportParameterTypes
                          }
                          dateRangeData={dateRangeData}
                          comparisonPeriod={comparisonPeriod}
                        />
                      </CustomPaper>
                    ),
                    Stream: (
                      <CustomPaper
                        key={report.report_uid}
                        customStyles={{
                          overflow: "visible",
                          margin: "10px",
                          position: "relative",
                        }}
                        innerCustomStyles={{ margin: "unset" }}
                      >
                        {/* Coming soon overlay */}
                        {report.report_uid ===
                          "88B8EC37-5F61-4CBC-A762-014C8D9A14E7" && (
                          <Box
                            width="100%"
                            height="100%"
                            display="flex"
                            flexDirection="row"
                            justifyContent="center"
                            alignItems="center"
                            sx={{
                              borderRadius: "inherit",
                              background:
                                theme.palette.mode === "dark"
                                  ? "#00000085"
                                  : "#FFFFFFA0",
                              position: "absolute",
                              zIndex: 1,
                              top: "0px",
                              left: "0px",
                            }}
                          >
                            <Typography
                              variant="body1"
                              color="inherit"
                              sx={{
                                p: 1,
                                borderRadius: 1,
                                // textShadow: `0 0 0.5px ${theme.palette.text.primary}`,
                              }}
                              fontWeight="bold"
                            >
                              Coming soon
                            </Typography>
                          </Box>
                        )}
                        <StreamChart
                          route="employee"
                          setChartData={setChartData}
                          title={report.reportName}
                          description={report.reportDescription}
                          postUrl="employee/data"
                          reportUid={report.report_uid}
                          parameters={
                            report.reportTypes[0].reportParameterTypes
                          }
                          dateRangeData={dateRangeData}
                          comparisonPeriod={comparisonPeriod}
                        />
                      </CustomPaper>
                    ),
                    RadialBar: (
                      <CustomPaper
                        key={report.report_uid}
                        customStyles={{ overflow: "visible" }}
                      >
                        <RadialBarChart
                          route="employee"
                          setChartData={setChartData}
                          title={report.reportName}
                          description={report.reportDescription}
                          postUrl="employee/data"
                          reportUid={report.report_uid}
                          parameters={
                            report.reportTypes[0].reportParameterTypes
                          }
                          dateRangeData={dateRangeData}
                          comparisonPeriod={comparisonPeriod}
                        />
                      </CustomPaper>
                    ),
                    SwarmPlot: (
                      <CustomPaper
                        key={report.report_uid}
                        customStyles={{ overflow: "visible" }}
                      >
                        <SwarmPlot
                          route="employee"
                          setChartData={setChartData}
                          title={report.reportName}
                          description={report.reportDescription}
                          postUrl="employee/data"
                          reportUid={report.report_uid}
                          parameters={
                            report.reportTypes[0].reportParameterTypes
                          }
                          dateRangeData={dateRangeData}
                          comparisonPeriod={comparisonPeriod}
                        />
                      </CustomPaper>
                    ),
                  }[report.reportTypes[0].ReportType])
              )}
              {/* <Table
                route="employee"
                setChartData={setChartData}
                title="Top Items Sold by Employees"
                showExportButton={false}
                hidePageNumbers={true}
                dateRangeData={dateRangeData}
                reportUid="static-List"
                postUrl="employee/list"
                StaticData={TopEmployeeList}
                StaticParameters={TopEmployeeListParameters}
              /> */}

              {/* <CustomPaper
                // key={report.report_uid}
                customStyles={{ overflow: "visible" }}
              >
                <SwarmPlot
                  route="employee"
                  setChartData={setChartData}
                  title="Sales by employee"
                  description="Sales by employee"
                  postUrl="employee/data"
                  // reportUid={report.report_uid}
                  // parameters={report.reportTypes[0].reportParameterTypes}
                  dateRangeData={dateRangeData}
                  comparisonPeriod={comparisonPeriod}
                />
              </CustomPaper> */}
            </Masonry>
            {BumpReports?.map(
              (report) =>
                ({
                  Bump: (
                    <CustomPaper
                      key={report.report_uid}
                      customStyles={{
                        overflow: "visible",
                        margin: "10px",
                        position: "relative",
                      }}
                      innerCustomStyles={{ margin: "unset" }}
                    >
                      {/* Coming soon overlay */}
                      {report.report_uid ===
                        "BF188CA3-C645-4D48-9066-E25ADB39BAAE" && (
                        <Box
                          width="100%"
                          height="100%"
                          display="flex"
                          flexDirection="row"
                          justifyContent="center"
                          alignItems="center"
                          sx={{
                            borderRadius: "inherit",
                            background:
                              theme.palette.mode === "dark"
                                ? "#00000085"
                                : "#FFFFFFA0",
                            position: "absolute",
                            zIndex: 1,
                            top: "0px",
                            left: "0px",
                          }}
                        >
                          <Typography
                            variant="body1"
                            color="inherit"
                            sx={{
                              p: 1,
                              borderRadius: 1,
                              // textShadow: `0 0 0.5px ${theme.palette.text.primary}`,
                            }}
                            fontWeight="bold"
                          >
                            Coming soon
                          </Typography>
                        </Box>
                      )}
                      <BumpChart
                        route="employee"
                        setChartData={setChartData}
                        title={report.reportName}
                        description={report.reportDescription}
                        postUrl="employee/data"
                        reportUid={report.report_uid}
                        parameters={report.reportTypes[0].reportParameterTypes}
                        dateRangeData={dateRangeData}
                        comparisonPeriod={comparisonPeriod}
                      />
                    </CustomPaper>
                  ),
                }[report.reportTypes[0].ReportType])
            )}

            {calendarReports?.map(
              (report) =>
                ({
                  Calendar: (
                    <CustomPaper
                      key={report.report_uid}
                      customStyles={{
                        overflow: "visible",
                        margin: "10px",
                      }}
                      innerCustomStyles={{ margin: "unset" }}
                    >
                      <CalendarChart
                        route="employee"
                        setChartData={setChartData}
                        title={report.reportName}
                        description={report.reportDescription}
                        comparisonPeriod={comparisonPeriod}
                        postUrl="employee/data"
                        reportUid={report.report_uid}
                        parameters={report.reportTypes[0].reportParameterTypes}
                        dateRangeData={dateRangeData}
                      />
                    </CustomPaper>
                  ),
                }[report.reportTypes[0].ReportType])
            )}

            {employeePerformanceReport && (
              <CustomPaper
                key={employeePerformanceReport.report_uid}
                customStyles={{ overflow: "visible", margin: 1 }}
                innerCustomStyles={{ margin: 0 }}
              >
                <StreamChart
                  route="employee"
                  setChartData={setChartData}
                  title={employeePerformanceReport.reportName}
                  description={employeePerformanceReport.reportDescription}
                  postUrl="employee/data"
                  reportUid={employeePerformanceReport.report_uid}
                  parameters={
                    employeePerformanceReport.reportTypes[0]
                      .reportParameterTypes
                  }
                  dateRangeData={dateRangeData}
                  comparisonPeriod={comparisonPeriod}
                />
              </CustomPaper>
            )}
          </Box>
        </>
      )}
    </>
  )
}

export default Employees
