import NivoBarChart from "./NivoBarchart"
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import InfoLogoSvg from "../../../assets/svgs/InfoLogoSvg"
import { useTheme } from "../../../contexts/theme"
import { useDispatch, useSelector } from "react-redux"
import { useEffect, useState } from "react"
import { postData } from "../../../api/api"
import CircularProgress from "@mui/material/CircularProgress"
import { BarChartData, BarChartParameters } from "../../../constants/chartsData"
import extractKeyValuePairs from "../../../utils/extractKeyValuePairs"
import InfoToolTip from "../../InfoToolTip"
import ViewBy from "../../ViewBy"
import Analytica from "../../Analytica"
import { MostWastedProducts } from "../../../constants/MostWastedProducts"
import { ComparisonofExpensesandRevenueData } from "../../../constants/ComparisonofExpensesandRevenueData"
import { CustomerDetailsBarChartData } from "../../../constants/CustomerDetailsBarChartData"

const BarChart = ({
  route,
  setChartData,
  title,
  description,
  comparisonPeriod,
  postUrl,
  reportUid,
  parameters,
  dateRangeData = {
    fromDate: "",
    toDate: "",
  },
  showLegend = true,
  focus_mode = true,
  performerFlagIcon,
  showAiDropdown = true,
  colorFlag,
}) => {
  const { theme } = useTheme()
  const extractedParameters = extractKeyValuePairs(parameters)
  const [viewBy, setViewBy] = useState("")
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const dispatch = useDispatch()
  const [apiResponse, setApiResponse] = useState([])
  const svgColor = theme.palette.accentSecondary
  const extractedPara = extractKeyValuePairs(parameters)
  const [combinedState, setCombinedState] = useState({
    comparisonPeriod: comparisonPeriod,
    dateRangeData: dateRangeData,
  })
  useEffect(() => {
    setCombinedState({
      comparisonPeriod,
      dateRangeData,
    })
  }, [comparisonPeriod, dateRangeData])
  function convertValues(obj) {
    for (let key in obj) {
      if (typeof obj[key] === "object" && obj[key] !== null) {
        convertValues(obj[key])
      } else if (typeof obj[key] === "string") {
        if (!isNaN(obj[key])) {
          obj[key] = Number(obj[key])
        } else if (obj[key].toLowerCase() === "true") {
          obj[key] = true
        } else if (obj[key].toLowerCase() === "false") {
          obj[key] = false
        }
      }
    }
    return obj
  }

  extractedPara.chartContainerDimensions ||
    (extractedPara.chartContainerDimensions = {
      height: "350px",
      minHeight: "350px",
      maxHeight: "unset",
    })

  const tooltip_data = extractedParameters.tooltip_data || [
    {
      name: "Gross Sales",
      format: "Currency",
      color: "",
    },
    {
      name: "Customers",
      format: "Number",
      color: "",
    },
    {
      name: "Transactions",
      format: "Number",
      color: "",
    },
    {
      name: "Average Ticket",
      format: "Currency",
      color: "",
    },
  ]

  const showTooltipPrevious =
    extractedParameters.showTooltipPrevious === undefined
      ? true
      : extractedParameters.showTooltipPrevious

  const parseNumber = (value) => {
    if (typeof value === "number") return value
    if (typeof value === "string") {
      const cleanedValue = value.replace(/[^0-9.]/g, "")
      return cleanedValue ? parseFloat(cleanedValue) || 0 : value
    }
    return 0
  }

  function transformData(input) {
    // TODO: Combine compared by case here itself
    if (!input || !input.reportDataCurrent || !input.reportDataPrevious) {
      console.error("Invalid input data structure:", input)
      return []
    }

    let data = []

    input.reportDataCurrent?.forEach((currentItem) => {
      const previousItem =
        input?.reportDataPrevious?.find((p) => p.id === currentItem.id) || {}

      let eachElement = {
        id: currentItem.id,
        ...currentItem,
        tooltipData: {
          current: {},
          previous: {},
        },
      }

      tooltip_data?.forEach((tooltipDataItem) => {
        eachElement.tooltipData.current[tooltipDataItem.name] = {
          value: parseNumber(currentItem[tooltipDataItem.name]) || 0,
          format: tooltipDataItem.format,
        }

        eachElement.tooltipData.previous[tooltipDataItem.name] = {
          value: parseNumber(previousItem[tooltipDataItem.name]) || 0,
          format: tooltipDataItem.format,
        }
      })

      data.push(eachElement)
    })

    return data
  }

  function transformDataComparedBy(input) {
    if (!input || !Array.isArray(input)) {
      console.error("Invalid input data structure:", input)
      return []
    }

    let data = []

    input?.forEach((currentItem) => {
      let eachElement = {
        id: currentItem.id,
        ...currentItem,
        tooltipData: {
          current: {},
        },
      }

      tooltip_data?.forEach((tooltipDataItem) => {
        eachElement.tooltipData.current[tooltipDataItem.name] = {
          value: parseNumber(currentItem[tooltipDataItem.name]) || 0,
          format: tooltipDataItem.format,
        }
      })

      data.push(eachElement)
    })

    return data
  }

  const params = convertValues(extractedParameters)

  const fetchData = async () => {
    try {
      let response

      if (reportUid === "static-barchart") {
        response = false
      } else {
        extractedParameters.view_by_period
          ? (response = await postData(postUrl, {
              entityDb: localStorage.getItem("entityDb"),
              fromDate: combinedState.dateRangeData.fromDate,
              toDate: combinedState.dateRangeData.toDate,
              reportInstanceId: reportUid,
              comparedBy: {
                previousFromDate:
                  combinedState.comparisonPeriod.previousFromDate,
                previousToDate: combinedState.comparisonPeriod.previousToDate,
              },
              viewBy: viewBy || "Day",
            }))
          : (response = await postData(postUrl, {
              entityDb: localStorage.getItem("entityDb"),
              fromDate: combinedState.dateRangeData.fromDate,
              toDate: combinedState.dateRangeData.toDate,
              reportInstanceId: reportUid,
              comparedBy: {
                previousFromDate:
                  combinedState.comparisonPeriod.previousFromDate,
                previousToDate: combinedState.comparisonPeriod.previousToDate,
              },
              // viewBy: viewBy || "Day",
            }))
      }

      setApiResponse(response)

      if (!!response === false || Object.keys(response).length === 0) {
        setError("Inadequate data points to generate a report")
        dispatch(
          setChartData({
            reportUid,
            response:
              comparisonPeriod?.selectedValue === "Compared By"
                ? transformDataComparedBy(BarChartData)
                : transformData(BarChartData),
          })
        )
      } else {
        dispatch(
          setChartData({
            reportUid,
            response:
              comparisonPeriod?.selectedValue === "Compared By"
                ? transformDataComparedBy(response)
                : transformData(response),
          })
        )
      }
    } catch (error) {
      setError("Inadequate data points to generate a report")
      dispatch(
        setChartData({
          reportUid,
          response: BarChartData,
        })
      )
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    setLoading(true)
    dispatch(
      setChartData({
        reportUid,
        viewBy: viewBy || "Day",
      })
    )
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    viewBy,
    combinedState.comparisonPeriod.previousFromDate,
    combinedState.comparisonPeriod.previousToDate,
    combinedState.dateRangeData.fromDate,
    combinedState.dateRangeData.toDate,
  ])

  const response = useSelector((state) => {
    const selectedReport = state[route]?.reports?.find(
      (report) => report.report_uid === reportUid
    )

    if (selectedReport) return selectedReport?.reportData
  })

  const config = parameters.length === 1 ? BarChartParameters : params

  const chartDataMap = {
    "Comparison of Expenses and Revenue": ComparisonofExpensesandRevenueData,
    "Most Wasted Products": MostWastedProducts,
    "Customer-Details-Page": CustomerDetailsBarChartData,
  }

  const chartInput = Array.isArray(response)
    ? response
    : chartDataMap[title] || BarChartData

  return (
    <>
      <Box
        display="flex"
        alignItems="flex-start"
        justifyContent="space-between"
        flexWrap={"wrap"}
        gap={"10px"}
      >
        <Box display="flex" alignItems="center">
          <Typography color="inherit">{title}</Typography>
          <InfoToolTip title={description}>
            <span style={{ height: "16px", cursor: "pointer" }}>
              <InfoLogoSvg svgColor={svgColor} />
            </span>
          </InfoToolTip>
        </Box>
        {extractedParameters.view_by_period && (
          <ViewBy
            route={route}
            parameters={
              extractedParameters && extractedParameters.view_by_period
            }
            viewBy={viewBy}
            setViewBy={setViewBy}
            reportUid={reportUid}
            dateRangeData={dateRangeData}
          />
        )}
        {extractedParameters.focus_mode && focus_mode && (
          <Analytica
            description={description}
            component="modal"
            reportType="Bar"
            chartDetails={{
              route,
              setChartData,
              title,
              description,
              comparisonPeriod,
              postUrl,
              reportUid,
              parameters,
              dateRangeData,
              showLegend,
              apiResponse,
            }}
          />
        )}
      </Box>

      {loading ? (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          alignContent="center"
          gap="10px"
          minHeight="350px"
          width="100%"
        >
          <CircularProgress size={45} color="info" />
          <Typography variant="body2" color="inherit">
            Fetching data, please wait...
          </Typography>
        </Box>
      ) : error ? (
        <NivoBarChart
          chartInput={chartInput}
          parameters={config}
          performerFlagIcon={performerFlagIcon}
          reportTitle={title}
          reportData={apiResponse}
          showAiDropdown={showAiDropdown}
          colorFlag={colorFlag}
          showTooltipPrevious={showTooltipPrevious}
        />
      ) : (
        <NivoBarChart
          chartInput={chartInput}
          parameters={config}
          performerFlagIcon={performerFlagIcon}
          reportTitle={title}
          reportData={apiResponse}
          showAiDropdown={showAiDropdown}
          colorFlag={colorFlag}
          showTooltipPrevious={showTooltipPrevious}
        />
      )}
    </>
  )
}

export default BarChart
