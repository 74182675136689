import { ResponsiveFunnel } from "@nivo/funnel"
import Box from "@mui/material/Box"
import CustomTooltip from "../../CustomTooltip"
import { useEffect, useMemo, useRef, useState } from "react"
import CustomLegend from "../../CustomLegend"
import { useOrdinalColorScale } from "@nivo/colors"
import { useTheme } from "../../../contexts/theme"

const NivoFunnelChart = ({
  chartInput = [{}],
  chartConfig,
  reportTitle,
  reportData,
  showAiDropdown,
  showTooltipPrevious,
}) => {
  const { theme } = useTheme()
  const chartContainerRef = useRef(null)
  const [chartWidth, setChartWidth] = useState(0)

  useEffect(() => {
    if (chartContainerRef.current) {
      setChartWidth(chartContainerRef.current.offsetWidth)
    }
  }, [chartContainerRef])

  const {
    chartContainerDimensions = {
      height: "600px",
    },
  } = chartConfig

  const getColor = useOrdinalColorScale({ scheme: "set3" }, "id")
  const itemTextColor = theme.palette.mode === "dark" ? "#fff" : "#333333"

  const legendsData = useMemo(() => {
    return (
      chartInput?.map((item) => ({
        label: item.id,
        color: getColor(item),
      })) || []
    )
  }, [chartInput, getColor])

  const PartLabel = ({ part }) => {
    const labelXOffset = part.width + 10
    const maxLabelPosition = chartWidth - 210
    const adjustedXOffset = Math.min(part.x + labelXOffset, maxLabelPosition)

    const verticalMargin = 20

    return (
      <g
        transform={`translate(${adjustedXOffset}, ${
          part.y + part.height / 2 - verticalMargin
        })`}
      >
        <text
          textAnchor="start"
          dominantBaseline="middle"
          style={{
            fill: itemTextColor,
            pointerEvents: "none",
          }}
        >
          {part.data.id}
        </text>
      </g>
    )
  }

  const Labels = (props) => {
    return props.parts.map((part) => (
      <PartLabel key={part.data.id} part={part} chartWidth={chartWidth} />
    ))
  }

  return (
    <Box display="flex" flexDirection="column" height="100%" gap={1}>
      <Box ref={chartContainerRef} sx={{ ...chartContainerDimensions }}>
        <ResponsiveFunnel
          data={chartInput}
          margin={{ top: 20, right: 150, bottom: 20, left: 80 }}
          spacing={0}
          valueFormat=">-.4r"
          layers={["separators", "parts", Labels, "annotations"]}
          colors={{ scheme: "set3" }}
          borderWidth={20}
          borderColor={{ from: "color", modifiers: [["darker", 0.2]] }}
          labelColor={{ from: "color", modifiers: [["darker", 3]] }}
          enableBeforeSeparators={false}
          beforeSeparatorLength={100}
          beforeSeparatorOffset={20}
          enableAfterSeparators={false}
          afterSeparatorLength={100}
          afterSeparatorOffset={20}
          currentPartSizeExtension={10}
          currentBorderWidth={40}
          motionConfig="gentle"
          tooltip={(point) => (
            <CustomTooltip
              data={point?.part?.data || {}}
              showCurrentPrevDate={true}
              showTooltipPrevious={showTooltipPrevious}
            />
          )}
        />
      </Box>
      <CustomLegend
        legendsData={legendsData}
        reportTitle={reportTitle}
        reportData={reportData}
        showAiDropdown={showAiDropdown}
      />
    </Box>
  )
}

export default NivoFunnelChart
