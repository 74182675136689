import { useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { getData } from "../../api/api"
import Stack from "@mui/material/Stack"
import Typography from "@mui/material/Typography"
import { loadData, setChartData } from "../../redux/slices/inventorySlice"
import Masonry from "@mui/lab/Masonry"
import Box from "@mui/material/Box"
import Grid from "@mui/material/Grid"
import KPICard from "../../components/KPICard"
import BarChart from "../../components/charts/bar/BarChart"
import RouteLoader from "../../components/loader/RouteLoader"
import LineChart from "../../components/charts/line/LineChart"
import PieChart from "../../components/charts/pie/PieChart"
import LineBarChart from "../../components/charts/lineBar/LineBarChart"
import HeatMapChart from "../../components/charts/heatmap/HeatMapChart"
import ScatterPlotChart from "../../components/charts/scatterplot/ScatterPlotChart"
import StackedBarChart from "../../components/charts/stackedBar/StackedBarChart"
import ProgressBar from "../../components/charts/progressBar/ProgressBarChart"
import CompareBy from "../../components/CompareBy"
import DashLineChart from "../../components/charts/dashLine/DashLineChart"
import HorizontalBarChart from "../../components/charts/bar/horizontalBarGraph/HorizontalBarChart"
import Table from "../../components/datagrid/Table"
import DatePicker from "../../components/DatePicker"
import RouteTitle from "../../components/RouteTitle"
import CustomPaper from "../../components/CustomPaper"
import "./styles.scss"
import { CustomerBarChartParameters } from "../marketingportal/CustomerBarChartParameters"
import {
  PopularProductList,
  PopularProductParameters,
} from "../../constants/chartsData"
import CalendarChart from "../../components/charts/calendar/CalendarChart"
import { useTheme } from "../../contexts/theme"
import GroupedBarChart from "../../components/charts/GroupBar/GroupedBarchart.jsx"
import FunnelChart from "../../components/charts/funnel/FunnelChart.jsx"
import RadialBarChart from "../../components/charts/radialBar/RadialBarChart.jsx"
import SwarmPlot from "../../components/charts/swarmPlot/SwarmPlot.jsx"

// import CalendarChart from "../../components/charts/calendar/CalendarChart"
// import SunburstChart from "../../components/charts/sunburst/SunburstChart"

const Inventory = () => {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(true)
  const inventoryData = useSelector((state) => state.inventory)
  const dateRangeData = useSelector((state) => state.calendar)
  const comparisonPeriod = useSelector((state) => state.period.selectedValue)
  const { theme } = useTheme()
  const ThemeMode = theme.palette.mode
  const defaultColor = { type: "diverging", scheme: "oranges", divergeAt: 0.75 }
  const defaultGroupBarLegendLabel = {
    axisLeft: "Sales - Quantity",
    axisBottom: "Day's",
  }

  const fetchData = async () => {
    try {
      setIsLoading(true)
      const response = await getData("inventory", {})
      dispatch(loadData(response))
    } catch (error) {
      console.error("Error fetching data:", error.message)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (inventoryData?.reports?.length === 0) {
      fetchData()
    } else {
      setIsLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inventoryData?.reports?.length])

  const countReports = inventoryData?.reports?.filter(
    (report) =>
      report.reportTypes.some((type) => type.ReportType === "Count") &&
      report.reportName !== "Total Products"
  )

  const TotalProductsReport = inventoryData?.reports?.find(
    (report) =>
      report.reportName === "Total Products" &&
      report.reportTypes.some((type) => type.ReportType === "Count")
  )

  const otherReports = inventoryData?.reports
    ?.filter(
      (report) =>
        report.reportName != "Product Profitability Report" &&
        !report.reportTypes.some(
          (type) =>
            type.ReportType === "Count" || type.ReportType === "Calendar"
        )
    )
    .sort((a, b) => a.reportOrder - b.reportOrder)

  const calendarReports = inventoryData?.reports?.filter((report) => {
    return report.reportTypes.some((type) => type.ReportType === "Calendar")
  })

  const fullscreenReport = inventoryData?.reports?.filter((report) =>
    ["Product Profitability Report"].includes(report.reportName)
  )

  const groupedBarReports = inventoryData?.reports?.filter((report) => {
    return report.reportTypes.some((type) => type.ReportType === "GroupedBar")
  })

  const groupedReports = inventoryData?.reports?.filter((report) =>
    [
      "Top Departments by Product Sales Quantity",
      "Most Popular Brands",
    ].includes(report.reportName)
  )

  return (
    <>
      {isLoading ? (
        <RouteLoader />
      ) : (
        <>
          <RouteTitle>
            <Stack sx={{ maxWidth: { md: "1250px" } }} gap="3px">
              <Typography
                variant="subtitle1"
                color="inherit"
                fontWeight="fontWeightMedium"
              >
                {`Good to see you again, ${localStorage.getItem("fname")}!`}
              </Typography>
              <Typography
                variant="body2"
                color="inherit"
                fontWeight="fontWeightLight"
                sx={{ fontSize: "0.8rem", opacity: 0.9 }}
              >
                Name: Demo Restaurants &nbsp;
                <span style={{ opacity: 0.6 }}>|</span> Address: 1000 Main st,
                Houston, TX&nbsp;
                <span style={{ opacity: 0.6 }}>|</span> Phone: +1 111 111
                1111&nbsp;
                <span style={{ opacity: 0.6 }}>|</span> Location: Houston&nbsp;
                <span style={{ opacity: 0.6 }}>|</span> Site: Houston
              </Typography>
            </Stack>
            <Box
              display="flex"
              alignItems="flex-start"
              justifyContent="space-between"
              maxWidth="auto"
              gap={2}
            >
              <CompareBy dateRangeData={dateRangeData} />
              <DatePicker />
            </Box>
          </RouteTitle>

          <Box padding="5px 20px 15px 20px">
            <Masonry
              columns={{ xs: 1, sm: 1, md: 2 }}
              spacing={2}
              id="masonry"
              sx={{ margin: "0px" }}
            >
              <CustomPaper
                customStyles={{
                  backgroundColor: ThemeMode === "dark" ? "#030816" : "#E9EDFC",
                }}
                watermarkBg={true}
                CustomDrawerComponent={true}
              >
                {TotalProductsReport && (
                  <Grid
                    key={TotalProductsReport.report_uid}
                    sx={{ width: "12rem", pb: 1 }}
                  >
                    <KPICard
                      title={TotalProductsReport.reportName}
                      parameters={
                        TotalProductsReport.reportTypes[0].reportParameterTypes
                      }
                      reportUid={TotalProductsReport.report_uid}
                      dateRangeData={dateRangeData}
                      description={TotalProductsReport.reportDescription}
                      postUrl="sales/data"
                      comparisonPeriod={comparisonPeriod}
                      showPrevious={false}
                    />
                  </Grid>
                )}
                {countReports && (
                  <section className="inventory_grid_container">
                    {countReports?.map((report, index) => (
                      <CustomPaper
                        key={report.report_uid}
                        className={
                          index > 8
                            ? "inventory_grid_item_other"
                            : `inventory_grid_item_${index + 1}`
                        }
                        watermarkBg={true}
                        CustomDrawerComponent={true}
                      >
                        <KPICard
                          key={report.report_uid}
                          title={report.reportName}
                          parameters={
                            report.reportTypes[0].reportParameterTypes
                          }
                          dateRangeData={dateRangeData}
                          description={report.reportDescription}
                          postUrl="inventory/data"
                          reportUid={report.report_uid}
                          width="100%"
                          height="6rem"
                          comparisonPeriod={comparisonPeriod}
                        />
                      </CustomPaper>
                    ))}
                  </section>
                )}
              </CustomPaper>

              {otherReports?.map(
                (report) =>
                  ({
                    Line: (
                      <CustomPaper
                        key={report.report_uid}
                        customStyles={{ overflow: "visible" }}
                      >
                        <LineChart
                          key={report.report_uid}
                          route="inventory"
                          setChartData={setChartData}
                          title={report.reportName}
                          description={report.reportDescription}
                          postUrl="inventory/data"
                          reportUid={report.report_uid}
                          parameters={
                            report.reportTypes[0].reportParameterTypes
                          }
                          dateRangeData={dateRangeData}
                          comparisonPeriod={comparisonPeriod}
                        />
                      </CustomPaper>
                    ),
                    Pie: (
                      <CustomPaper
                        key={report.report_uid}
                        customStyles={{ overflow: "visible" }}
                      >
                        <PieChart
                          key={report.report_uid}
                          route="inventory"
                          title={report.reportName}
                          comparisonPeriod={comparisonPeriod}
                          setChartData={setChartData}
                          description={report.reportDescription}
                          postUrl="inventory/data"
                          reportUid={report.report_uid}
                          parameters={
                            report.reportTypes[0].reportParameterTypes
                          }
                          dateRangeData={dateRangeData}
                        />
                      </CustomPaper>
                    ),
                    List: (
                      <Table
                        key={report.report_uid}
                        route="inventory"
                        setChartData={setChartData}
                        title={report.reportName}
                        showExportButton={false}
                        hidePageNumbers={true}
                        dateRangeData={dateRangeData}
                        reportUid={report.report_uid}
                        postUrl="inventory/list"
                        parameters={report.reportTypes[0].reportParameterTypes}
                        onRowClick={(params) =>
                          console.log(
                            `Inventory Route > ${report.reportName}, Row clicked`,
                            params
                          )
                        }
                      />
                    ),
                    Bar: (
                      <CustomPaper
                        key={report.report_uid}
                        customStyles={{ overflow: "visible" }}
                      >
                        <BarChart
                          key={report.report_uid}
                          route="inventory"
                          showLegend={true}
                          title={report.reportName}
                          description={report.reportDescription}
                          setChartData={setChartData}
                          comparisonPeriod={comparisonPeriod}
                          postUrl="inventory/data"
                          reportUid={report.report_uid}
                          parameters={
                            report.reportTypes[0].reportParameterTypes
                          }
                          dateRangeData={dateRangeData}
                          colorFlag="single"
                        />
                      </CustomPaper>
                    ),
                    LineBar: (
                      <CustomPaper
                        key={report.report_uid}
                        customStyles={{ overflow: "visible" }}
                        // CustomDrawerComponent={
                        //   report.reportTypes[0].ReportType !== "List"
                        // }
                      >
                        <LineBarChart
                          key={report.report_uid}
                          route="inventory"
                          setChartData={setChartData}
                          title={report.reportName}
                          description={report.reportDescription}
                          postUrl="inventory/data"
                          reportUid={report.report_uid}
                          parameters={
                            report.reportTypes[0].reportParameterTypes
                          }
                          dateRangeData={dateRangeData}
                          comparisonPeriod={comparisonPeriod}
                        />
                      </CustomPaper>
                    ),
                    Heatmap: (
                      <CustomPaper
                        key={report.report_uid}
                        customStyles={{ overflow: "visible" }}
                      >
                        <HeatMapChart
                          key={report.report_uid}
                          route="inventory"
                          setChartData={setChartData}
                          title={report.reportName}
                          description={report.reportDescription}
                          postUrl="inventory/data"
                          reportUid={report.report_uid}
                          parameters={
                            report.reportTypes[0].reportParameterTypes
                          }
                          comparisonPeriod={comparisonPeriod}
                          dateRangeData={dateRangeData}
                          defaultColor={defaultColor}
                        />
                      </CustomPaper>
                    ),
                    ProgressBar: (
                      <CustomPaper
                        key={report.report_uid}
                        customStyles={{ overflow: "visible" }}
                      >
                        <ProgressBar
                          key={report.report_uid}
                          route="inventory"
                          setChartData={setChartData}
                          title={report.reportName}
                          description={report.reportDescription}
                          postUrl="inventory/data"
                          reportUid={report.report_uid}
                          parameters={
                            report.reportTypes[0].reportParameterTypes
                          }
                          dateRangeData={dateRangeData}
                          comparisonPeriod={comparisonPeriod}
                        />
                      </CustomPaper>
                    ),
                    ScatterPlot: (
                      <ScatterPlotChart
                        key={report.report_uid}
                        route="inventory"
                        setChartData={setChartData}
                        title={report.reportName}
                        description={report.reportDescription}
                        postUrl="inventory/data"
                        reportUid={report.report_uid}
                        parameters={report.reportTypes[0].reportParameterTypes}
                        dateRangeData={dateRangeData}
                        comparisonPeriod={comparisonPeriod}
                      />
                    ),
                    StackedBar: (
                      <StackedBarChart
                        key={report.report_uid}
                        route="inventory"
                        setChartData={setChartData}
                        title={report.reportName}
                        description={report.reportDescription}
                        postUrl="inventory/data"
                        reportUid={report.report_uid}
                        parameters={report.reportTypes[0].reportParameterTypes}
                        dateRangeData={dateRangeData}
                        comparisonPeriod={comparisonPeriod}
                      />
                    ),
                    DualHorizontalBar: (
                      <CustomPaper
                        key={report.report_uid}
                        customStyles={{ overflow: "visible" }}
                      >
                        <HorizontalBarChart
                          route="inventory"
                          setChartData={setChartData}
                          title={report.reportName}
                          description={report.reportDescription}
                          postUrl="inventory/data"
                          reportUid={report.report_uid}
                          parameters={
                            report.reportTypes[0].reportParameterTypes
                          }
                          dateRangeData={dateRangeData}
                          comparisonPeriod={comparisonPeriod}
                        />
                      </CustomPaper>
                    ),
                    DashLine: (
                      <CustomPaper
                        key={report.report_uid}
                        customStyles={{ overflow: "visible" }}
                      >
                        <DashLineChart
                          route="inventory"
                          setChartData={setChartData}
                          title={report.reportName}
                          description={report.reportDescription}
                          postUrl="inventory/data"
                          reportUid={report.report_uid}
                          parameters={
                            report.reportTypes[0].reportParameterTypes
                          }
                          dateRangeData={dateRangeData}
                          comparisonPeriod={comparisonPeriod}
                        />
                      </CustomPaper>
                    ),
                    Funnel: (
                      <CustomPaper
                        key={report.report_uid}
                        customStyles={{ overflow: "visible" }}
                      >
                        <FunnelChart
                          route="inventory"
                          setChartData={setChartData}
                          title={report.reportName}
                          description={report.reportDescription}
                          postUrl="inventory/data"
                          reportUid={report.report_uid}
                          parameters={
                            report.reportTypes[0].reportParameterTypes
                          }
                          dateRangeData={dateRangeData}
                          comparisonPeriod={comparisonPeriod}
                        />
                      </CustomPaper>
                    ),
                    RadialBar: (
                      <CustomPaper
                        key={report.report_uid}
                        customStyles={{ overflow: "visible" }}
                      >
                        <RadialBarChart
                          route="inventory"
                          setChartData={setChartData}
                          title={report.reportName}
                          description={report.reportDescription}
                          postUrl="inventory/data"
                          reportUid={report.report_uid}
                          parameters={
                            report.reportTypes[0].reportParameterTypes
                          }
                          dateRangeData={dateRangeData}
                          comparisonPeriod={comparisonPeriod}
                        />
                      </CustomPaper>
                    ),
                    SwarmPlot: (
                      <CustomPaper
                        key={report.report_uid}
                        customStyles={{ overflow: "visible" }}
                      >
                        <SwarmPlot
                          route="inventory"
                          setChartData={setChartData}
                          title={report.reportName}
                          description={report.reportDescription}
                          postUrl="inventory/data"
                          reportUid={report.report_uid}
                          parameters={
                            report.reportTypes[0].reportParameterTypes
                          }
                          dateRangeData={dateRangeData}
                          comparisonPeriod={comparisonPeriod}
                        />
                      </CustomPaper>
                    ),
                  }[report.reportTypes[0].ReportType])
              )}
              {/* 
              <Box>
                <Grid container sx={{ height: "100%" }} spacing={2}>
                  {groupedReports?.map((report) => (
                    <Grid item xs={12} sm={12} md={12} lg={6}>
                      <CustomPaper
                        key={report.report_uid}
                        customStyles={{ overflow: "visible" }}
                      >
                        <ProgressBar
                          key={report.report_uid}
                          route="inventory"
                          setChartData={setChartData}
                          title={report.reportName}
                          description={report.reportDescription}
                          postUrl="inventory/data"
                          reportUid={report.report_uid}
                          parameters={
                            report.reportTypes[0].reportParameterTypes
                          }
                          showPagination={true}
                          dateRangeData={dateRangeData}
                          comparisonPeriod={comparisonPeriod}
                        />
                      </CustomPaper>
                    </Grid>
                  ))}
                </Grid>
              </Box> */}

              {/* <CustomPaper key={1} customStyles={{ overflow: "visible" }}>
                <BarChart
                  route="inventory"
                  showLegend={true}
                  setChartData={setChartData}
                  comparisonPeriod={comparisonPeriod}
                  title={"Most Wasted Products"}
                  description={"Most Wasted Products"}
                  postUrl="inventory/data"
                  reportUid={"static-barchart"}
                  parameters={CustomerBarChartParameters}
                />
              </CustomPaper> */}

              {/* <Table
                route="inventory"
                setChartData={setChartData}
                title="Most Popular Product by Sales Qnt"
                showExportButton={false}
                hidePageNumbers={true}
                dateRangeData={dateRangeData}
                reportUid={"static-List"}
                postUrl="inventory/list"
                StaticData={PopularProductList}
                StaticParameters={PopularProductParameters}
              /> */}

              {/* <CustomPaper>
                <ProgressBar
                  route="inventory"
                  title="Most Popular Products"
                  description="Most Popular Products"
                  setChartData={setChartData}
                  postUrl="inventory/data"
                  dateRangeData={dateRangeData}
                  comparisonPeriod={comparisonPeriod}
                  showPagination={true}
                />
              </CustomPaper>
              <CustomPaper>
                <ProgressBar
                  route="inventory"
                  title="Most Popular Departments"
                  description="Most Popular Departments"
                  setChartData={setChartData}
                  postUrl="inventory/data"
                  dateRangeData={dateRangeData}
                  comparisonPeriod={comparisonPeriod}
                  showPagination={true}
                />
              </CustomPaper>
              <CustomPaper customStyles={{ overflow: "visible" }}>
                <CalendarChart
                  route="inventory"
                  title="Calendar"
                  description="Top Calendar Items"
                  setChartData={setChartData}
                  postUrl="inventory/data"
                  dateRangeData={dateRangeData}
                  comparisonPeriod={comparisonPeriod}
                />
              </CustomPaper>
              <CustomPaper customStyles={{ overflow: "visible" }}>
                <SunburstChart
                  route="inventory"
                  title="Wastage Items"
                  description="Top Wastage Items"
                  setChartData={setChartData}
                  postUrl="inventory/data"
                  dateRangeData={dateRangeData}
                  comparisonPeriod={comparisonPeriod}
                />
              </CustomPaper> */}
            </Masonry>

            {groupedBarReports?.map(
              (report) =>
                ({
                  GroupedBar: (
                    <CustomPaper
                      key={report.report_uid}
                      customStyles={{
                        overflow: "visible",
                        margin: "10px",
                      }}
                      innerCustomStyles={{ margin: "unset" }}
                    >
                      <GroupedBarChart
                        route="inventory"
                        title={report.reportName}
                        description={report.reportDescription}
                        setChartData={setChartData}
                        comparisonPeriod={comparisonPeriod}
                        postUrl="inventory/data"
                        dateRangeData={dateRangeData}
                        reportUid={report.report_uid}
                        parameters={report.reportTypes[0].reportParameterTypes}
                        defaultGroupBarLegendLabel={defaultGroupBarLegendLabel}
                        showLegend={true}
                      />
                    </CustomPaper>
                  ),
                }[report.reportTypes[0].ReportType])
            )}

            {calendarReports?.map(
              (report) =>
                ({
                  Calendar: (
                    <CustomPaper
                      key={report.report_uid}
                      customStyles={{ overflow: "visible", margin: "10px" }}
                    >
                      <CalendarChart
                        route="inventory"
                        setChartData={setChartData}
                        title={report.reportName}
                        description={report.reportDescription}
                        comparisonPeriod={comparisonPeriod}
                        postUrl="inventory/data"
                        reportUid={report.report_uid}
                        parameters={report.reportTypes[0].reportParameterTypes}
                        dateRangeData={dateRangeData}
                      />
                    </CustomPaper>
                  ),
                }[report.reportTypes[0].ReportType])
            )}

            {fullscreenReport?.map(
              (report) =>
                ({
                  Bar: (
                    <CustomPaper
                      key={report.report_uid}
                      customStyles={{ overflow: "visible" }}
                    >
                      <BarChart
                        key={report.report_uid}
                        route="inventory"
                        showLegend={true}
                        title={report.reportName}
                        description={report.reportDescription}
                        setChartData={setChartData}
                        comparisonPeriod={comparisonPeriod}
                        postUrl="inventory/data"
                        reportUid={report.report_uid}
                        parameters={report.reportTypes[0].reportParameterTypes}
                        dateRangeData={dateRangeData}
                        colorFlag="single"
                      />
                    </CustomPaper>
                  ),
                }[report.reportTypes[0].ReportType])
            )}
          </Box>
        </>
      )}
    </>
  )
}

export default Inventory
