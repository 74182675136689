import { useState, useEffect } from "react"
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import { useSelector, useDispatch } from "react-redux"
import { postData } from "../../../api/api"
import { useTheme } from "../../../contexts/theme"
import InfoLogoSvg from "../../../assets/svgs/InfoLogoSvg"
import CircularProgress from "@mui/material/CircularProgress"
import CustomLegendTable from "./CustomLegendTable"
import CustomLegend from "../../CustomLegend"
import NivoPieChart from "./NivoPieChart"
import { PieChartData } from "../../../constants/chartsData"
import InfoToolTip from "../../InfoToolTip"
import extractKeyValuePairs from "../../../utils/extractKeyValuePairs"
import ViewBy from "../../ViewBy"
import Analytica from "../../Analytica"

const PieChart = ({
  route,
  setChartData,
  title,
  description,
  postUrl,
  comparisonPeriod,
  reportUid,
  parameters,
  dateRangeData = {
    fromDate: "",
    toDate: "",
  },
  focus_mode = true,
  showAiDropdown = true,
}) => {
  const extractedParameters = extractKeyValuePairs(parameters)
  const [viewBy, setViewBy] = useState("Day")
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [apiResponse, setApiResponse] = useState([])
  const dispatch = useDispatch()
  const { theme } = useTheme()
  const [reportData, setReportData] = useState([])
  const [combinedState, setCombinedState] = useState({
    comparisonPeriod: comparisonPeriod,
    dateRangeData: dateRangeData,
  })
  useEffect(() => {
    setCombinedState({
      comparisonPeriod,
      dateRangeData,
    })
  }, [comparisonPeriod, dateRangeData])
  const tooltip_data = extractedParameters.tooltip_data || [
    {
      name: "Gross Sales",
      format: "Currency",
      color: "",
    },
    {
      name: "Customers",
      format: "Number",
      color: "",
    },
    {
      name: "Transactions",
      format: "Number",
      color: "",
    },
    {
      name: "Average Ticket",
      format: "Currency",
      color: "",
    },
  ]

  const showPrevious =
    extractedParameters.showPrevious === undefined
      ? true
      : extractedParameters.showPrevious

  const parseNumber = (value) => {
    try {
      return typeof value === "number"
        ? value
        : parseFloat(value.replace(/[^0-9.]/g, ""))
    } catch (error) {
      return undefined
    }
  }

  function transformData(input) {
    // TODO: Combine compared by case here itself
    if (!input || !input.reportDataCurrent || !input.reportDataPrevious) {
      console.error("Invalid input data structure:", input)
      return []
    }

    let data = []

    input.reportDataCurrent?.forEach((currentItem) => {
      const previousItem =
        input?.reportDataPrevious?.find((p) => p.id === currentItem.id) || {}

      let eachElement = {
        id: currentItem.id,
        value: currentItem.value,
        tooltipData: {
          current: {},
          previous: {},
        },
      }

      tooltip_data?.forEach((tooltipDataItem) => {
        eachElement.tooltipData.current[tooltipDataItem.name] = {
          value: parseNumber(currentItem[tooltipDataItem.name]),
          format: tooltipDataItem.format,
        }

        eachElement.tooltipData.previous[tooltipDataItem.name] = {
          value: parseNumber(previousItem[tooltipDataItem.name]),
          format: tooltipDataItem.format,
        }
      })

      data.push(eachElement)
    })

    return data
  }

  function transformDataComparedBy(input) {
    if (!input) {
      console.error("Invalid input data structure:", input)
      return []
    }

    let data = []

    input?.forEach((currentItem) => {
      let eachElement = {
        id: currentItem.id,
        value: currentItem.value,
        tooltipData: {
          current: {},
        },
      }

      tooltip_data?.forEach((tooltipDataItem) => {
        eachElement.tooltipData.current[tooltipDataItem.name] = {
          value: parseNumber(currentItem[tooltipDataItem.name]),
          format: tooltipDataItem.format,
        }
      })

      data.push(eachElement)
    })

    return data
  }

  const colorScheme = [
    "#2271B4",
    "#6E8B3D",
    "#C75D8A",
    "#DB7093",
    "#FF8C00",
    "#9370DB",
    "#FFE4B5",
    "#BA55D3",
    "#B0E0E6",
    "#8B4513",
    "#00CED1",
    "#CD5C5C",
    "#3CB371",
    "#A7CEFA",
    "#BDB76B",
    "#D2691E",
    "#00FA9A",
    "#F4A460",
    "#FF69B4",
    "#8A2BE2",
    "#2E8B57",
  ]

  const fetchData = async () => {
    try {
      let response

      if (reportUid === "static-PieChart") {
        response = false
      } else {
        response = await postData(postUrl, {
          entityDb: localStorage.getItem("entityDb"),
          fromDate: combinedState.dateRangeData.fromDate,
          toDate: combinedState.dateRangeData.toDate,
          reportInstanceId: reportUid,
          comparedBy: {
            previousFromDate: combinedState.comparisonPeriod.previousFromDate,
            previousToDate: combinedState.comparisonPeriod.previousToDate,
          },
          // viewBy: viewBy || "Day",
        })
      }

      setReportData(response)
      setApiResponse(response)
      if (!!response === false || Object.keys(response).length === 0) {
        setError("Inadequate data points to generate a report")
        setApiResponse(PieChartData)
        dispatch(
          setChartData({
            reportUid,
            response: PieChartData,
          })
        )
      } else {
        const result =
          comparisonPeriod?.selectedValue === "Compared By"
            ? transformDataComparedBy(response)
            : transformData(response)
        dispatch(
          setChartData({
            reportUid,
            response: result,
          })
        )
      }
    } catch (error) {
      setError("Inadequate data points to generate a report")
      setApiResponse(PieChartData)
      dispatch(
        setChartData({
          reportUid,
          response: PieChartData,
        })
      )
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    setLoading(true)
    dispatch(
      setChartData({
        reportUid,
        viewBy: viewBy || "Day",
      })
    )

    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    viewBy,
    combinedState.comparisonPeriod.previousFromDate,
    combinedState.comparisonPeriod.previousToDate,
    combinedState.dateRangeData.fromDate,
    combinedState.dateRangeData.toDate,
  ])

  const response = useSelector((state) => {
    const selectedReport = state[route]?.reports?.find(
      (report) => report.report_uid === reportUid
    )

    if (selectedReport) return selectedReport?.reportData
  })

  const svgColor = theme.palette.accentSecondary

  const legendsData =
    response && Array.isArray(response)
      ? [...response].map((item, idx) => ({
          label: item?.id,
          color: colorScheme[idx],
        }))
      : []

  return (
    <>
      <Box
        display="flex"
        alignItems="flex-start"
        justifyContent="space-between"
        flexWrap={"wrap"}
        gap={"10px"}
      >
        <Box display="flex" alignItems="center">
          <Typography color="inherit">{title}</Typography>
          <InfoToolTip title={description}>
            <span style={{ height: "16px", cursor: "pointer" }}>
              <InfoLogoSvg svgColor={svgColor} />
            </span>
          </InfoToolTip>
        </Box>
        <Box
          display="flex"
          alignItems="flex-start"
          justifyContent="space-between"
          gap={"2px"}
        >
          {extractedParameters.view_by_period && (
            <ViewBy
              route={route}
              parameters={
                extractedParameters && extractedParameters.view_by_period
              }
              viewBy={viewBy}
              setViewBy={setViewBy}
              reportUid={reportUid}
              dateRangeData={dateRangeData}
            />
          )}
          <Box display="flex">
            {extractedParameters.focus_mode && focus_mode && (
              <Analytica
                description={description}
                component="modal"
                reportType="Pie"
                chartDetails={{
                  route,
                  setChartData,
                  title,
                  description,
                  comparisonPeriod,
                  postUrl,
                  reportUid,
                  parameters,
                  dateRangeData,
                  apiResponse,
                }}
              />
            )}
          </Box>
        </Box>
      </Box>

      <Box
        display="flex"
        justifyContent="space-between"
        alignContent="center"
        alignItems="center"
        flexWrap="wrap"
        flexDirection="row"
        rowGap={1}
      >
        {loading ? (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            alignContent="center"
            gap="10px"
            minHeight="350px"
            width="100%"
          >
            <CircularProgress size={45} color="info" />
            <Typography variant="body2" color="inherit">
              Fetching data, please wait...
            </Typography>
          </Box>
        ) : error ? (
          <>
            {response && (
              <NivoPieChart
                chartConfig={extractedParameters}
                chartInput={response}
                colorScheme={colorScheme}
              />
            )}
            <Box
              display="flex"
              flexDirection="column"
              rowGap={{ md: "10px", lg: "5px" }}
              width="100%"
              flex={1}
            >
              <CustomLegendTable
                data={reportData}
                chartConfig={extractedParameters}
                colorScheme={colorScheme}
                title={title}
                showPrevious={showPrevious}
              />
            </Box>
          </>
        ) : (
          <>
            {response && (
              <NivoPieChart
                chartConfig={extractedParameters}
                chartInput={response}
                colorScheme={colorScheme}
              />
            )}
            <Box
              display="flex"
              flexDirection="column"
              rowGap={{ md: "10px", lg: "5px" }}
              width="100%"
              flex={1}
            >
              <CustomLegendTable
                data={reportData}
                chartConfig={extractedParameters}
                colorScheme={colorScheme}
                title={title}
                showPrevious={showPrevious}
              />
            </Box>
            <Box width="100%">
              <CustomLegend
                legendsData={legendsData}
                alignment="flex-end"
                reportTitle={title}
                reportData={apiResponse}
                showAiDropdown={showAiDropdown}
              />
            </Box>
          </>
        )}
      </Box>
    </>
  )
}

export default PieChart
