import RouteTitle from "../../components/RouteTitle"
import Box from "@mui/material/Box"
import DatePicker from "../../components/DatePicker"
import CustomPaper from "../../components/CustomPaper"
import { useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { getData, postData } from "../../api/api"
import Stack from "@mui/material/Stack"
import Typography from "@mui/material/Typography"
import Masonry from "@mui/lab/Masonry"
import Grid from "@mui/material/Grid"
import KPICard from "../../components/KPICard"
import "./styles.scss"
import { loadData, setChartData } from "../../redux/slices/customerSlice"
import LineChart from "../../components/charts/line/LineChart"
import Table from "../../components/datagrid/Table"
import PieChart from "../../components/charts/pie/PieChart"
import RouteLoader from "../../components/loader/RouteLoader"
import HeatMapChart from "../../components/charts/heatmap/HeatMapChart"
import BarChart from "../../components/charts/bar/BarChart"
import LineBarChart from "../../components/charts/lineBar/LineBarChart"
import ScatterPlotChart from "../../components/charts/scatterplot/ScatterPlotChart"
import StackedBarChart from "../../components/charts/stackedBar/StackedBarChart"
import ProgressBar from "../../components/charts/progressBar/ProgressBarChart"
import CutomerSatisfaction from "../../components/CutomerSatisfaction"
import { useTheme } from "../../contexts/theme"
import CompareBy from "../../components/CompareBy"
import DashLineChart from "../../components/charts/dashLine/DashLineChart.jsx"
import HorizontalBarChart from "../../components/charts/bar/horizontalBarGraph/HorizontalBarChart.jsx"
import CalendarChart from "../../components/charts/calendar/CalendarChart.jsx"
import GroupedBarChart from "../../components/charts/GroupBar/GroupedBarchart.jsx"
import RadialBarChart from "../../components/charts/radialBar/RadialBarChart.jsx"
import SwarmPlot from "../../components/charts/swarmPlot/SwarmPlot.jsx"

const Customers = () => {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(true)
  const customerData = useSelector((state) => state.customer)
  const dateRangeData = useSelector((state) => state.calendar)
  const { theme } = useTheme()
  const ThemeMode = theme.palette.mode
  const comparisonPeriod = useSelector((state) => state.period.selectedValue)
  const defaultColor = { type: "diverging", scheme: "purples", divergeAt: 0.75 }
  const calenderDefaultColor = [
    "#e0f7ff",
    "#b3eaff",
    "#80dcff",
    "#4dceff",
    "#1abfff",
    "#0099cc",
  ]
  const defaultGroupBarLegendLabel = {
    axisLeft: "Customers - Visits",
    axisBottom: "Day's",
  }

  const fetchData = async () => {
    try {
      setIsLoading(true)
      const response = await getData("customer", {})
      dispatch(loadData(response))
    } catch (error) {
      console.error("Error fetching data:", error.message)
    } finally {
      setIsLoading(false)
    }
  }
  useEffect(() => {
    if (customerData?.reports?.length === 0) {
      fetchData()
    } else {
      setIsLoading(false)
    }
  }, [customerData?.reports?.length])

  const TotalCustomerReport = customerData?.reports?.find(
    (report) =>
      report.reportName === "Total Customers" &&
      report.reportTypes.some((type) => type.ReportType === "Count")
  )

  const countReports = customerData?.reports?.filter((report) => {
    return (
      report.reportTypes.some(
        (type) =>
          type.ReportType === "Count" && report.reportName !== "Total Customers"
      ) && report.reportName !== "Total Customers"
    )
  })

  const otherReports = customerData?.reports
    ?.filter(
      (report) =>
        !report.reportTypes.some(
          (type) =>
            type.ReportType === "Count" || type.ReportType === "Calendar"
        ) && report.reportName !== "Customer Summary"
    )
    .sort((a, b) => a.reportOrder - b.reportOrder)

  const calendarReports = customerData?.reports?.filter((report) => {
    return report.reportTypes.some((type) => type.ReportType === "Calendar")
  })

  const groupedReports = customerData?.reports?.filter((report) => {
    return report.reportTypes.some((type) => type.ReportType === "GroupedBar")
  })

  const uniqueCustomersReport = customerData?.reports?.filter(
    (report) => report.reportName === "Customer Summary"
  )?.[0]

  const [totalCustomers, setTotalCustomers] = useState(null)

  useEffect(() => {
    const fetchTotalCustomers = async () => {
      try {
        const totalCustomersReport =
          customerData?.reports?.filter((report) => {
            return (
              report.reportTypes.some((type) => type.ReportType === "Count") &&
              report.reportName === "Total Customers"
            )
          })?.[0] || null

        if (!totalCustomersReport) return

        const customerCount = await postData("customer/data", {
          entityDb: localStorage.getItem("entityDb"),
          comparedBy: {
            previousFromDate: comparisonPeriod?.previousFromDate,
            previousToDate: comparisonPeriod?.previousToDate,
          },
          fromDate: dateRangeData.fromDate,
          toDate: dateRangeData.toDate,
          reportInstanceId: totalCustomersReport?.report_uid,
        })

        setTotalCustomers(customerCount?.reportDataCurrent[0]?.value)
      } catch (error) {
        console.error(error)
      }
    }

    if (comparisonPeriod.previousToDate !== 1) {
      fetchTotalCustomers()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dateRangeData.fromDate,
    dateRangeData.toDate,
    customerData?.reports?.length,
  ])

  return (
    <Box position="relative">
      {isLoading ? (
        <RouteLoader />
      ) : (
        <>
          <>
            <RouteTitle>
              <Stack sx={{ maxWidth: { md: "1250px" } }} gap="3px">
                <Typography
                  variant="subtitle1"
                  color="inherit"
                  fontWeight="fontWeightMedium"
                >
                  {`Good to see you again, ${localStorage.getItem("fname")}!`}
                </Typography>
                <Typography
                  variant="body2"
                  color="inherit"
                  fontWeight="fontWeightLight"
                  sx={{ fontSize: "0.8rem", opacity: 0.9 }}
                >
                  Name: Demo Restaurants &nbsp;
                  <span style={{ opacity: 0.6 }}>|</span> Address: 1000 Main st,
                  Houston, TX&nbsp;
                  <span style={{ opacity: 0.6 }}>|</span> Phone: +1 111 111
                  1111&nbsp;
                  <span style={{ opacity: 0.6 }}>|</span> Location:
                  Houston&nbsp;
                  <span style={{ opacity: 0.6 }}>|</span> Site: Houston
                </Typography>
              </Stack>
              <Box
                display="flex"
                alignItems="flex-start"
                justifyContent="space-between"
                maxWidth="auto"
                gap={2}
              >
                <CompareBy dateRangeData={dateRangeData} />
                <DatePicker />
              </Box>
            </RouteTitle>

            <Box padding="5px 20px 15px 20px">
              <Grid
                container
                justifyContent="space-between"
                p={1}
                spacing={{ sm: 2, lg: 0.5, xl: 0 }}
              >
                <CustomPaper
                  customStyles={{
                    width: "100%",
                    backgroundColor:
                      ThemeMode === "dark" ? "#030816" : "#E9EDFC",
                  }}
                  watermarkBg={true}
                >
                  {TotalCustomerReport && (
                    <Grid
                      key={TotalCustomerReport.report_uid}
                      sx={{ width: "12rem", pb: 1 }}
                    >
                      <KPICard
                        title={TotalCustomerReport.reportName}
                        parameters={
                          TotalCustomerReport.reportTypes[0]
                            .reportParameterTypes
                        }
                        reportUid={TotalCustomerReport.report_uid}
                        dateRangeData={dateRangeData}
                        description={TotalCustomerReport.reportDescription}
                        postUrl="sales/data"
                        comparisonPeriod={comparisonPeriod}
                        showPrevious={false}
                      />
                    </Grid>
                  )}
                  <Grid container className="customer_grid_container">
                    {countReports?.map((report, index) => (
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        lg={1.75}
                        key={report.report_uid}
                        className={`customer_grid_item_${index + 1}`}
                      >
                        <CustomPaper
                          key={report.report_uid}
                          customStyles={{ height: "100%" }}
                          watermarkBg={true}
                        >
                          <KPICard
                            title={report.reportName}
                            parameters={
                              report.reportTypes[0].reportParameterTypes
                            }
                            reportUid={report.report_uid}
                            dateRangeData={dateRangeData}
                            description={report.reportDescription}
                            postUrl="customer/data"
                            width="100%"
                            height="6rem"
                            comparisonPeriod={comparisonPeriod}
                          />
                        </CustomPaper>
                      </Grid>
                    ))}
                    <Grid item xs={12} sm={12} md={8} lg={4.25}>
                      <CutomerSatisfaction />
                    </Grid>
                  </Grid>
                </CustomPaper>
              </Grid>

              {uniqueCustomersReport && (
                <CustomPaper
                  key={uniqueCustomersReport.report_uid}
                  customStyles={{ overflow: "visible", margin: 1 }}
                  innerCustomStyles={{ margin: 0 }}
                >
                  <LineBarChart
                    key={uniqueCustomersReport.report_uid}
                    route="customer"
                    setChartData={setChartData}
                    title={uniqueCustomersReport.reportName}
                    description={uniqueCustomersReport.reportDescription}
                    postUrl="customer/data"
                    reportUid={uniqueCustomersReport.report_uid}
                    parameters={
                      uniqueCustomersReport.reportTypes[0].reportParameterTypes
                    }
                    dateRangeData={dateRangeData}
                    comparisonPeriod={comparisonPeriod}
                  />
                </CustomPaper>
              )}

              <Masonry
                columns={{ xs: 1, sm: 1, md: 2 }}
                spacing={2}
                id="masonry"
                sx={{ margin: "0px" }}
              >
                {/* <section className="customer_grid_container">
                {countReports?.map((report, index) => (
                  <CustomPaper
                    key={report.report_uid}
                    className={
                      index > 5
                        ? "customer_grid_item_other"
                        : `customer_grid_item_${index + 1}`
                    }
                    watermarkBg={true}
                    CustomDrawerComponent={true}
                  >
                    <KPICard
                      key={report.report_uid}
                      title={report.reportName}
                      parameters={report.reportTypes[0].reportParameterTypes}
                      postUrl="customer/data"
                      dateRangeData={dateRangeData}
                      description={report.reportDescription}
                      reportUid={report.report_uid}
                      width="100%"
                      height="6rem"
                      comparisonPeriod={comparisonPeriod}

                    />
                  </CustomPaper>
                ))}
              </section> */}

                {otherReports?.map(
                  (report) =>
                    ({
                      Line: (
                        <CustomPaper
                          key={report.report_uid}
                          customStyles={{ overflow: "visible" }}
                          CustomDrawerComponent={
                            report.reportTypes[0].ReportType !== "List"
                          }
                        >
                          <LineChart
                            key={report.report_uid}
                            route="customer"
                            setChartData={setChartData}
                            title={report.reportName}
                            description={report.reportDescription}
                            postUrl="customer/data"
                            reportUid={report.report_uid}
                            parameters={
                              report.reportTypes[0].reportParameterTypes
                            }
                            dateRangeData={dateRangeData}
                            comparisonPeriod={comparisonPeriod}
                          />
                        </CustomPaper>
                      ),
                      Pie: (
                        <CustomPaper
                          key={report.report_uid}
                          // CustomDrawerComponent={ true }
                          customStyles={{ overflow: "visible" }}
                          CustomDrawerComponent={
                            report.reportTypes[0].ReportType !== "List"
                          }
                        >
                          <PieChart
                            key={report.report_uid}
                            route="customer"
                            setChartData={setChartData}
                            comparisonPeriod={comparisonPeriod}
                            title={report.reportName}
                            description={report.reportDescription}
                            postUrl="customer/data"
                            reportUid={report.report_uid}
                            parameters={
                              report.reportTypes[0].reportParameterTypes
                            }
                            dateRangeData={dateRangeData}
                          />
                        </CustomPaper>
                      ),
                      List: (
                        <Table
                          key={report.report_uid}
                          route="customer"
                          postUrl="customer/list"
                          reportUid={report.report_uid}
                          parameters={
                            report.reportTypes[0].reportParameterTypes
                          }
                          setChartData={setChartData}
                          title={report.reportName}
                          showExportButton={false}
                          hidePageNumbers={true}
                          dateRangeData={dateRangeData}
                        />
                      ),
                      Bar: (
                        <CustomPaper
                          key={report.report_uid}
                          customStyles={{ overflow: "visible" }}
                        >
                          <BarChart
                            key={report.report_uid}
                            route="customer"
                            title={report.reportName}
                            comparisonPeriod={comparisonPeriod}
                            description={report.reportDescription}
                            postUrl="customer/data"
                            setChartData={setChartData}
                            reportUid={report.report_uid}
                            parameters={
                              report.reportTypes[0].reportParameterTypes
                            }
                            dateRangeData={dateRangeData}
                          />
                        </CustomPaper>
                      ),
                      LineBar: (
                        <CustomPaper
                          key={report.report_uid}
                          customStyles={{ overflow: "visible" }}
                        >
                          <LineBarChart
                            key={report.report_uid}
                            route="customer"
                            setChartData={setChartData}
                            title={report.reportName}
                            description={report.reportDescription}
                            postUrl="customer/data"
                            reportUid={report.report_uid}
                            parameters={
                              report.reportTypes[0].reportParameterTypes
                            }
                            dateRangeData={dateRangeData}
                            comparisonPeriod={comparisonPeriod}
                          />
                        </CustomPaper>
                      ),
                      Heatmap: (
                        <CustomPaper
                          key={report.report_uid}
                          customStyles={{ overflow: "visible" }}
                        >
                          <HeatMapChart
                            key={report.report_uid}
                            route="customer"
                            setChartData={setChartData}
                            title={report.reportName}
                            description={report.reportDescription}
                            postUrl="customer/data"
                            reportUid={report.report_uid}
                            parameters={
                              report.reportTypes[0].reportParameterTypes
                            }
                            dateRangeData={dateRangeData}
                            comparisonPeriod={comparisonPeriod}
                            defaultColor={defaultColor}
                          />
                        </CustomPaper>
                      ),
                      ProgressBar: (
                        <CustomPaper
                          key={report.report_uid}
                          customStyles={{ overflow: "visible" }}
                        >
                          <ProgressBar
                            key={report.report_uid}
                            route="customer"
                            setChartData={setChartData}
                            title={report.reportName}
                            description={report.reportDescription}
                            postUrl="customer/data"
                            reportUid={report.report_uid}
                            parameters={
                              report.reportTypes[0].reportParameterTypes
                            }
                            dateRangeData={dateRangeData}
                            comparisonPeriod={comparisonPeriod}
                            showPagination={false}
                          />
                        </CustomPaper>
                      ),
                      ScatterPlot: (
                        <ScatterPlotChart
                          key={report.report_uid}
                          route="customer"
                          setChartData={setChartData}
                          title={report.reportName}
                          description={report.reportDescription}
                          postUrl="customer/data"
                          reportUid={report.report_uid}
                          parameters={
                            report.reportTypes[0].reportParameterTypes
                          }
                          dateRangeData={dateRangeData}
                          comparisonPeriod={comparisonPeriod}
                        />
                      ),
                      StackedBar: (
                        <StackedBarChart
                          key={report.report_uid}
                          route="customer"
                          setChartData={setChartData}
                          title={report.reportName}
                          description={report.reportDescription}
                          postUrl="customer/data"
                          reportUid={report.report_uid}
                          parameters={
                            report.reportTypes[0].reportParameterTypes
                          }
                          dateRangeData={dateRangeData}
                          comparisonPeriod={comparisonPeriod}
                        />
                      ),
                      DualHorizontalBar: (
                        <CustomPaper
                          key={report.report_uid}
                          customStyles={{ overflow: "visible" }}
                        >
                          <HorizontalBarChart
                            route="customer"
                            setChartData={setChartData}
                            title={report.reportName}
                            description={report.reportDescription}
                            postUrl="customer/data"
                            reportUid={report.report_uid}
                            parameters={
                              report.reportTypes[0].reportParameterTypes
                            }
                            dateRangeData={dateRangeData}
                            comparisonPeriod={comparisonPeriod}
                          />
                        </CustomPaper>
                      ),
                      DashLine: (
                        <CustomPaper
                          key={report.report_uid}
                          customStyles={{ overflow: "visible" }}
                        >
                          <DashLineChart
                            route="customer"
                            setChartData={setChartData}
                            title={report.reportName}
                            description={report.reportDescription}
                            postUrl="customer/data"
                            reportUid={report.report_uid}
                            parameters={
                              report.reportTypes[0].reportParameterTypes
                            }
                            dateRangeData={dateRangeData}
                            comparisonPeriod={comparisonPeriod}
                          />
                        </CustomPaper>
                      ),
                      RadialBar: (
                        <CustomPaper
                          key={report.report_uid}
                          customStyles={{ overflow: "visible" }}
                        >
                          <RadialBarChart
                            route="customer"
                            setChartData={setChartData}
                            title={report.reportName}
                            description={report.reportDescription}
                            postUrl="customer/data"
                            reportUid={report.report_uid}
                            parameters={
                              report.reportTypes[0].reportParameterTypes
                            }
                            dateRangeData={dateRangeData}
                            comparisonPeriod={comparisonPeriod}
                          />
                        </CustomPaper>
                      ),
                      SwarmPlot: (
                        <CustomPaper
                          key={report.report_uid}
                          customStyles={{ overflow: "visible" }}
                        >
                          <SwarmPlot
                            route="customer"
                            setChartData={setChartData}
                            title={report.reportName}
                            description={report.reportDescription}
                            postUrl="customer/data"
                            reportUid={report.report_uid}
                            parameters={
                              report.reportTypes[0].reportParameterTypes
                            }
                            dateRangeData={dateRangeData}
                            comparisonPeriod={comparisonPeriod}
                          />
                        </CustomPaper>
                      ),
                    }[report.reportTypes[0].ReportType])
                )}

                {/* Customer Rating */}
                {/* <CustomPaper>
                <Box display="flex" alignItems="center" mb={1}>
                  <Typography color="inherit">Customer Rating</Typography>
                  <InfoToolTip title="Customer Rating">
                    <span style={{ height: "16px", cursor: "pointer" }}>
                      <InfoLogoSvg svgColor={theme.palette.accentSecondary} />
                    </span>
                  </InfoToolTip>
                </Box>
                <Box
                  height="350px"
                  sx={{
                    position: "relative",
                  }}
                >
                  <Box
                    sx={{
                      height: "100%",
                      width: "25px",
                      position: "absolute",
                      left: "50px",
                      background:
                        "linear-gradient(180deg, #F04747 0%, #F2A73B 54.17%, #23BF6F 99.99%, #FDBF01 100%, #DE4C08 100%)",
                    }}
                  />
                  <Stack height="100%" display="flex">
                    {[
                      { percentage: 6, count: 3232, stars: 1, flex: 0.06 },
                      { percentage: 8, count: 4554, stars: 2, flex: 0.08 },
                      { percentage: 16, count: 434535, stars: 3, flex: 0.16 },
                      { percentage: 34, count: 4243234, stars: 4, flex: 0.34 },
                      { percentage: 42, count: 7643234, stars: 5, flex: 0.42 },
                    ].map((item, index) => (
                      <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-between"
                        alignItems="center"
                        flex={item.flex}
                      >
                        <Typography
                          variant="subtitle1"
                          color="inherit"
                          minWidth="50px"
                          fontWeight="bold"
                          textAlign="end"
                          pr={1}
                        >
                          {item.percentage}%
                        </Typography>
                        <Box
                          display="flex"
                          width="100%"
                          height="100%"
                          justifyContent="space-between"
                          alignItems="center"
                          sx={{
                            borderTop: index === 0 ? "1px solid" : "none",
                            borderBottom: "1px solid",
                            borderColor: theme.palette.divider,
                          }}
                        >
                          <Box width="50px" />
                          <Box display="flex">
                            {Array(item.stars)
                              .fill(0)
                              .map((_, index) => (
                                <StarIcon key={index} color="#F2CD4A" />
                              ))}

                            {Array(5 - item.stars)
                              .fill(0)
                              .map((_, index) => (
                                <StarIcon key={index} color="#F2CD4A50" />
                              ))}
                          </Box>
                          <Typography
                            variant="body1"
                            color="inherit"
                            minWidth="90px"
                            textAlign="end"
                            sx={{
                              opacity: "75%",
                            }}
                          >
                            {`${getFormattedValue(item.count, "Number")}  >`}
                          </Typography>
                        </Box>
                      </Box>
                    ))}
                  </Stack>
                </Box>
              </CustomPaper> */}
              </Masonry>

              {groupedReports?.map(
                (report) =>
                  ({
                    GroupedBar: (
                      <CustomPaper
                        key={report.report_uid}
                        customStyles={{
                          overflow: "visible",
                          margin: "10px",
                        }}
                        innerCustomStyles={{ margin: "unset" }}
                      >
                        <GroupedBarChart
                          route="customer"
                          title={report.reportName}
                          description={report.reportDescription}
                          setChartData={setChartData}
                          comparisonPeriod={comparisonPeriod}
                          postUrl="customer/data"
                          dateRangeData={dateRangeData}
                          reportUid={report.report_uid}
                          defaultGroupBarLegendLabel={
                            defaultGroupBarLegendLabel
                          }
                          parameters={
                            report.reportTypes[0].reportParameterTypes
                          }
                        />
                      </CustomPaper>
                    ),
                  }[report.reportTypes[0].ReportType])
              )}

              {calendarReports?.map(
                (report) =>
                  ({
                    Calendar: (
                      <CustomPaper
                        key={report.report_uid}
                        customStyles={{
                          overflow: "visible",
                          margin: "10px",
                        }}
                      >
                        <CalendarChart
                          route="customer"
                          setChartData={setChartData}
                          title={report.reportName}
                          description={report.reportDescription}
                          comparisonPeriod={comparisonPeriod}
                          postUrl="customer/data"
                          reportUid={report.report_uid}
                          parameters={
                            report.reportTypes[0].reportParameterTypes
                          }
                          dateRangeData={dateRangeData}
                          defaultColor={calenderDefaultColor}
                        />
                      </CustomPaper>
                    ),
                  }[report.reportTypes[0].ReportType])
              )}
            </Box>
          </>
          {/* <Box
            sx={{
              position: "absolute",
              height: "100%",
              width: "100%",
              top: 0,
              zIndex: 6,
            }}
          >
            <Backdrop
              sx={{
                zIndex: (theme) => theme.zIndex.drawer + 1,
                backgroundColor: "#000000B0",
              }}
              open={true}
            >
              <Box
                display="flex"
                alignItems="center"
                flexDirection="column"
                justifyContent="center"
                height="100vh"
              >
                <Typography variant="h5" color="inherit">
                  Coming soon...
                </Typography>
                <Logo
                  width="177"
                  height="80"
                  fill={theme.palette.text.primary}
                  yfill={
                    theme.palette.mode === "light"
                      ? theme.palette.accent
                      : "white"
                  }
                />
              </Box>
            </Backdrop>
          </Box> */}
        </>
      )}
    </Box>
  )
}

export default Customers
