import { useEffect, useState } from "react"
import InfoToolTip from "../../InfoToolTip"
import InfoLogoSvg from "../../../assets/svgs/InfoLogoSvg"
import { useTheme } from "../../../contexts/theme"
import extractKeyValuePairs from "../../../utils/extractKeyValuePairs"
import ViewBy from "../../ViewBy"
import Analytica from "../../Analytica"
import { useDispatch, useSelector } from "react-redux"
import NivoRadialBarChart from "./NivoRadialBarChart"
import { postData } from "../../../api/api"
import Box from "@mui/material/Box"
import CircularProgress from "@mui/material/CircularProgress"
import Typography from "@mui/material/Typography"

const RadialBarChart = ({
  route,
  setChartData,
  title,
  description,
  postUrl,
  comparisonPeriod,
  reportUid,
  parameters,
  dateRangeData = { fromDate: "", toDate: "" },
  focus_mode = true,
  showAiDropdown = true,
}) => {
  const extractedParameters = extractKeyValuePairs(parameters)
  const [viewBy, setViewBy] = useState("Day")
  const { theme } = useTheme()
  const svgColor = theme.palette.accentSecondary

  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [apiResponse, setApiResponse] = useState([])
  const dispatch = useDispatch()
  const [combinedState, setCombinedState] = useState({
    comparisonPeriod: comparisonPeriod,
    dateRangeData: dateRangeData,
  })
  useEffect(() => {
    setCombinedState({
      comparisonPeriod,
      dateRangeData,
    })
  }, [comparisonPeriod, dateRangeData])

  const staticData = [
    {
      id: "Supermarket",
      data: [
        {
          x: "Vegetables",
          y: 285,
          tooltipData: {
            current: { Vegetables: { value: 285, format: "currency" } },
            previous: { Vegetables: { value: 70, format: "currency" } },
          },
        },
        {
          x: "Fruits",
          y: 174,
          tooltipData: {
            current: { Fruits: { value: 174, format: "currency" } },
            previous: { Fruits: { value: 50, format: "currency" } },
          },
        },
        {
          x: "Meat",
          y: 291,
          tooltipData: {
            current: { Meat: { value: 291, format: "currency" } },
            previous: { Meat: { value: 85, format: "currency" } },
          },
        },
        {
          x: "Fish",
          y: 108,
          tooltipData: {
            current: { Fish: { value: 108, format: "currency" } },
            previous: { Fish: { value: 40, format: "currency" } },
          },
        },
      ],
    },
  ]

  const tooltip_data = extractedParameters.tooltip_data || [
    { name: "Gross Sales", format: "Currency", color: "" },
    { name: "Customers", format: "Number", color: "" },
    { name: "Transactions", format: "Number", color: "" },
    { name: "Average Ticket", format: "Currency", color: "" },
  ]

  const indexBy = extractedParameters.indexBy || "id"
  const keys = extractedParameters.keys || "Gross Sales"

  // function transformData(input) {
  //   if (!input?.reportDataCurrent) {
  //     console.error("Invalid input data structure:", input)
  //     return []
  //   }

  //   return input.reportDataCurrent.map((currentItem) => {
  //     return {
  //       id: currentItem[indexBy],
  //       data: Object.entries(currentItem)
  //         .filter(([key]) => key === keys)
  //         .map(([key, value]) => ({ x: key, y: value })),
  //       tooltipData: {
  //         current: {},
  //         previous: {},
  //       },
  //     }
  //   })
  // }
  const showTooltipPrevious =
    extractedParameters.showTooltipPrevious === undefined
      ? true
      : extractedParameters.showTooltipPrevious

  function transformData2(response) {
    // Use reportDataCurrent if it exists, otherwise use response directly as data
    const dataSource = Array.isArray(response?.reportDataCurrent)
      ? response.reportDataCurrent
      : response

    if (!Array.isArray(dataSource)) {
      console.error("The provided data is not an array.")
      return [{ id: "", data: [] }]
    }

    if (!dataSource.length) return [{ id: "", data: [] }]

    const combinedData = []
    const ids = []

    // Iterate over the data source (either reportDataCurrent or response)
    dataSource.forEach((entry, index) => {
      const previous = response.reportDataPrevious?.[index] // Safely get corresponding previous entry
      const keys = Object.keys(entry)

      if (keys.length < 2) {
        console.error("Each entry should have at least two keys.")
        return
      }

      const firstKey = keys[0] // First key for id (e.g., name, date, etc.)
      const secKey = keys[1] // Second key for the y-value (e.g., value)

      ids.push(entry[firstKey]) // Push id to ids array

      // Construct tooltipData for current values
      const tooltipData = {
        current: keys.slice(1).reduce((acc, key) => {
          acc[key] = { value: entry[key], format: "currency" }
          return acc
        }, {}),
      }

      // Conditionally add previous values only if reportDataPrevious exists
      if (response.reportDataPrevious) {
        tooltipData.previous = keys.slice(1).reduce((acc, key) => {
          acc[key] = { value: previous?.[key] || null, format: "currency" }
          return acc
        }, {})
      }

      // Push data entry to combinedData
      combinedData.push({
        x: `${entry[firstKey]}`, // X-axis label
        y: entry[secKey], // Y-axis value
        tooltipData,
      })
    })

    // Return the transformed data structure
    return [
      {
        id: ids.filter(Boolean).join(", "), // Concatenated ids as a string
        data: combinedData,
      },
    ]
  }

  const fetchData = async () => {
    try {
      setLoading(true)
      const response = reportUid
        ? await postData(postUrl, {
            entityDb: localStorage.getItem("entityDb"),
            fromDate: combinedState.dateRangeData.fromDate,
            toDate: combinedState.dateRangeData.toDate,
            reportInstanceId: reportUid,
            comparedBy: {
              previousFromDate: combinedState.comparisonPeriod.previousFromDate,
              previousToDate: combinedState.comparisonPeriod.previousToDate,
            },
          })
        : staticData

      setApiResponse(response)
      dispatch(
        setChartData({
          reportUid,
          response: transformData2(response),
        })
      )
    } catch (error) {
      setError("Inadequate data points to generate a report")
      setApiResponse(staticData)
      dispatch(
        setChartData({
          reportUid,
          staticData,
        })
      )
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    combinedState.comparisonPeriod.previousFromDate,
    combinedState.comparisonPeriod.previousToDate,
    combinedState.dateRangeData.fromDate,
    combinedState.dateRangeData.toDate,
  ])

  const chartInput = useSelector((state) => {
    const selectedReport = state[route]?.reports?.find(
      (report) => report.report_uid === reportUid
    )
    return selectedReport?.reportData
  })

  return (
    <>
      <Box
        display="flex"
        alignItems="flex-start"
        justifyContent="space-between"
      >
        <Box display="flex" alignItems="center">
          <Typography color="inherit">{title}</Typography>
          <InfoToolTip title={description}>
            <span style={{ height: "16px", cursor: "pointer" }}>
              <InfoLogoSvg svgColor={svgColor} />
            </span>
          </InfoToolTip>
        </Box>
        <Box display="flex" gap={"2px"}>
          {extractedParameters.view_by_period && (
            <ViewBy
              route={route}
              parameters={extractedParameters.view_by_period}
              viewBy={viewBy}
              setViewBy={setViewBy}
              reportUid={reportUid}
              dateRangeData={dateRangeData}
            />
          )}
          {extractedParameters.focus_mode && focus_mode && (
            <Analytica
              description={description}
              component="modal"
              chartDetails={{
                route,
                setChartData,
                title,
                description,
                comparisonPeriod,
                postUrl,
                reportUid,
                parameters,
                dateRangeData,
                apiResponse,
              }}
            />
          )}
        </Box>
      </Box>

      {loading ? (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          minHeight="350px"
          width="100%"
          gap="10px"
        >
          <CircularProgress size={45} color="info" />
          <Typography variant="body2" color="inherit">
            Fetching data, please wait...
          </Typography>
        </Box>
      ) : error ? (
        <NivoRadialBarChart
          chartConfig={extractedParameters}
          chartInput={staticData}
          reportTitle={title}
          reportData={apiResponse}
          showAiDropdown={showAiDropdown}
          showTooltipPrevious={showTooltipPrevious}
        />
      ) : (
        <NivoRadialBarChart
          chartConfig={extractedParameters}
          chartInput={chartInput}
          reportTitle={title}
          reportData={apiResponse}
          showAiDropdown={showAiDropdown}
          showTooltipPrevious={showTooltipPrevious}
        />
      )}
    </>
  )
}

export default RadialBarChart
