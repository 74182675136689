import React, { useState, useEffect } from "react"
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import { useSelector, useDispatch } from "react-redux"
import CircularProgress from "@mui/material/CircularProgress"
import extractKeyValuePairs from "../../../utils/extractKeyValuePairs"
import { useTheme } from "../../../contexts/theme"
import { GroupEdData } from "./GroupEdData"
import { sunburstData } from "../../../constants/chartsData"
import InfoToolTip from "../../InfoToolTip"
import InfoLogoSvg from "../../../assets/svgs/InfoLogoSvg"
import ViewBy from "../../ViewBy"
import Analytica from "../../Analytica"
import NivoGroupBarChart from "./NivoGroupBarChart"
import { postData } from "../../../api/api"
import { getDateFormatFromLocalStorage } from "../../../utils/dateUtils"
import dayjs from "dayjs"

const GroupedBarChart = ({
  route,
  setChartData,
  comparisonPeriod,
  title,
  description,
  postUrl,
  reportUid,
  parameters,
  dateRangeData = { fromDate: "", toDate: "" },
  legendData,
  showAiDropdown = true,
  showLegend = false,
  defaultGroupBarLegendLabel = {
    axisLeft: "",
    axisBottom: "",
  },
}) => {
  const extractedParameters = extractKeyValuePairs(parameters)
  const [viewBy, setViewBy] = useState("Day")
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const dispatch = useDispatch()
  const { theme } = useTheme()
  const [combinedState, setCombinedState] = useState({
    comparisonPeriod: comparisonPeriod,
    dateRangeData: dateRangeData,
  })
  useEffect(() => {
    setCombinedState({
      comparisonPeriod,
      dateRangeData,
    })
  }, [comparisonPeriod, dateRangeData])

  const tooltip_data =
    extractedParameters.tooltip_data ||
    (reportUid === "8D69C58A-3051-4701-968D-07C8CA186C12"
      ? [
          { name: "ProductName", format: false, color: "" },
          { name: "SalesQuantity", format: "Number", color: "" },
          { name: "TotalOrders", format: "Number", color: "" },
        ]
      : [
          { name: "Customers", format: "Number", color: "" },
          { name: "Discounts", format: "Currency", color: "" },
          { name: "Gross Sales", format: "Currency", color: "" },
          { name: "Returns", format: "Number", color: "" },
        ])

  const showTooltipPrevious =
    extractedParameters.showTooltipPrevious === undefined
      ? true
      : extractedParameters.showTooltipPrevious

  const formatDateKey = (dateKey) => {
    const year = Math.floor(dateKey / 10000)
    const month = Math.floor((dateKey % 10000) / 100)
    const day = dateKey % 100

    const dateStr = `${year}-${month.toString().padStart(2, "0")}-${day
      .toString()
      .padStart(2, "0")}`

    const dateFormat = getDateFormatFromLocalStorage() || "MM-DD-YYYY"

    return dayjs(dateStr).format(dateFormat)
  }

  const transformData = (data) => {
    let reportDataCurrent = []
    let reportDataPrevious = []

    if (Array.isArray(data)) {
      reportDataCurrent = data
    } else if (data && typeof data === "object") {
      reportDataCurrent = data.reportDataCurrent || []
      reportDataPrevious = data.reportDataPrevious || []
    }

    const result = []

    // Group previous data by day, if provided
    const previousGrouped = reportDataPrevious.reduce((acc, item) => {
      const day = item.day // Group by day
      if (!acc[day]) {
        acc[day] = []
      }
      acc[day].push(item) // Add to the day's array
      return acc
    }, {})

    // Group current data and map previous data if available
    const groupedData = reportDataCurrent.reduce((acc, item) => {
      const day = item.day

      if (!acc[day]) {
        acc[day] = { day: day, tooltipFields: [] }
      }

      // Create tooltip field for current data
      const previousItems = previousGrouped[day] || []
      const currentIndexx = acc[day].tooltipFields.length
      const previousDateKey =
        previousItems.length > currentIndexx &&
        previousItems[currentIndexx]?.datekey
          ? previousItems[currentIndexx].datekey
          : "" // Keep empty if no matching previous datekey

      const tooltipField = {
        reportType: "GroupBar",
        currentDate: formatDateKey(item.datekey),
        day: item.day,
        previousDate: previousDateKey ? formatDateKey(previousDateKey) : null,
        tooltipData: {
          current: {},
          previous: {},
        },
      }

      // Fill current tooltip data based on tooltip_data
      tooltip_data.forEach((field) => {
        const fieldName = field.name
        const value = item[fieldName] // Get the value from the current item
        const format = field.format

        // Update condition to check for `undefined` and `null` only (allow `0` as a valid value)
        if (value !== undefined && value !== null) {
          tooltipField.tooltipData.current[fieldName] = {
            value: value,
            format: format,
          }
        }
      })

      // Fill previous data if previous data is provided
      if (reportDataPrevious.length > 0) {
        const previousItems = previousGrouped[day] || []
        const currentIndex = acc[day].tooltipFields.length // Current index of tooltipFields

        if (currentIndex < previousItems.length) {
          const previousItem = previousItems[currentIndex]
          tooltip_data.forEach((field) => {
            const fieldName = field.name
            const previousValue = previousItem[fieldName] // Get the value from the previous item
            const previousFormat = field.format

            if (previousValue !== undefined && previousValue !== null) {
              tooltipField.tooltipData.previous[fieldName] = {
                value: previousValue,
                format: previousFormat,
              }
            }
          })
        }
      }

      acc[day].tooltipFields.push(tooltipField)

      // Remove previous key if it's empty
      if (Object.keys(tooltipField.tooltipData.previous).length === 0) {
        delete tooltipField.tooltipData.previous // Only keep current if previous is empty
      }

      // Extract Gross Sales values for a, b, c, d
      const indexKey =
        reportUid === "67187936-81CE-44B0-9416-5D52B308EE84"
          ? item["Customers"]
          : item["Gross Sales"] || null

      // Ensure there is a fixed limit of 4 gross sales
      const currentIndex = acc[day].tooltipFields.length - 1
      if (currentIndex < 4) {
        acc[day][String.fromCharCode(97 + currentIndex)] = indexKey // a, b, c, d
      } else if (currentIndex === 4) {
        acc[day]["e"] = indexKey // For the 5th entry, add it to 'e'
      }

      return acc
    }, {})

    // Convert grouped data into an array
    for (const day in groupedData) {
      if (groupedData.hasOwnProperty(day)) {
        const tooltipFields = groupedData[day].tooltipFields

        result.push(groupedData[day])
      }
    }

    return result
  }

  function transformDataProductName(data) {
    const result = []

    // Determine if the input is in object format or array format
    const reportDataCurrent = Array.isArray(data)
      ? data
      : data.reportDataCurrent
    const reportDataPrevious = Array.isArray(data)
      ? null
      : data.reportDataPrevious

    // Create a map for quick access to previous data by ProductName if reportDataPrevious exists
    const previousDataMap = {}
    if (reportDataPrevious) {
      reportDataPrevious.forEach((item) => {
        const key = item.ProductName
        if (!previousDataMap[item.day]) {
          previousDataMap[item.day] = {}
        }
        previousDataMap[item.day][key] = item
      })
    }

    // Helper function to extract fields based on tooltip_data
    const extractTooltipFields = (item) => {
      const tooltipFields = {}
      tooltip_data.forEach((field) => {
        const fieldName = field.name
        if (item[fieldName] !== undefined) {
          tooltipFields[fieldName] = {
            value: item[fieldName],
            format: field.format,
          }
        }
      })
      return tooltipFields
    }

    // Iterate through the current report data
    reportDataCurrent.forEach((currentItem) => {
      const day = currentItem.day
      const productName = currentItem.ProductName

      // Check if the day already exists in the result
      let dayEntry = result.find((entry) => entry.day === day)
      if (!dayEntry) {
        dayEntry = { day: day }
        result.push(dayEntry)
      }

      // Set the sales quantity for the current product
      dayEntry[productName] = currentItem.SalesQuantity

      // Prepare the tooltip data for the product
      if (!dayEntry.tooltipFields) {
        dayEntry.tooltipFields = {}
      }

      const currentTooltipFields = extractTooltipFields(currentItem)

      let tooltipData
      // Conditionally add previous data only if reportDataPrevious is provided
      if (reportDataPrevious) {
        const previousItem = previousDataMap[day]?.[productName] || {}
        tooltipData = {
          current: currentTooltipFields,
          previous: extractTooltipFields(previousItem),
        }
      } else {
        // Only include current data in array format if previous data doesn't exist
        tooltipData = {
          current: currentTooltipFields,
        }
      }

      dayEntry.tooltipFields[productName] = { tooltipData }
    })

    return result
  }

  const fetchData = async () => {
    try {
      let response

      if (reportUid === "static-groupbar") {
        response = false
      } else {
        response = await postData(postUrl, {
          entityDb: localStorage.getItem("entityDb"),
          fromDate: combinedState.dateRangeData.fromDate,
          toDate: combinedState.dateRangeData.toDate,
          reportInstanceId: reportUid,
          comparedBy: {
            previousFromDate: combinedState.comparisonPeriod.previousFromDate,
            previousToDate: combinedState.comparisonPeriod.previousToDate,
          },
          // viewBy: viewBy || "Day",
        })
      }

      if (!!response === false || Object.keys(response).length === 0) {
        setError("Inadequate data points to generate a report")
        dispatch(
          setChartData({
            reportUid,
            response: GroupEdData,
          })
        )
      } else {
        let transData =
          reportUid === "8D69C58A-3051-4701-968D-07C8CA186C12"
            ? transformDataProductName(response)
            : transformData(response)

        dispatch(
          setChartData({
            reportUid,
            response: transData,
          })
        )
      }
    } catch (error) {
      setError("Inadequate data points to generate a report")
      dispatch(
        setChartData({
          reportUid,
          response: GroupEdData,
        })
      )
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    setLoading(true)
    setChartData({
      reportUid,
      viewBy: viewBy || "Day",
    })
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    reportUid,
    combinedState.comparisonPeriod.previousFromDate,
    combinedState.comparisonPeriod.previousToDate,
    combinedState.dateRangeData.fromDate,
    combinedState.dateRangeData.toDate,
  ])

  const response = useSelector((state) => {
    const selectedReport = state[route]?.reports?.find(
      (report) => report.report_uid === reportUid
    )
    return selectedReport ? selectedReport.reportData : null
  })

  const svgColor = theme.palette.accentSecondary

  return (
    <>
      <Box
        display="flex"
        alignItems="flex-start"
        justifyContent="space-between"
        flexWrap={"wrap"}
        gap={"10px"}
      >
        <Box display="flex" alignItems="center" mb={"14px"}>
          <Typography color="inherit">{title}</Typography>
          {description && (
            <InfoToolTip title={description}>
              <span style={{ height: "16px", cursor: "pointer" }}>
                <InfoLogoSvg svgColor={svgColor} />
              </span>
            </InfoToolTip>
          )}
        </Box>
        <Box
          display="flex"
          alignItems="flex-start"
          justifyContent="space-between"
          gap={"2px"}
        >
          {extractedParameters.view_by_period && (
            <ViewBy
              route={route}
              parameters={
                extractedParameters && extractedParameters.view_by_period
              }
              viewBy={viewBy}
              setViewBy={setViewBy}
              reportUid={reportUid}
              dateRangeData={dateRangeData}
            />
          )}
          <Box display="flex">
            {extractedParameters.focus_mode && (
              <Analytica description={description} />
            )}
          </Box>
        </Box>
      </Box>

      <Box>
        {loading ? (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            alignContent="center"
            gap="10px"
            minHeight="350px"
            width="100%"
          >
            <CircularProgress size={45} color="info" />
            <Typography variant="body2" color="inherit">
              Fetching data, please wait...
            </Typography>
          </Box>
        ) : error ? (
          <NivoGroupBarChart
            legendData={legendData}
            reportTitle={title}
            reportData={sunburstData}
            chartInput={GroupEdData}
            showAiDropdown={showAiDropdown}
            reportUid={reportUid}
            defaultGroupBarLegendLabel={defaultGroupBarLegendLabel}
            showLegend={showLegend}
            showTooltipPrevious={showTooltipPrevious}
          />
        ) : (
          <NivoGroupBarChart
            legendData={legendData}
            reportTitle={title}
            reportData={sunburstData}
            chartInput={response}
            showAiDropdown={showAiDropdown}
            reportUid={reportUid}
            defaultGroupBarLegendLabel={defaultGroupBarLegendLabel}
            showLegend={showLegend}
            showTooltipPrevious={showTooltipPrevious}
          />
        )}
      </Box>
    </>
  )
}

export default GroupedBarChart
