import { ResponsiveBar } from "@nivo/bar"
import { line, curveCatmullRom } from "d3-shape"
import { useTooltip } from "@nivo/tooltip"
import { computeXYScalesForSeries } from "@nivo/scales"
import { useTheme } from "../../../contexts/theme"
import { useEffect, useState } from "react"
import CustomTooltip from "../../CustomTooltip"
import CustomLegend from "../../CustomLegend"
import VerifiedIcon from "@mui/icons-material/Verified"
import NewReleasesIcon from "@mui/icons-material/NewReleases"
import Box from "@mui/material/Box"
import { getFormattedValue } from "../../../utils/utils"
import StarLowPerformer from "../../StarLowPerformer"
import { getDateFormatFromLocalStorage } from "../../../utils/dateUtils"

const NivoLineBarChart = ({
  reportTitle,
  reportData,
  chartInput = [],
  parameters,
  maxValue,
  dateRange,
  legendsData,
  showDateRange,
  showAiDropdown,
  showTooltipPrevious,
}) => {
  const { theme } = useTheme()

  let {
    margin,
    padding,
    borderRadius,
    enableLabel,
    labelTextColor,
    indexBy,
    viewBy: initialViewBy,
    chart_colors,
    meta_info,
    performerFlag = { icon: false, legend: false },
    chartContainerDimensions = {
      height: "345px",
    },
  } = parameters

  const [viewBy, setViewBy] = useState(initialViewBy)
  const [highestBarValue, setHighestBarValue] = useState(null)
  const [highestBarDate, setHighestBarDate] = useState(null)
  const [lowestBarValue, setLowestBarValue] = useState(null)
  const [lowestBarDate, setLowestBarDate] = useState(null)

  useEffect(() => {
    setViewBy(initialViewBy)
  }, [initialViewBy])

  const itemTextColor = theme.palette.mode === "dark" ? "#fff" : "#333333"

  const customTheme = {
    text: {
      fill: itemTextColor,
    },
    tooltip: {
      container: {
        background: "rgba(0, 0, 0, 0.8)",
        color: "#fff",
      },
    },
    grid: {
      line: {
        stroke: "rgba(29, 71, 206, 0.3)",
        strokeWidth: 0.93,
        top: 359.59,
      },
    },
    axis: {
      ticks: {
        text: {
          fontSize: 10,
          fill: itemTextColor,
          outlineWidth: 0,
          outlineColor: "transparent",
        },
      },
    },
  }

  useEffect(() => {
    if (chartInput && chartInput.length > 0) {
      const highestBar = chartInput.reduce(
        (max, item) => (item.barValue > max.barValue ? item : max),
        chartInput[0]
      )

      const lowestBar = chartInput.reduce(
        (min, item) => (item.barValue < min.barValue ? item : min),
        chartInput[0]
      )

      const formatDate = (dateString) => {
        if (typeof dateString !== "string") {
          console.error(
            "Expected dateString to be a string but got:",
            typeof dateString
          )
          return ""
        }

        const date = new Date(dateString)
        if (isNaN(date)) {
          console.error("Invalid date:", dateString)
          return ""
        }

        const month = String(date.getMonth() + 1).padStart(2, "0") // MM
        const day = String(date.getDate()).padStart(2, "0") // DD
        const year = date.getFullYear() // YYYY
        return `${month}-${day}-${year}`
      }

      // const highestbarDate = formatDate(highestBar.day)
      const highestbarDate = highestBar.date
        ? formatDate(highestBar.day)
        : formatDate(highestBar.day)

      const lowestbarDate = lowestBar.date
        ? formatDate(lowestBar.day)
        : formatDate(lowestBar.day)

      setHighestBarValue(
        meta_info?.format === "Currency"
          ? getFormattedValue(highestBar.barValue, "Currency")
          : getFormattedValue(
              highestBar.barValue,
              meta_info?.format || "Number"
            )
      )
      setHighestBarDate(viewBy === "Day" ? highestbarDate : highestBar.day)

      setLowestBarValue(
        meta_info?.format === "Currency"
          ? getFormattedValue(lowestBar.barValue, "Currency")
          : getFormattedValue(lowestBar.barValue, meta_info?.format || "Number")
      )
      setLowestBarDate(viewBy === "Day" ? lowestbarDate : lowestBar.day)
    }
  }, [chartInput])

  const Line = ({
    bars,
    xScale,
    yScale,
    innerWidth,
    innerHeight,
    reportTitle,
  }) => {
    const scale = computeXYScalesForSeries(
      [
        {
          id: "only",
          data: chartInput.map((it) => ({
            x: it.d,
            y: it.lineValue,
          })),
        },
      ],
      { type: "linear" },
      { type: "linear" },
      innerWidth,
      innerHeight
    )
    const lineGenerator = line()
      .x((bar) => bar.x + bar.width / 2)
      .y((bar) => yScale(bar.data.data.lineValue || 0))
      .curve(curveCatmullRom.alpha(0.05))

    const tip = useTooltip()

    function renderTip(e, idx) {
      return tip.showTooltipFromEvent(
        <CustomTooltip
          data={chartInput[idx]}
          showCurrentPrevDate={true}
          showCurrentPrevColors={{
            current: chart_colors?.bar?.[theme.palette.mode || "dark"],
            previous: chart_colors?.line?.[theme.palette.mode || "dark"],
          }}
          showDateRange={false}
          showTooltipPrevious={showTooltipPrevious}
        />,
        e
      )
    }

    return (
      <>
        {bars.some((bar) =>
          chartInput.find((it) => it.lineValue !== undefined)
        ) && (
          <path
            d={lineGenerator(bars)}
            fill="none"
            stroke={chart_colors?.line?.[theme.palette.mode || "dark"]}
            strokeWidth={3}
            style={{ pointerEvents: "none" }}
          />
        )}

        {bars.map((bar, idx) => {
          const circleX = bar.x + bar.width / 2
          const circleY = yScale(chartInput[idx].lineValue)

          return (
            chartInput[idx].lineValue !== undefined && (
              <circle
                key={`circle-${bar.key}`}
                cx={circleX}
                cy={circleY}
                r={5}
                fill={
                  chart_colors?.lineCircle?.[theme.palette.mode || "dark"]?.fill
                }
                stroke={
                  chart_colors?.lineCircle?.[theme.palette.mode || "dark"]
                    ?.stroke
                }
                strokeWidth={3}
                onMouseEnter={(e) => renderTip(e, idx)}
                onMouseMove={(e) => renderTip(e, idx)}
                onMouseLeave={tip.hideTooltip}
              />
            )
          )
        })}
      </>
    )
  }

  const getColor = (data) => {
    return chart_colors?.bar?.[theme.palette.mode]
  }

  const HighestPoint = ({ bars, yScale }) => {
    if (!bars) return null
    const highestValue = Math.max(...chartInput.map((d) => d.barValue))
    const highestBar = bars.find((bar) => bar.data.value === highestValue)

    if (!highestBar) return null

    const x = highestBar.x + highestBar.width / 2
    const y = yScale(highestValue)

    const colors = {
      dark: {
        fill: "#23BF6F",
        stroke: "#FFFFFF",
      },
      light: {
        fill: "#FFFFFF",
        stroke: "#23BF6F",
      },
    }

    return (
      performerFlag.icon && (
        <>
          <circle cx={x} cy={y} r={12} fill="#FFFFFF" />
          <VerifiedIcon
            height={24}
            width={24}
            x={x - 12}
            y={y - 12}
            sx={{ fill: colors.dark.fill }}
          />
        </>
      )
    )
  }

  const LowestPoint = ({ bars, yScale }) => {
    if (!bars) return null
    const lowestValue = Math.min(...chartInput.map((d) => d.barValue))
    const lowestBar = bars.find((bar) => bar.data.value === lowestValue)

    if (!lowestBar) return null

    const x = lowestBar.x + lowestBar.width / 2
    const y = yScale(lowestValue)

    const colors = {
      dark: {
        fill: "#EC2D30",
        stroke: "#FFFFFF",
      },
      light: {
        fill: "#FFFFFF",
        stroke: "#EC2D30",
      },
    }

    return (
      performerFlag.icon && (
        <>
          <circle cx={x} cy={y} r={12} fill="#FFFFFF" />
          <NewReleasesIcon
            height={24}
            width={24}
            x={x - 12}
            y={y - 12}
            sx={{ fill: colors.dark.fill }}
          />
        </>
      )
    )
  }
  return (
    <Box display="flex" flexDirection="column" height="100%">
      {performerFlag.legend && (
        <StarLowPerformer
          legendsData={legendsData}
          StarPerformer={highestBarValue}
          StarPerformerDate={highestBarDate}
          lowPerformer={lowestBarValue}
          lowPerformerDate={lowestBarDate}
        />
      )}
      <Box sx={{ ...chartContainerDimensions }}>
        <ResponsiveBar
          data={chartInput}
          theme={customTheme}
          keys={["barValue"]}
          indexBy={indexBy}
          margin={margin}
          tooltip={(point) => {
            return (
              <CustomTooltip
                data={point.data}
                showCurrentPrevDate={true}
                showCurrentPrevColors={{
                  current: chart_colors?.bar?.[theme.palette.mode || "dark"],
                  previous: chart_colors?.line?.[theme.palette.mode || "dark"],
                }}
                showDateRange={false}
                showTooltipPrevious={showTooltipPrevious}
              />
            )
          }}
          padding={padding}
          borderRadius={borderRadius}
          enableLabel={enableLabel}
          colors={getColor}
          maxValue={maxValue}
          labelTextColor={labelTextColor}
          layers={["grid", "axes", "bars", Line, HighestPoint, LowestPoint]}
          axisLeft={{
            orient: "left",
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: "Sales",
            legendOffset: -50,
            legendPosition: "middle",
          }}
          axisBottom={{
            orient: "bottom",
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: "Date",
            legendOffset: 50,
            legendPosition: "middle",
          }}
        />
      </Box>

      {performerFlag.legend && (
        <CustomLegend
          legendsData={legendsData}
          StarPerformer={highestBarValue}
          StarPerformerDate={highestBarDate}
          lowPerformer={lowestBarValue}
          lowPerformerDate={lowestBarDate}
          reportTitle={reportTitle}
          reportData={reportData}
          showAiDropdown={showAiDropdown}
        />
      )}
    </Box>
  )
}

export default NivoLineBarChart
