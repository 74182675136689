import { useState } from "react"
import Stack from "@mui/material/Stack"
import Typography from "@mui/material/Typography"
import Grid from "@mui/material/Grid"
import Tabs from "@mui/material/Tabs"
import Tab from "@mui/material/Tab"
import { useNavigate } from "react-router-dom"
import RouteTitle from "../../components/RouteTitle"
import CustomPaper from "../../components/CustomPaper"
import KPICard from "../../components/KPICard"
import ArrowRise from "../../assets/svgs/ArrowRise"
import Table from "../../components/datagrid/Table"
import { useTheme } from "../../contexts/theme"
import { useSelector } from "react-redux"

const Management = () => {
  const navigate = useNavigate()
  const comparisonPeriod = useSelector((state) => state.period.selectedValue)

  const orderDetails = [
    {
      title: "Total Customers",
      currentValue: "78,4.80",
      previousValue: "368.30",
      format: "",
    },
    {
      title: "Total Employees",
      currentValue: "2,063",
      previousValue: "1,175",
      format: "",
    },
    {
      title: "Total Profit",
      currentValue: "721",
      previousValue: "229",
      format: "Currency",
    },
    {
      title: "Total Sale",
      currentValue: "2333",
      previousValue: "1222",
      format: "Currency",
    },
    {
      title: "Emp Turnover per Year",
      currentValue: "192",
      previousValue: "156",
      format: "",
    },
    {
      title: "Revenue per Employee",
      currentValue: "10000",
      previousValue: "8976",
      format: "Currency",
    },
  ]

  const [activeTab, setActiveTab] = useState(0)

  const { theme } = useTheme()

  const palette = theme.palette

  const tabStyles = {
    textTransform: "none",
    color: "inherit",
    "&:hover": {
      color: palette.accent,
    },
    "&.Mui-selected": {
      color: palette.accent,
    },
  }

  const handleTabChange = (event, newActiveTab) => {
    setActiveTab(newActiveTab)
  }

  const handleCreateUser = () => {
    navigate("/management/users/create")
  }

  const handleUserRowClick = () => {
    navigate("/management/users/user/1")
  }

  const handleCreateGroup = () => {
    navigate("/management/groups/create")
  }

  const handleGroupRowClick = () => {
    navigate("/management/groups/group/1")
  }

  const renderTableContent = (activeTab) => {
    const tableProps = [
      {
        title: "Users",
        onRowClick: handleUserRowClick,
        showButton: handleCreateUser,
        showDropdown: true,
        dropdownOptions: ["Option 1", "Option 2", "Option 3"],
        buttonName: "+ Add User",
      },
      {
        title: "Groups",
        onRowClick: handleGroupRowClick,
        showButton: handleCreateGroup,
        buttonName: "+ Create Group",
      },
    ][activeTab]

    return <Table {...tableProps} />
  }

  return (
    <>
      <RouteTitle title="Management" />
      <Stack padding="15px 30px 15px 30px" gap="20px">
        <CustomPaper
          customStyles={{
            backgroundColor: palette.mode === "dark" ? "#000" : "#E9EDFC",
          }}
          watermarkBg={true}
        >
          <Typography variant="subtitle2" color="inherit" mb="10px">
            Order Details of 2023
          </Typography>
          <Grid container spacing={2} justifyContent="space-evenly">
            {orderDetails.map((orderDetail) => (
              <Grid item xs={12} sm={6} md={4} lg={2} key={orderDetail.title}>
                <CustomPaper width="100%" watermarkBg={true}>
                  <KPICard
                    title={orderDetail.title}
                    format={orderDetail.format}
                    parameters={[
                      {
                        currentValue: `$${orderDetail.currentValue}`,
                        previousValue: orderDetail.previousValue,
                      },
                    ]}
                    postUrl=""
                    reportUid="static-kpi"
                    dateRangeData={{}}
                    comparisonPeriod={{}}
                  />
                </CustomPaper>
              </Grid>
            ))}
          </Grid>
        </CustomPaper>
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          aria-label="management tabs"
          sx={{
            "& .MuiTabs-indicator": {
              backgroundColor: palette.accent,
            },
          }}
        >
          <Tab label="User Management" sx={tabStyles} />
          <Tab label="Group Management" sx={tabStyles} />
        </Tabs>
        {renderTableContent(activeTab)}
      </Stack>
    </>
  )
}

export default Management
