import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import InfoLogoSvg from "../../../assets/svgs/InfoLogoSvg"
import { useTheme } from "../../../contexts/theme"
import { useDispatch, useSelector } from "react-redux"
import { useEffect, useState } from "react"
import CircularProgress from "@mui/material/CircularProgress"
import extractKeyValuePairs from "../../../utils/extractKeyValuePairs"
import InfoToolTip from "../../InfoToolTip"
import ViewBy from "../../ViewBy"
import Analytica from "../../Analytica"
import NivoCalendarChart from "./NivoCalendarChart"
import { CalendarChartData } from "./CalendarChartData"
import { CalendarChartParameters } from "./CalendarChartParameters"
import { postData } from "../../../api/api"
import { getDateFormatFromLocalStorage } from "../../../utils/dateUtils"

const CalendarChart = ({
  route,
  setChartData,
  title,
  description,
  comparisonPeriod,
  postUrl,
  reportUid,
  parameters,
  dateRangeData = {
    fromDate: "",
    toDate: "",
  },
  focus_mode = true,
  showLegend = true,
  showAiDropdown = true,
  defaultColor = ["#eeeeee", "#61cdbb", "#e8c1a0", "#f47560"],
}) => {
  const { theme } = useTheme()

  const extractedParameters = extractKeyValuePairs(parameters)
  const [viewBy, setViewBy] = useState("Day")
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [apiResponse, setApiResponse] = useState([])
  const staticAiResponse = {
    success: true,
    msg: [
      {
        Analysis:
          "\n1. The actual sales for August 2024 ranged from approximately 6,182 to 15,386 units, with the highest sales recorded on August 4th.\n2. The forecasted sales for the same period ranged from about 7,024 to 14,287 units, peaking on August 11th, 25th, and 31st.\n3. The actual sales surpassed the forecast on several days, particularly on August 4th, 15th, and 25th.\n",
      },
      {
        Recommendations:
          "\n1. Consider investigating the factors that led to the higher than forecasted sales on August 4th, 15th, and 25th, as understanding these could help replicate such success in the future.\n2. Keep a close eye on the days where sales are consistently lower than forecasted to identify any potential issues or trends.\n3. Use this data to refine your forecasting model, taking into account any external factors that may have influenced sales.\n",
      },
      {
        Anomalies:
          "\n1. There was a significant discrepancy between the actual and forecasted sales on August 4th, with actual sales much higher than predicted.\n2. The actual sales on August 11th were lower than forecasted, despite the forecast predicting a peak in sales.\n3. The actual sales on August 25th significantly surpassed the forecast, indicating an unexpected surge in sales.",
      },
    ],
  }
  const dispatch = useDispatch()
  const svgColor = theme.palette.accentSecondary

  extractedParameters.chartContainerDimensions ||
    (extractedParameters.chartContainerDimensions = {
      height: "600px",
      minHeight: "600px",
      maxHeight: "unset",
    })

  const tooltip_data = extractedParameters.tooltip_data || [
    {
      name: "value",
      format: "Currency",
      color: "",
    },
    {
      name: "Customers",
      format: "Number",
      color: "",
    },
    {
      name: "Transactions",
      format: "Number",
      color: "",
    },
    {
      name: "Returns",
      format: "Currency",
      color: "",
    },
    {
      name: "Discounts",
      format: "Currency",
      color: "",
    },
  ]

  const parseNumber = (value) => {
    try {
      return typeof value === "number"
        ? value
        : parseFloat(value.replace(/[^0-9.]/g, ""))
    } catch (error) {
      return undefined
    }
  }
  const showTooltipPrevious =
    extractedParameters.showTooltipPrevious === undefined
      ? true
      : extractedParameters.showTooltipPrevious

  function transformData(input) {
    if (!input || !input.reportDataCurrent || !input.reportDataPrevious) {
      console.error("Invalid input data structure:", input)
      return []
    }

    // Create a map of current data by datekey
    const currentDataMap = input.reportDataCurrent.reduce((map, item) => {
      if (item.datekey) {
        map[item.datekey] = item
      }
      return map
    }, {})

    // Helper function to adjust datekey by a fixed offset
    const adjustDatekey = (datekey, yearsOffset) => {
      const dateStr = datekey.toString()
      const year = parseInt(dateStr.substring(0, 4), 10)
      const newYear = year - yearsOffset
      return parseInt(`${newYear}${dateStr.substring(4)}`, 10)
    }

    let data = []

    input.reportDataCurrent.forEach((currentItem) => {
      // Adjust the datekey for matching
      const adjustedDatekey = adjustDatekey(currentItem.datekey, 1) // Assuming a 1-year offset

      // Find the corresponding current item
      const previousItem = currentDataMap[adjustedDatekey] || {}

      let eachElement = {
        day: currentItem.day,
        value: currentItem.value,
        reportType: "Calendar",
        previousDate: previousItem.day,
        tooltipData: {
          current: {},
          previous: {},
        },
      }

      tooltip_data.forEach((tooltipDataItem) => {
        const tooltipName = tooltipDataItem.name

        // Handle current data
        eachElement.tooltipData.current[tooltipName] = {
          value: parseNumber(currentItem[tooltipName]) || 0,
          format: tooltipDataItem.format,
        }

        // Handle previous data
        eachElement.tooltipData.previous[tooltipName] = {
          value: parseNumber(previousItem[tooltipName]) || 0,
          format: tooltipDataItem.format,
        }
      })

      data.push(eachElement)
    })

    return data
  }

  const fetchData = async () => {
    try {
      const response = await postData(postUrl, {
        entityDb: localStorage.getItem("entityDb"),
        reportInstanceId: reportUid,
        fromDate: "20220101",
        toDate: "20241231",
        comparedBy: {
          previousFromDate: "20220101",
          previousToDate: "20231231",
        },
      })

      setApiResponse(response)
      if (!!response === false || Object.keys(response).length === 0) {
        setError("Inadequate data points to generate a report")
      } else {
        dispatch(
          setChartData({
            reportUid,
            response: transformData(response),
          })
        )
      }
    } catch (error) {
      setError("Inadequate data points to generate a report")
    } finally {
      setLoading(false)
    }
  }
  useEffect(() => {
    setLoading(true)
    dispatch(
      setChartData({
        reportUid,
        viewBy: viewBy || "Day",
      })
    )
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewBy])

  const response = useSelector((state) => {
    const selectedReport = state[route]?.reports?.find(
      (report) => report.report_uid === reportUid
    )

    if (selectedReport) return selectedReport?.reportData
  })
  const chartInput = response || CalendarChartData

  return (
    <>
      <Box
        display="flex"
        alignItems="flex-start"
        justifyContent="space-between"
        flexWrap={"wrap"}
        gap={"10px"}
      >
        <Box display="flex" alignItems="center" mb={"14px"}>
          <Typography color="inherit">{title}</Typography>
          <InfoToolTip title={description}>
            <span style={{ height: "16px", cursor: "pointer" }}>
              <InfoLogoSvg svgColor={svgColor} />
            </span>
          </InfoToolTip>
        </Box>

        <Box
          display="flex"
          alignItems="flex-start"
          justifyContent="space-between"
          gap={"2px"}
        >
          {extractedParameters.view_by_period && (
            <ViewBy
              route={route}
              parameters={
                extractedParameters && extractedParameters.view_by_period
              }
              viewBy={viewBy}
              setViewBy={setViewBy}
              reportUid={reportUid}
              dateRangeData={dateRangeData}
            />
          )}
          <Box display="flex">
            {extractedParameters.focus_mode && focus_mode && (
              <Analytica
                description={description}
                component="modal"
                chartDetails={{
                  route,
                  setChartData,
                  title,
                  description,
                  comparisonPeriod,
                  postUrl,
                  reportUid,
                  parameters,
                  dateRangeData,
                  showLegend,
                  apiResponse,
                }}
              />
            )}
          </Box>
        </Box>
      </Box>

      <Box>
        {loading ? (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            alignContent="center"
            gap="10px"
            minHeight="550px"
            width="100%"
          >
            <CircularProgress size={45} color="info" />
            <Typography variant="body2" color="inherit">
              Fetching data, please wait...
            </Typography>
          </Box>
        ) : error ? (
          <NivoCalendarChart
            chartInput={chartInput}
            parameters={CalendarChartParameters}
            reportTitle={title}
            reportData={apiResponse}
            showAiDropdown={showAiDropdown}
            defaultColor={defaultColor}
            showTooltipPrevious={showTooltipPrevious}
          />
        ) : (
          <NivoCalendarChart
            chartInput={chartInput}
            parameters={CalendarChartParameters}
            reportTitle={title}
            reportData={apiResponse}
            showAiDropdown={showAiDropdown}
            defaultColor={defaultColor}
            showTooltipPrevious={showTooltipPrevious}
          />
        )}
      </Box>
    </>
  )
}

export default CalendarChart
